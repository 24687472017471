import { Autocomplete, type AutocompleteProps, TextField, type AutocompleteRenderInputParams, type TextFieldProps, MenuList, MenuItem, type AutocompleteRenderOptionState, } from '@mui/material';
import { Children, createContext, createRef, type CSSProperties, forwardRef, type HTMLAttributes, type ReactElement, type ReactNode, useContext, useRef } from "react"
import { VariableSizeList } from 'react-window';
import Measure from 'react-measure';

const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

export interface SearchableSelectOption {
  label: string
  value: string
}

export interface SearchableSelectProps<T extends SearchableSelectOption> extends Omit<AutocompleteProps<T, true, true, false>, "freeSolo" | "options" | "renderInput"> {
  options: T[]
  rowHeight?: number
  TextFieldProps?: Partial<TextFieldProps>
  renderInput?: (params: AutocompleteRenderInputParams) => ReactElement
}

export const SearchableSelect = <K extends SearchableSelectOption>({ options, rowHeight = 34, TextFieldProps = {}, renderInput, renderOption, ...props }: SearchableSelectProps<K>) => {
  const itemHeights = useRef<{ [key: number]: number }>({});
  const listRef = createRef<VariableSizeList>();

  const renderInputDefault = (params: AutocompleteRenderInputParams) => {
    return <TextField autoFocus {...params} {...TextFieldProps} variant='standard' />
  }

  const renderOptionDefault = (props: HTMLAttributes<HTMLLIElement>, option: SearchableSelectOption, state: AutocompleteRenderOptionState) => {
    return (
      <MenuItem value={option.value} {...props} key={state.index}>
        {option.label}
      </MenuItem>
    );
  }

  const ListboxComponent = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {

    const { children, ...other } = props;
    const items: ReactNode[] = Children.toArray(children);
    const renderRow = ({ index, style }: { index: number, style: CSSProperties }) => {
      return (
        <div key={index} style={style}>
          <Measure
            client
            offset
            scroll
            bounds
            margin
            onResize={(contentRect) => {
              const newHeight = contentRect.offset?.height as number;
              itemHeights.current = { ...itemHeights.current, [index]: newHeight };
              listRef.current?.resetAfterIndex(0);
            }}
          >
            {({ measureRef }) => (
              <div ref={measureRef}>
                {items[index]}
              </div>
            )}
          </Measure>
        </div>
      )
    };

    const getItemSize = (index: number) => itemHeights.current[index] ?? rowHeight;

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <MenuList>
            <VariableSizeList
              itemData={items}
              itemSize={getItemSize}
              height={600}
              width="100%"
              outerElementType={OuterElementType}
              innerElementType="ul"
              overscanCount={5}
              itemCount={items.length}
              ref={listRef}
            >
              {renderRow}
            </VariableSizeList>
          </MenuList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  return (
    <Autocomplete
      disableListWrap
      autoFocus
      fullWidth
      disableClearable
      freeSolo={false}
      options={options}
      inputMode='search'
      renderInput={renderInput ?? renderInputDefault}
      renderOption={renderOption ?? renderOptionDefault}
      slots={{ listbox: ListboxComponent }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      {...props}
    />
  )
}
