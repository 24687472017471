import type { APITestRunQuestion } from '@visorpro/client'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import type { Stores } from '../../util/store'
import { progressColor } from '../../util/style'
import { useTestRunQuestionScore } from '../../util/test-run'
import { TableLinkCell } from '../table/table-link-cell'
import { TableRow } from '../table/table-row'
import { TableTextCell } from '../table/table-text-cell'

export interface TestQuestionRowProps {
    stores: Stores
    testRunQuestion: APITestRunQuestion
    isFocused: boolean
}

export const TestRunQuestionRow: FC<TestQuestionRowProps> = observer(
    ({ testRunQuestion, isFocused }) => {
        const score = useTestRunQuestionScore(testRunQuestion)

        const location = useLocation()

        const to = useMemo(() => {
            const searchParams = new URLSearchParams(location.search)

            searchParams.set('question_id', testRunQuestion.id)

            return `${location.pathname}?${searchParams.toString()}`
        }, [location, testRunQuestion.id])

        return (
            <TableRow key={testRunQuestion.id}>
                <TableTextCell
                    style={{
                        color: progressColor(
                            score.percentage,
                            score.isComplete,
                        ),
                    }}>
                    {score.percentage}%
                </TableTextCell>

                <TableLinkCell
                    to={to}
                    textStyle={{
                        color: isFocused
                            ? 'var(--cyan-color)'
                            : 'var(--foreground-color)',
                    }}>
                    {testRunQuestion.text}
                </TableLinkCell>

                <TableTextCell>
                    {testRunQuestion.acceptance_criteria.length}
                </TableTextCell>
            </TableRow>
        )
    },
)
