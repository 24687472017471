import { DateTime } from 'luxon'
import type { APIDocumentVersion } from '@visorpro/client'
import { Table } from '../../../../../components/table/table'
import { TableHeader } from '../../../../../components/table/table-header'
import { DocumentVersionRow } from './version-row'

export interface DocumentVersionsTableProps {
    versions: APIDocumentVersion[]
    onIsEnabledClick(versionId: string, isEnabled: boolean): void
}

export const DocumentVersionsTable = ({
    versions,
    onIsEnabledClick,
}: DocumentVersionsTableProps) => {
    const now = DateTime.local()

    return (
        <Table>
            <thead>
                <tr>
                    <TableHeader>Parser</TableHeader>
                    <TableHeader>Args</TableHeader>
                    <TableHeader>Enabled</TableHeader>
                    <TableHeader>Sections</TableHeader>
                    <TableHeader>Created</TableHeader>
                </tr>
            </thead>

            <tbody>
                {versions.map((version) => {
                    return (
                        <DocumentVersionRow
                            key={version.id}
                            version={version}
                            now={now}
                            onIsEnabledClick={onIsEnabledClick}
                        />
                    )
                })}
            </tbody>
        </Table>
    )
}
