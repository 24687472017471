import type { CSSProperties, FC } from 'react'
import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import type { NavLinkFnProps } from '../root'

export enum NavBarItem {
    Members,
    DataSets,
    InvitationCodes,
    Profile,
    Domains,
}

const nameByItem: Record<NavBarItem, string> = {
    [NavBarItem.Members]: 'Members',
    [NavBarItem.DataSets]: 'Data sets',
    [NavBarItem.InvitationCodes]: 'Invitation codes',
    [NavBarItem.Profile]: 'Profile',
    [NavBarItem.Domains]: 'Domains',
}

const routeByItem: Record<NavBarItem, string> = {
    [NavBarItem.Members]: 'members',
    [NavBarItem.DataSets]: 'data-sets',
    [NavBarItem.InvitationCodes]: 'invitation-codes',
    [NavBarItem.Profile]: 'profile',
    [NavBarItem.Domains]: 'domains',
}

export interface NavBarProps {
    style?: CSSProperties
}

export const NavBar: FC<NavBarProps> = ({ style }) => {
    const items = [
        NavBarItem.Members,
        NavBarItem.DataSets,
        NavBarItem.InvitationCodes,
        NavBarItem.Profile,
        NavBarItem.Domains,
    ]

    const itemClassName = useCallback(({ isActive }: NavLinkFnProps) => {
        return isActive ? 'active' : ''
    }, [])

    return (
        <div className="navbar" style={style}>
            {items.map((item) => (
                <span key={item}>
                    <NavLink to={routeByItem[item]} className={itemClassName}>
                        {nameByItem[item]}
                    </NavLink>
                </span>
            ))}
        </div>
    )
}
