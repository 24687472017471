import type { APITestRunQuestionAcceptanceCriteria } from '@visorpro/client'
import type { FC } from 'react'
import { successColor } from '../../../util/style'

export interface TestRunQuestionAcceptanceCriteriaProps {
    item: APITestRunQuestionAcceptanceCriteria
}

export const TestRunQuestionAcceptanceCriteria: FC<
    TestRunQuestionAcceptanceCriteriaProps
> = ({ item }) => {
    return (
        <div
            style={{
                backgroundColor: 'var(--background-dark-color)',
                display: 'flex',
            }}>
            <div
                style={{
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                    flexShrink: 0,
                    marginTop: '0.3rem',
                    backgroundColor: successColor(item.success),
                }}
            />

            <div style={{ marginLeft: '0.5rem' }}>
                <p style={{ color: 'var(--foreground-color)' }}>{item.text}</p>

                {item.comment && (
                    <p
                        style={{
                            marginTop: '0.5rem',
                            color: 'var(--comment-color)',
                        }}>
                        {item.comment}
                    </p>
                )}
            </div>
        </div>
    )
}
