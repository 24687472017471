import type { FC } from 'react'
import React from 'react'
import './table-row.css'

export interface TableRowProps {
    containerRef?: React.RefObject<HTMLTableRowElement>
    children: React.ReactNode
}

export const TableRow: FC<TableRowProps> = ({ containerRef, children }) => {
    return (
        <tr
            ref={containerRef}
            className="table-row"
            style={{
                borderTop: '0.5rem solid transparent',
                borderBottom: '0.5rem solid transparent',
            }}>
            {children}
        </tr>
    )
}
