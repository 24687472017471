import { useCallback, useRef } from "react"
import { Stores } from "../../util/store"
import { useModal } from "../../components/modal"

export const useDocumentUploads = (dataSetId: string, stores: Stores) => {
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleButtonClick = useCallback(() => {
        fileInputRef.current?.click()
    }, [])

    const handleFileChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files

            if (!dataSetId || !files) return

            stores.documentUploads.enqueueDocumentUploads(dataSetId, files)
        },
        [stores, dataSetId],
    )

    return {
        fileInputRef,
        handleButtonClick,
        handleFileChange,
    }
}

export const useMetadataUploads = (
    dataSetId: string,
) => {
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleButtonClick = useCallback(() => {
        fileInputRef.current?.click()
    }, [])

    const modal = useModal<File>()

    const handleFileChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files

            if (!dataSetId || !files) return

            modal.open(event, files[0])
        },
        [dataSetId],
    )

    return {
        modal,
        fileInputRef,
        handleButtonClick,
        handleFileChange,
    }
}
