export enum RouteName {
    Root = 'root',
    Login = 'login',
    OrganizationsList = 'organizations-list',
    OrganizationDetails = 'organization-details',
    OrganizationDetailsMembers = 'organization-details-members',
    OrganizationDetailsDataSets = 'organization-details-data-sets',
    OrganizationDetailsDataSetDetails = 'organization-details-data-set-details',
    OrganizationDetailsInvitationCodes = 'organization-details-invitation-codes',
    OrganizationDetailsProfile = 'organization-details-profile',
    OrganizationDetailsDomains = 'organization-details-domains',
    ParsersList = 'parsers-list',
    ParserDetails = 'parser-details',
    UsersList = 'users-list',
    UserDetails = 'user-details',
    DataSets = 'data-sets',
    DataSetDetails = 'data-set-details',
    DataSetDetailsDocuments = 'data-set-details-documents',
    DataSetDetailsQuestions = 'data-set-details-questions',
    DataSetDetailsTestRuns = 'data-set-details-test-runs',
    Models = 'models',
    Documents = 'documents',
    DocumentDetails = 'document-details',
    DocumentDetailsInfo = 'document-details-info',
    DocumentDetailsQuestions = 'document-details-questions',
    DocumentDetailsTestRuns = 'document-details-test-runs',
    DocumentDetailsVersions = 'document-details-versions',
    DocumentDetailsQueueTasks = 'document-details-queue-tasks',
    DocumentTestRunDetails = 'document-test-run-details',
    Issues = 'issues',
    IssueDetails = 'issue-details',
    Prompts = 'prompts',
    TestRuns = 'test-runs',
    KnowledgeArticles = 'knowledge-articles',
    QueueTasks = 'queue-tasks',
    WorkOrders = 'work-orders',
}
