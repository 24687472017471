import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { RestClient, PipelineClient } from '@visorpro/client'

export const API_TOKEN_KEY = 'token'
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000'
export const PIPELINE_API_URL =
    process.env.REACT_APP_PIPELINE_API_URL || 'http://localhost:3001'

export const visorPRORestClient = new RestClient(API_URL)
export const visorPROPipelineClient = new PipelineClient(PIPELINE_API_URL)

const api_token = localStorage.getItem(API_TOKEN_KEY)
if (api_token) {
    try {
        visorPRORestClient.setToken(api_token)
        visorPROPipelineClient.setToken(api_token)
    } catch (e) {
        localStorage.removeItem(API_TOKEN_KEY)
        window.location.href = '/login?logged_out=true'
    }
}

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(API_TOKEN_KEY)
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        return config
    },
    (error) => Promise.reject(error),
)

export const logBackendError = (e: any, message: string) => {
    if (e instanceof AxiosError) {
        toast.error(`${message}: ${e.response?.data?.error?.message}`)
    } else {
        toast.error(message)
    }
}
