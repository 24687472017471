import { useEffect } from 'react'
import { makeAutoObservable, runInAction } from 'mobx'
import { type APIQueueTask } from '@visorpro/client'
import _ from 'lodash'

import { visorPROPipelineClient } from '../util/api'
import { type APIGetQueueTaskRequestFilteringParams } from '@visorpro/client/dist/PipelineClient/services'
import { observed } from '../util/observed-decorator'

export class QueueTasksStore {
    public tasks: APIQueueTask[] = []
    public totalTasks: number = 0
    public queryParams: APIGetQueueTaskRequestFilteringParams = {}
    public isFetched = false
    public isFetching = false
    public isUpdating = false

    constructor() {
        makeAutoObservable(this)
    }

    @observed('isFetching')
    public async list(params?: APIGetQueueTaskRequestFilteringParams) {
        const response = await visorPROPipelineClient.tasks.getTasks(params)
        runInAction(() => {
            this.tasks = response.items
            this.totalTasks = response.total
            this.queryParams = params || {}
            this.isFetched = true
        })
    }
}

export const useQueueTasks = (
    store: QueueTasksStore,
    params?: APIGetQueueTaskRequestFilteringParams,
) => {
    useEffect(() => {
        if (!_.isEqual(store.queryParams, params)) {
            void store.list(params)
        }
    }, [params, store, store.queryParams])

    return store.tasks
}
