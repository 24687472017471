import { type APIDataSet } from '@visorpro/client'
import { makeAutoObservable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { DocumentsStore } from './documents-store'
import { observed } from '../util/observed-decorator'
import { DictionaryArray } from '../types'

export class DataSetStore {
    public dataSetsById: Record<string, APIDataSet> = {}
    public dataSetIdsByOrganizationId: Record<string, string[] | undefined> = {}
    public documentsStoresById: Record<string, DocumentsStore> = {}
    public isFetched = false
    public isFetching = false
    public isUpdating = false

    constructor(private readonly stores: Stores) {
        makeAutoObservable(this)
    }

    @observed('isUpdating')
    public async update(dataSetId: string, name: string) {
        const dataSet = await visorPRORestClient.dataSet.update(dataSetId, name)

        runInAction(() => {
            this.dataSetsById = {
                ...this.dataSetsById,
                [dataSet.id]: dataSet,
            }
        })
    }

    public addDataSets(dataSets: APIDataSet[]) {
        const dataSetsById: Record<string, APIDataSet> = {}

        dataSets.forEach((dataSet) => {
            dataSetsById[dataSet.id] = dataSet
        })

        this.dataSetsById = {
            ...this.dataSetsById,
            ...dataSetsById,
        }
    }

    @observed('isFetching')
    public async list() {
        const dataSets = await visorPRORestClient.dataSet.list()
        const dataSetsById: Record<string, APIDataSet> = {}
        const documentsStoresById: Record<string, DocumentsStore> = {}

        dataSets.items.forEach((dataSet) => {
            dataSetsById[dataSet.id] = dataSet
            documentsStoresById[dataSet.id] = new DocumentsStore(this.stores)
        })

        runInAction(() => {
            this.dataSetsById = dataSetsById
            this.documentsStoresById = documentsStoresById
            this.isFetched = true
        })
    }

    @observed('isUpdating')
    public async create(name: string, organizationId?: string) {
        const dataSet = await visorPRORestClient.dataSet.create(
            name,
            organizationId,
        )

        runInAction(() => {
            this.dataSetsById = {
                ...this.dataSetsById,
                [dataSet.id]: dataSet,
            }
            this.documentsStoresById[dataSet.id] = new DocumentsStore(
                this.stores,
            )
        })
    }

    @observed('isUpdating')
    public async addOrganizationDataSet(
        dataSetId: string,
        organizationId: string,
    ) {
        const response = await visorPRORestClient.dataSet.addOrganization(
            dataSetId,
            organizationId,
        )

        runInAction(() => {
            this.dataSetsById[dataSetId].organizations = [
                ...(this.dataSetsById[dataSetId].organizations || []),
                response.organization,
            ]
        })
    }

    @observed('isUpdating')
    public async removeOrganizationDataSet(
        dataSetId: string,
        organizationId: string,
    ) {
        await visorPRORestClient.dataSet.removeOrganization(
            dataSetId,
            organizationId,
        )

        runInAction(() => {
            const newOrgs = this.dataSetsById[dataSetId].organizations?.filter(
                (org) => org.id !== organizationId,
            )
            this.dataSetsById[dataSetId].organizations = newOrgs
        })
    }

    @observed('isUpdating')
    public addOrRemoveOrganizationDataSet(
        dataSetId: string,
        organizationId: string,
    ) {
        const dataSet = this.dataSetsById[dataSetId]

        const hasOrganization = dataSet.organizations?.some(
            (org) => org.id === organizationId,
        )

        if (hasOrganization) {
            void this.removeOrganizationDataSet(dataSetId, organizationId)
        } else {
            void this.addOrganizationDataSet(dataSetId, organizationId)
        }
    }
}

export const useDataSets = (stores: Stores) => {
    useEffect(() => {
        if (!stores.dataSets.isFetched) {
            void stores.dataSets.list()
        }
    }, [stores.dataSets, stores.dataSets.isFetched])

    return new DictionaryArray(stores.dataSets.dataSetsById)
}

export const useDataSetById = (
    stores: Stores,
    id: string,
): APIDataSet | undefined => {
    useEffect(() => {
        if (!stores.dataSets.dataSetsById[id]) {
            void stores.dataSets.list()
        }
    }, [stores.dataSets.dataSetsById, stores.dataSets, id])

    return useMemo(() => {
        return stores.dataSets.dataSetsById[id]
    }, [stores.dataSets.dataSetsById, id])
}
