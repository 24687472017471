import {
    type APIDocumentType,
    type APIDocument,
    type APIQueueTaskStatus,
} from '@visorpro/client'
import { makeAutoObservable, runInAction } from 'mobx'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { visorPROPipelineClient, visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { observed } from '../util/observed-decorator'
import { DictionaryArray } from '../types'
import { QueueTasksStore } from './queue-tasks-store'
import { type APIListDocumentRequest } from '@visorpro/client/dist/RestClient/services'

export interface UpdateDocumentVersionBody {
    is_enabled: boolean
}

export interface APIUpdateDocumentRequest {
    original_filename?: string
    title?: string
    type?: APIDocumentType
    file_ext?: string
}

export interface APIDocumentExtended extends APIDocument {
    task_status: APIQueueTaskStatus
}

export class DocumentsStore {
    public documentsById: Record<string, APIDocument> = {}
    public documentTaskStatus: Record<string, APIQueueTaskStatus> = {}
    public documentQueueTasksStoresById: Record<string, QueueTasksStore> = {}
    public totalDocuments: number = 0
    public isFetched = false
    public isFetching = false
    public isUpdating = false

    constructor(private readonly stores: Stores) {
        makeAutoObservable(this)
    }

    public get documents(): DictionaryArray<APIDocumentExtended> {
        const docs: APIDocumentExtended[] = Object.values(
            this.documentsById,
        ).map((document) => {
            return {
                ...document,
                task_status: this.documentTaskStatus[document.id],
            }
        })
        return new DictionaryArray(docs, 'id')
    }

    @observed('isFetching')
    public async list(options?: APIListDocumentRequest) {
        const response = await visorPRORestClient.document.list({
            limit: 100,
            ...options,
        })

        const documentsById: Record<string, APIDocument> = {}
        const queueTasksStoresById: Record<string, QueueTasksStore> = {}
        response.items.forEach((document) => {
            documentsById[document.id] = document
            queueTasksStoresById[document.id] = new QueueTasksStore()
        })

        runInAction(() => {
            this.documentsById = documentsById
            this.documentQueueTasksStoresById = queueTasksStoresById
            this.totalDocuments = response.total
            this.isFetched = true
        })

        const documentIds = Object.keys(documentsById)
        await this.getTasksStatus(documentIds)
    }

    @observed('isFetching')
    public async get(documentId: string) {
        try {
            const document =
                await visorPRORestClient.document.getAdminDocumentById(
                    documentId,
                )

            runInAction(() => {
                if (!this.documentQueueTasksStoresById[documentId]) {
                    const tasksStore = new QueueTasksStore()
                    this.documentQueueTasksStoresById[documentId] = tasksStore
                }

                this.documentsById[documentId] = document
            })
        } catch (e) {
            toast.error(`Failed to fetch document: ${(e as Error).message}`)
        }
    }

    @observed('isFetching')
    public async getTasksStatus(documentId: string | string[]) {
        if (!Array.isArray(documentId)) {
            documentId = [documentId]
        }

        const chunks = _.chunk(documentId, 100)

        const responses = await Promise.all(
            chunks.map((chunk) =>
                visorPROPipelineClient.tasksStatus.getTasksStatus({
                    document_id: chunk,
                }),
            ),
        )

        runInAction(() => {
            this.documentTaskStatus = responses.reduce((acc, response) => {
                return {
                    ...acc,
                    ...response,
                }
            }, {})
        })
    }

    @observed('isUpdating')
    public async updateDocumentVersion(
        documentId: string,
        versionId: string,
        body: UpdateDocumentVersionBody,
    ) {
        try {
            const response =
                await visorPRORestClient.document.updateDocumentVersion(
                    versionId,
                    {
                        is_enabled: body.is_enabled,
                    },
                )

            runInAction(() => {
                const document = this.documentsById[documentId]

                if (document) {
                    document.versions = document.versions?.map((version) => {
                        if (version.id === versionId) {
                            return response
                        }

                        return version
                    })
                }
            })
        } catch (e) {
            toast.error(`Failed to fetch document: ${(e as Error).message}`)
        }
    }

    @observed('isUpdating')
    public async setDocumentEnabled(documentId: string, isEnabled: boolean) {
        try {
            const document = await visorPRORestClient.document.update(
                documentId,
                {
                    is_enabled: isEnabled,
                },
            )

            runInAction(() => {
                this.documentsById[documentId] = document
            })
        } catch (e) {
            toast.error(
                `Failed to set document enabled: ${(e as Error).message}`,
            )
        }
    }

    @observed('isUpdating')
    public async update(documentId: string, options: APIUpdateDocumentRequest) {
        const document = await visorPRORestClient.document.update(
            documentId,
            options,
        )

        runInAction(() => {
            this.documentsById[document.id] = document
        })
    }

    @observed('isUpdating')
    public async addDocumentToDataSet(documentId: string, dataSetId: string) {
        await this.addDocumentToDataSets(documentId, [dataSetId])
    }

    @observed('isUpdating')
    public async addDocumentToDataSets(
        documentId: string,
        dataSetIds: string[],
    ) {
        await Promise.all(
            dataSetIds.map((dataSetId) =>
                visorPRORestClient.dataSet.addDocument(dataSetId, documentId),
            ),
        )

        runInAction(() => {
            const document = this.documentsById[documentId]
            const dataSets = document.data_sets ?? []
            const concatenatedDataSets = dataSetIds.map(
                (dataSetId) => this.stores.dataSets.dataSetsById[dataSetId],
            )
            const newDataSets = dataSets.concat(...concatenatedDataSets)
            this.documentsById[documentId] = {
                ...document,
                data_sets: newDataSets,
            }
        })
    }

    @observed('isUpdating')
    public async removeDocumentFromDataSet(
        documentId: string,
        dataSetId: string,
    ) {
        await this.removeDocumentFromDataSets(documentId, [dataSetId])
    }

    @observed('isUpdating')
    public async removeDocumentFromDataSets(
        documentId: string,
        dataSetIds: string[],
    ) {
        await Promise.all(
            dataSetIds.map((dataSetId) =>
                visorPRORestClient.dataSet.removeDocument(
                    dataSetId,
                    documentId,
                ),
            ),
        )

        runInAction(() => {
            const document = this.documentsById[documentId]
            const dataSet = document.data_sets ?? []
            const newDataSet = dataSet.filter(
                (dataSet) => !dataSetIds.includes(dataSet.id),
            )
            this.documentsById[documentId] = {
                ...document,
                data_sets: newDataSet,
            }
        })
    }

    @observed('isUpdating')
    public async addDocumentToModels(documentId: string, modelIds: string[]) {
        await Promise.all(
            modelIds.map((modelId) =>
                visorPRORestClient.model.addDocumentsToProductModel(modelId, [
                    documentId,
                ]),
            ),
        )

        runInAction(() => {
            const document = this.documentsById[documentId]
            const d2m = document.document_to_product_model || []
            const newD2M = d2m.concat(
                modelIds.map((modelId) => ({
                    product_model: this.stores.models.modelsById[modelId],
                })),
            )
            this.documentsById[documentId] = {
                ...document,
                document_to_product_model: newD2M,
            }
        })
    }

    @observed('isUpdating')
    public async removeDocumentFromModels(
        documentId: string,
        modelIds: string[],
    ) {
        await this.removeDocumentsFromModels([documentId], modelIds)
    }

    @observed('isUpdating')
    public async removeDocumentsFromModels(
        documentIds: string[],
        modelIds: string[],
    ) {
        await Promise.all(
            modelIds.map((modelId) =>
                visorPRORestClient.model.removeDocumentsFromProductModel(
                    modelId,
                    documentIds,
                ),
            ),
        )

        runInAction(() => {
            documentIds.forEach((documentId) => {
                const document = this.documentsById[documentId]
                const d2m = document.document_to_product_model || []
                const newD2M = d2m.filter(
                    (r) => !modelIds.includes(r.product_model.id),
                )
                this.documentsById[documentId] = {
                    ...document,
                    document_to_product_model: newD2M,
                }
            })
        })
    }

    public addDocument(document: APIDocument) {
        this.documentsById[document.id] = document
    }
}

export const useDocumentById = (
    stores: Stores,
    documentId: string,
): APIDocument | undefined => {
    useEffect(() => {
        if (!stores.documents.documentsById[documentId]) {
            void stores.documents.get(documentId)
        }
    }, [documentId, stores.documents, stores.documents.documentsById])

    return stores.documents.documentsById[documentId]
}

export const useDocuments = (
    store: DocumentsStore,
    options?: APIListDocumentRequest,
): DictionaryArray<APIDocument> => {
    useEffect(() => {
        void store.list(options)
    }, [options, store])

    return store.documents
}
