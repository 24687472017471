import type { APIGetRandomSectionsRequest } from '@visorpro/client/dist/RestClient/services'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { Outlet, useMatches, useParams } from 'react-router-dom'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { DataSetNavBar } from '../../components/data-set-details/data-set-nav-bar'
import { useModal } from '../../components/modal'
import { QuestionModal } from '../../components/question-modal/question-modal'
import { TextButton } from '../../components/text-button'
import { useDataSetById } from '../../stores/data-sets-store'
import { RouteName } from '../../util/router'
import type { Stores } from '../../util/store'
import { DocumentSectionPreview } from '../documents/section-preview'
import { Stack } from '@mui/material'
import { useDocumentUploads, useMetadataUploads } from './hooks'
import { MetadataImportModal } from './metadata-import-modal/metadata-import-modal'

export interface DataSetDetailsPageProps {
    stores: Stores
}

export const DataSetDetailsPage: FC<DataSetDetailsPageProps> = observer(
    ({ stores }) => {
        const { dataSetId } = useParams()
        const dataSet = useDataSetById(stores, dataSetId!)

        const matches = useMatches()
        const lastMatch = matches[matches.length - 1]

        const documentUploads = useDocumentUploads(dataSetId!, stores)
        const metadataUploads = useMetadataUploads(
            dataSetId!,
        )

        const [
            documentSectionPreviewRequest,
            setDocumentSectionPreviewRequest,
        ] = useState<APIGetRandomSectionsRequest>()

        const createQuestionModal = useModal()

        const createDataSetTestRun = useCallback(() => {
            void stores.testRuns.createDataSetTestRun(dataSetId!)
        }, [stores, dataSetId])

        if (!dataSet) return null

        return (
            <Stack
                style={{
                    padding: '1rem',
                    boxSizing: 'border-box',
                    overflow: 'none',
                    height: '100vh',
                }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}>
                    <BreadCrumbs
                        name={dataSet.name}
                        path={[
                            {
                                name: 'Data sets',
                                to: '/data-sets',
                            },
                        ]}
                    />

                    {lastMatch.id === RouteName.DataSetDetailsDocuments && (
                        <div>
                            <TextButton onClick={metadataUploads.handleButtonClick}>
                                Upload metadata
                            </TextButton>

                            <TextButton
                                style={{ marginLeft: '1rem' }}
                                onClick={documentUploads.handleButtonClick}>
                                Upload documents
                            </TextButton>
                        </div>
                    )}

                    {lastMatch.id === RouteName.DataSetDetailsQuestions && (
                        <TextButton onClick={createQuestionModal.open}>
                            Create question
                        </TextButton>
                    )}

                    {lastMatch.id === RouteName.DataSetDetailsTestRuns && (
                        <TextButton
                            onClick={createDataSetTestRun}
                            style={{ color: 'var(--green-color)' }}>
                            Start test run
                        </TextButton>
                    )}
                </div>

                <DataSetNavBar />

                <Outlet />

                {documentSectionPreviewRequest && (
                    <DocumentSectionPreview
                        request={documentSectionPreviewRequest}
                        stores={stores}
                        onClose={() => {
                            setDocumentSectionPreviewRequest(undefined)
                        }}
                    />
                )}

                {createQuestionModal.state && (
                    <QuestionModal
                        stores={stores}
                        anchor={createQuestionModal.state.anchor}
                        onClose={createQuestionModal.close}
                        dataSetIds={[dataSetId!]}
                    />
                )}

                {metadataUploads.modal.state && (
                    <MetadataImportModal
                        dataSetId={dataSetId!}
                        stores={stores}
                        file={metadataUploads.modal.state.data!}
                        onClose={metadataUploads.modal.close}
                    />
                )}

                <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="application/pdf"
                    multiple
                    ref={documentUploads.fileInputRef}
                    onChange={documentUploads.handleFileChange}
                />

                <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="text/csv"
                    multiple
                    ref={metadataUploads.fileInputRef}
                    onChange={metadataUploads.handleFileChange}
                />
            </Stack>
        )
    },
)

