import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import { type Stores } from "../../../../util/store"
import { useParams } from "react-router-dom";
import { useDocumentById } from "../../../../stores/documents-store";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";

export interface DocumentInfoProps {
    stores: Stores
}

const DocumentInfo = observer(({ stores }: DocumentInfoProps) => {
    const { documentId } = useParams()
    const document = useDocumentById(stores, documentId!)
    if (!documentId || !document) return

    const onEnabledClicked = () => {
        void stores.documents.setDocumentEnabled(document.id, !document.is_enabled)
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">ID</TableCell>
                        <TableCell>{document.id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Title</TableCell>
                        <TableCell>{document.title}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Sha512 Hash</TableCell>
                        <TableCell>{document.sha512_hash}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">File Extension</TableCell>
                        <TableCell>{document.file_ext}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Original Filename</TableCell>
                        <TableCell>{document.original_filename}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Type</TableCell>
                        <TableCell>{document.type}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Is Enabled</TableCell>
                        <TableCell>
                            <Switch checked={document.is_enabled} onChange={onEnabledClicked} edge='start' />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Sections</TableCell>
                        <TableCell>{document._count?.sections}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Created At</TableCell>
                        <TableCell>{document.created_at.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Updated At</TableCell>
                        <TableCell>{document.updated_at.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    )
})

export default DocumentInfo