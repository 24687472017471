import type { FC } from 'react'
import { TextButton } from '../text-button'

export interface DataSetsBulkSelectModalProps {
    onManageOrganizationsPress(): void
}

export const DataSetsBulkSelectModal: FC<DataSetsBulkSelectModalProps> = ({
    onManageOrganizationsPress,
}) => {
    return (
        <div
            style={{
                position: 'absolute',
                bottom: '3rem',
                height: '3rem',
                right: '2rem',
                backgroundColor: 'var(--background-dark-color)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '5px',
                padding: '0 1rem',
            }}>
            <TextButton onClick={onManageOrganizationsPress}>
                Manage organizations
            </TextButton>
        </div>
    )
}
