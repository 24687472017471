import type { FC, CSSProperties } from 'react'
import { useEffect, useRef } from 'react'
import type { usePdfDocument } from '../util/pdf'

export interface PdfPageProps {
    pageNumber: number
    style?: CSSProperties
    onPageClick?(): void
    doc: ReturnType<typeof usePdfDocument>
    onLoad?(element: HTMLDivElement): void
}

export const PdfPage: FC<PdfPageProps> = ({
    pageNumber,
    style,
    onPageClick,
    doc,
    onLoad,
}) => {
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const element = containerRef.current

        if (!doc.pdfDoc || !element) return

        doc.renderPage({
            element,
            pageNumber,
            pageWidth: containerRef.current.clientWidth,
            onPageClick,
        }).then(() => {
            onLoad?.(element)
        })

        return () => {
            element.innerHTML = ''
        }
    }, [doc, pageNumber, onPageClick, onLoad])

    return (
        <div
            ref={containerRef}
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                ...style,
            }}
        />
    )
}
