import type { CSSProperties, FC } from 'react'
import { useCallback } from 'react'
import { useAutoResize } from './hooks'

export interface FormTextAreaProps {
    textAreaRef: React.RefObject<HTMLTextAreaElement>
    placeholder?: string
    value: string
    onChange?(value: string): void
    style?: CSSProperties
    maxHeightPx?: number
    onEnterPress?(): void
    onDeleteWhileEmpty?(): void
}

export const FormTextArea: FC<FormTextAreaProps> = ({
    textAreaRef,
    value,
    onChange,
    placeholder,
    style,
    maxHeightPx = Number.MAX_SAFE_INTEGER,
    onEnterPress,
    onDeleteWhileEmpty,
}) => {
    useAutoResize(textAreaRef, maxHeightPx)

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault() // Prevent new line
                onEnterPress?.()
            } else if (e.key === 'Backspace' && value === '') {
                e.preventDefault()
                onDeleteWhileEmpty?.()
            }
        },
        [onEnterPress, onDeleteWhileEmpty, value],
    )

    const _onChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChange?.(e.target.value)
        },
        [onChange],
    )

    return (
        <textarea
            ref={textAreaRef}
            className="text-small"
            rows={1}
            style={{
                width: '100%',
                padding: '0',
                border: 'none',
                boxSizing: 'border-box',
                fontSize: '1rem',
                outline: 'none',
                backgroundColor: 'transparent',
                color: 'var(--foreground-color)',
                resize: 'none',
                maxHeight: `${maxHeightPx}px`,
                overflowY: 'auto',
                ...style,
            }}
            placeholder={placeholder}
            value={value}
            onChange={_onChange}
            onKeyDown={onKeyDown}
        />
    )
}
