import type { APITestRunQuestion } from '@visorpro/client'
import type { APICreateOrCopyTestRunRequest } from '@visorpro/client/dist/RestClient/services/TestRunService'
import type { APITestRun } from '@visorpro/client/dist/types/APITestRun'
import { makeAutoObservable, runInAction } from 'mobx'
import pMap from 'p-map'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { observed } from '../util/observed-decorator'

const TEST_RUN_PROCESS_CONCURRENCY = 10

const defaultPagination = {
    offset: 0,
    limit: 100,
}

export class TestRunsStore {
    public testRunsById: Record<string, APITestRun> = {}
    public testRunIdsByDocumentId: Record<string, string[]> = {}
    public testRunIdsByDataSetId: Record<string, string[]> = {}
    public testRunQuestionsById: Record<string, APITestRunQuestion> = {}
    public testRunIds: string[] = []
    public isFetched = false
    public isProcessingTestRuns = false
    public isCopyingTestRuns = false
    public isUpdating = false

    constructor() {
        makeAutoObservable(this)
    }

    public getPendingTestRuns() {
        const pendingTestRuns: APITestRun[] = []

        this.testRunIds.forEach((testRunId) => {
            const testRun = this.testRunsById[testRunId]

            const isPending = testRun.test_run_questions?.some((question) => {
                return question.acceptance_criteria.some(
                    (item) => item.success === null,
                )
            })

            if (isPending) {
                pendingTestRuns.push(testRun)
            }
        })

        return pendingTestRuns
    }

    @observed('isProcessingTestRuns')
    public async processTestRuns() {
        await pMap(
            this.getPendingTestRuns(),
            async (testRun) => {
                await this.processTestRun(testRun.id)
            },
            {
                concurrency: TEST_RUN_PROCESS_CONCURRENCY,
            },
        )
    }

    public async processTestRun(testRunId: string) {
        await visorPRORestClient.testRun.processTestRun(testRunId)
        await this.getTestRunById(testRunId)
    }

    @observed('isCopyingTestRuns')
    public async copy(
        testRunIds: string[],
        config?: APICreateOrCopyTestRunRequest,
    ) {
        const testRuns = await pMap(
            testRunIds,
            async (testRunId) => {
                return visorPRORestClient.testRun.copyTestRun(testRunId, config)
            },
            { concurrency: 5 },
        )

        runInAction(() => {
            const testRunIds = this.addTestRuns(testRuns)
            this.testRunIds = testRunIds.concat(this.testRunIds)
        })
    }

    @observed('isUpdating')
    public async create(
        documentId: string,
        config: APICreateOrCopyTestRunRequest,
    ) {
        try {
            const testRun = await visorPRORestClient.testRun.createTestRun(
                documentId,
                config,
            )

            runInAction(() => {
                this.addTestRuns([testRun])
            })

            return testRun.id
        } catch (e) {
            toast.error(
                `Failed to create test question: ${(e as Error).message}`,
            )
        }
    }

    public async createDataSetTestRun(dataSetId: string) {
        try {
            const dataSetTestRun =
                await visorPRORestClient.testRun.createDataSetTestRun(dataSetId)

            runInAction(() => {
                const testRunsById: Record<string, APITestRun> = {}
                const dataSetTestRunIds = new Set(
                    this.testRunIdsByDataSetId[dataSetId] ?? [],
                )

                dataSetTestRun.test_runs?.forEach((testRun) => {
                    testRunsById[testRun.id] = testRun
                    dataSetTestRunIds.add(testRun.id)
                })

                this.testRunsById = {
                    ...this.testRunsById,
                    ...testRunsById,
                }

                this.testRunIdsByDataSetId = {
                    ...this.testRunIdsByDataSetId,
                    [dataSetId]: Array.from(dataSetTestRunIds),
                }
            })

            return dataSetTestRun.id
        } catch (e) {
            toast.error(
                `Failed to create test question: ${(e as Error).message}`,
            )
        }
    }

    public async list() {
        try {
            const testRuns =
                await visorPRORestClient.testRun.getTestRuns(defaultPagination)

            runInAction(() => {
                const testRunIds = this.addTestRuns(testRuns.items)
                this.testRunIds = testRunIds
                this.isFetched = true
            })
        } catch (e) {
            toast.error(`Failed to get test runs: ${(e as Error).message}`)
        }
    }

    public async getTestRunsByDocumentId(documentId: string) {
        try {
            const testRuns = await visorPRORestClient.testRun.getTestRuns({
                document_id: documentId,
                ...defaultPagination,
            })

            runInAction(() => {
                this.addTestRuns(testRuns.items)
            })
        } catch (e) {
            toast.error(`Failed to get test runs: ${(e as Error).message}`)
        }
    }

    public async getDataSetTestRuns(dataSetId: string) {
        try {
            const testRuns = await visorPRORestClient.testRun.getTestRuns({
                data_set_id: dataSetId,
                ...defaultPagination,
            })

            runInAction(() => {
                const testRunsById: Record<string, APITestRun> = {}
                const dataSetTestRunIds = new Set<string>()

                testRuns.items.forEach((testRun) => {
                    testRunsById[testRun.id] = testRun
                    dataSetTestRunIds.add(testRun.id)
                })

                this.testRunsById = {
                    ...this.testRunsById,
                    ...testRunsById,
                }

                this.testRunIdsByDataSetId = {
                    ...this.testRunIdsByDataSetId,
                    [dataSetId]: Array.from(dataSetTestRunIds),
                }
            })
        } catch (e) {
            toast.error(`Failed to get test runs: ${(e as Error).message}`)
        }
    }

    public async getTestRunById(testRunId: string) {
        try {
            const testRun =
                await visorPRORestClient.testRun.getTestRunById(testRunId)

            runInAction(() => {
                this.addTestRuns([testRun])
            })
        } catch (e) {
            toast.error(`Failed to get test runs: ${(e as Error).message}`)
        }
    }

    private addTestRuns(testRuns: APITestRun[]) {
        const testRunIds: string[] = []
        const testRunsById: Record<string, APITestRun> = {}
        const testRunIdsByDocumentId: Record<string, Set<string>> = {}
        const testRunQuestionsById: Record<string, APITestRunQuestion> = {}

        Object.keys(this.testRunIdsByDocumentId).forEach((documentId) => {
            testRunIdsByDocumentId[documentId] = new Set(
                this.testRunIdsByDocumentId[documentId],
            )
        })

        testRuns.forEach((testRun) => {
            testRunIds.push(testRun.id)

            // test run
            testRunsById[testRun.id] = testRun

            // documents
            if (!testRunIdsByDocumentId[testRun.document_id]) {
                testRunIdsByDocumentId[testRun.document_id] = new Set()
            }

            testRunIdsByDocumentId[testRun.document_id].add(testRun.id)

            // questions
            testRun.test_run_questions?.forEach((question) => {
                testRunQuestionsById[question.id] = question
            })
        })

        this.testRunsById = {
            ...this.testRunsById,
            ...testRunsById,
        }
        this.testRunIdsByDocumentId = Object.fromEntries(
            Object.entries(testRunIdsByDocumentId).map(([documentId, ids]) => [
                documentId,
                Array.from(ids),
            ]),
        )
        this.testRunQuestionsById = {
            ...testRunQuestionsById,
            ...this.testRunQuestionsById,
        }

        return testRunIds
    }
}

export const useDocumentTestRuns = (stores: Stores, documentId: string) => {
    useEffect(() => {
        if (!stores.testRuns.testRunIdsByDocumentId[documentId]) {
            void stores.testRuns.getTestRunsByDocumentId(documentId)
        }
    }, [documentId, stores])

    return useMemo(() => {
        const ids = stores.testRuns.testRunIdsByDocumentId[documentId] ?? []
        return ids.map((testRunId) => stores.testRuns.testRunsById[testRunId])
    }, [
        documentId,
        stores.testRuns.testRunIdsByDocumentId,
        stores.testRuns.testRunsById,
    ])
}

export const useDataSetTestRuns = (stores: Stores, dataSetId: string) => {
    useEffect(() => {
        if (!stores.testRuns.testRunIdsByDataSetId[dataSetId]) {
            void stores.testRuns.getDataSetTestRuns(dataSetId)
        }
    }, [dataSetId, stores])

    return useMemo(() => {
        return (
            stores.testRuns.testRunIdsByDataSetId[dataSetId]?.map(
                (testRunId) => stores.testRuns.testRunsById[testRunId],
            ) ?? []
        )
    }, [
        dataSetId,
        stores.testRuns.testRunIdsByDataSetId,
        stores.testRuns.testRunsById,
    ])
}

export const useTestRun = (
    stores: Stores,
    testRunId: string,
): APITestRun | undefined => {
    useEffect(() => {
        if (!stores.testRuns.testRunsById[testRunId]) {
            void stores.testRuns.getTestRunById(testRunId)
        }
    }, [testRunId, stores])

    return useMemo(() => {
        return stores.testRuns.testRunsById[testRunId]
    }, [testRunId, stores.testRuns.testRunsById])
}

export const useTestRuns = (stores: Stores) => {
    useEffect(() => {
        if (!stores.testRuns.isFetched) {
            void stores.testRuns.list()
        }
    }, [stores])

    return useMemo(() => {
        return stores.testRuns.testRunIds.map(
            (testRunId) => stores.testRuns.testRunsById[testRunId],
        )
    }, [stores.testRuns.testRunsById, stores.testRuns.testRunIds])
}
