import { makeAutoObservable, runInAction } from 'mobx'
import { useEffect } from 'react'
import type { APIUser } from '@visorpro/client'

import { visorPRORestClient } from '../util/api'
import { observed } from '../util/observed-decorator'

export class OrganizationMemberStore {
    public organizationId: string | null = null
    public membersById: Record<string, APIUser> = {}
    public isFetching = false
    public isUpdating = false

    constructor() {
        makeAutoObservable(this)
    }

    @observed('isFetching')
    public async fetchOrganizationMembers(organizationId: string) {
        runInAction(() => {
            this.organizationId = organizationId
        })

        const response =
            await visorPRORestClient.organization.getMembers(organizationId)

        runInAction(() => {
            this.membersById = {}
            this.organizationId = organizationId
            response.items.forEach((user: APIUser) => {
                this.membersById[user.id] = user
            })
        })
    }

    public async addOrganizationMembership(
        userId: string,
        organizationId: string,
    ) {
        const membership =
            await visorPRORestClient.organization.createMembership(
                organizationId,
                userId,
            )

        runInAction(() => {
            this.membersById[userId] = membership.user!
        })
    }

    public async removeOrganizationMembership(
        userId: string,
        organizationId: string,
    ) {
        await this.removeOrganizationMemberships([userId], organizationId)
    }

    public async removeOrganizationMemberships(
        userIds: string[],
        organizationId: string,
    ) {
        await Promise.all(
            userIds.map((userId) =>
                visorPRORestClient.organization.deleteMembership(
                    organizationId,
                    userId,
                ),
            ),
        )

        runInAction(() => {
            userIds.forEach((userId) => {
                delete this.membersById[userId]
            })
        })
    }
}

export const useOrganizationMembers = (
    store: OrganizationMemberStore,
    organizationId: string,
) => {
    useEffect(() => {
        if (organizationId !== store.organizationId) {
            void store.fetchOrganizationMembers(organizationId)
        }
    }, [organizationId, store, store.organizationId])

    return Object.values(store.membersById)
}
