import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DeleteButton } from '../../../../components/delete-button'
import { Table } from '../../../../components/table/table'
import { TableDateCell } from '../../../../components/table/table-date-cell'
import { TableHeader } from '../../../../components/table/table-header'
import { TableRow } from '../../../../components/table/table-row'
import { TableTextCell } from '../../../../components/table/table-text-cell'
import { useDomainsByOrganizationId } from '../../../../stores/organization-domains-store'
import type { Stores } from '../../../../util/store'

export interface OrganizationDomainsPageProps {
    stores: Stores
}

export const OrganizationDomainsPage = observer(
    ({ stores }: OrganizationDomainsPageProps) => {
        const { organizationId } = useParams()
        const domains = useDomainsByOrganizationId(stores, organizationId!)

        useEffect(() => {
            if (!domains) {
                void stores.organizationDomains.getDomains(organizationId!)
            }
        }, [domains, organizationId, stores.organizationDomains])

        const deleteDomain = useCallback(
            (domainId: string) => {
                void stores.organizationDomains.deleteDomain(
                    organizationId!,
                    domainId,
                )
            },
            [stores.organizationDomains, organizationId],
        )

        const now = DateTime.now()

        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Domain</TableHeader>
                            <TableHeader>Created</TableHeader>
                            <TableHeader>Actions</TableHeader>
                        </tr>
                    </thead>

                    <tbody>
                        {domains?.map(({ id, domain, created_at }) => {
                            return (
                                <TableRow key={id}>
                                    <TableTextCell>{domain}</TableTextCell>

                                    <TableDateCell
                                        date={created_at}
                                        now={now}
                                    />

                                    <td className="table-cell">
                                        <DeleteButton
                                            onDelete={() => deleteDomain(id)}
                                        />
                                    </td>
                                </TableRow>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    },
)
