import { runInAction } from 'mobx'

// eslint-disable-next-line
type AsyncFunction = (...args: Parameters<any>) => Promise<any>

/**
 * Method Decorator to monitor an async method's execution state
 * @param observable The name of a boolean property of the class to record the method's execution state
 */
export const observed = <T extends Record<K, boolean>, K extends keyof T>(
    observable?: K,
) => {
    return (target: T, key: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value as AsyncFunction
        type MethodParameters = Parameters<typeof originalMethod>
        type MethodReturnType = ReturnType<typeof originalMethod>

        descriptor.value = async function (this: T, ...args: MethodParameters) {
            runInAction(() => {
                if (observable && observable in this) {
                    this[observable] = true as T[K]
                }
            })
            const result = (await originalMethod.apply(
                this,
                args,
            )) as MethodReturnType

            runInAction(() => {
                if (observable && observable in this) {
                    this[observable] = false as T[K]
                }
            })
            return result
        }

        return descriptor
    }
}
