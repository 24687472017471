import { MdArrowForward } from "react-icons/md"
import { TextButton } from "../../../../components/text-button"
import { useCallback } from "react"

interface MappingRowProps {
    name: string
    value?: string
    onChange?(value: string): void
    description?: string
}

export const MappingRow = ({
    name,
    value,
    onChange,
    description,
}: MappingRowProps) => {
    const columns = [
        'Language',
        'Model',
        'Description',
        'P.I.N.',
        'Date Added',
        'Image URL',
        'Item Number',
        'Type',
    ]

    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}>
                <div>{name}</div>

                <MdArrowForward
                    color='var(--comment-color)'
                    style={{ marginLeft: '1rem' }}
                />

                <div style={{
                    marginLeft: '1rem',
                    gap: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    {
                        columns.map(column => (
                            <MappingColumn
                                key={column}
                                column={column}
                                isActive={value === column}
                                onChange={onChange}
                            />
                        ))
                    }
                </div>
            </div>

            {
                typeof description === 'string' &&
                <div style={{
                    color: 'var(--comment-color)',
                    fontSize: '0.8rem',
                    marginTop: '0.25rem',
                }}>
                    {description}
                </div>
            }
        </div>
    )
}

export interface MappingColumnProps {
    isActive: boolean
    column: string
    onChange?(value: string): void
}

const MappingColumn = ({
    isActive,
    column,
    onChange,
}: MappingColumnProps) => {
    const _onChange = useCallback(() => {
        onChange?.(column)
    }, [onChange])

    return (
        <TextButton
            disabled={isActive}
            style={{
                fontSize: '0.8rem',
                color: isActive
                    ? 'var(--green-color)'
                    : 'var(--comment-color)',
            }}
            onClick={_onChange}>
            {column}
        </TextButton>
    )
}


