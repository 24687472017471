import type { CSSProperties, FC } from 'react'

export interface PageTitleProps {
    style?: CSSProperties
    children: string
}

export const PageTitle: FC<PageTitleProps> = ({ children, style }) => {
    return (
        <h1
            style={{
                color: 'var(--foreground-color)',
                fontWeight: 700,
                ...style,
            }}>
            {children}
        </h1>
    )
}
