import pMap from "p-map"
import { apiClient } from "../../util/api"
import { makeObservable, observable, runInAction } from "mobx"

const ignoredFileNames = new Set(['.DS_Store'])
const attachmentKeys = ['images', 'attachments']

const getFileHash = async (file: File) => {
    const arrayBuffer = await file.arrayBuffer()
    const hashBuffer = await crypto.subtle.digest('SHA-512', arrayBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray
        .map((byte) => byte.toString(16).padStart(2, '0'))
        .join('')

    return hashHex
}

const getBaseDirectory = (path: string) => {
    // 1. group files in the same directory
    // remove file name
    const index = path.lastIndexOf('/')
    if (index !== -1) {
        path = path.slice(0, index)
    }

    // remove file directories
    for (const key of attachmentKeys) {
        if (path.endsWith(key)) {
            path = path.slice(0, -key.length)
            break
        }
    }

    // remove trailing '/'
    if (path.endsWith('/')) {
        path = path.slice(0, -1)
    }

    return path
}

const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                resolve(e.target.result as string);
            }
        };
        reader.onerror = () => {
            reject(new Error("Failed to read file"));
        };
        reader.readAsText(file);
    });
}

export class KnowledgeArticlesUploadStore {
    public isUploading = false
    public uploadStartDate: Date | undefined
    public lastUpdateDate: Date | undefined
    public totalItemsToUpload = 0
    public totalSuccesses = 0
    public totalFailures = 0

    // to print avg duration
    public totalDuration = 0

    constructor() {
        makeObservable(this, {
            isUploading: observable,
            uploadStartDate: observable,
            totalItemsToUpload: observable,
            totalSuccesses: observable,
            totalFailures: observable,
            totalDuration: observable,
        })
    }

    public handleFileChange = async (files: FileList | null) => {
        if (!files || this.isUploading) return

        runInAction(() => {
            this.isUploading = true
            this.uploadStartDate = new Date()
            this.lastUpdateDate = new Date()
        })

        try {
            // 1. gather all files for each ka before processing them
            const filesByBaseDirectory: { [key: string]: File[] } = {}

            Array.from(files).forEach((file) => {
                if (ignoredFileNames.has(file.name)) return

                const path = getBaseDirectory(file.webkitRelativePath)
                if (!filesByBaseDirectory[path]) {
                    filesByBaseDirectory[path] = []
                }
                filesByBaseDirectory[path].push(file)
            })

            runInAction(() => {
                this.totalItemsToUpload = Object.keys(filesByBaseDirectory).length
                this.totalSuccesses = 0
                this.totalFailures = 0
                this.totalDuration = 0
            })

            // 2. upload a few at a time
            await pMap(
                Object.values(filesByBaseDirectory),
                async (files) => {
                    try {
                        const start = performance.now()
                        await this.uploadFile(files)
                        const end = performance.now()
                        runInAction(() => {
                            this.totalSuccesses++
                            this.totalDuration += (end - start)
                            this.lastUpdateDate = new Date()
                        })
                    } catch (e) {
                        runInAction(() => {
                            this.totalFailures++
                            this.lastUpdateDate = new Date()
                        })
                    }
                },
                { concurrency: 5, stopOnError: false },
            )
        } catch (e) {
            console.log(e)
        } finally {
            runInAction(() => {
                this.isUploading = false
            })
        }
    }

    private uploadFile = async (files: File[], remainingRetries = 3) => {
        try {
            const images: File[] = []
            const attachments: File[] = []

            let contentFilePromise: Promise<string> | undefined

            files.forEach(file => {
                if (file.webkitRelativePath.includes('images')) {
                    images.push(file)
                } else if (file.webkitRelativePath.includes('attachments')) {
                    attachments.push(file)
                } else if (file.name.endsWith('.json')) {
                    if (contentFilePromise) {
                        throw new Error('Multiple JSON files found in the same directory')
                    }

                    contentFilePromise = readFileAsText(file)
                }
            })

            if (!contentFilePromise) {
                const directory = files[0].webkitRelativePath
                console.warn(`No JSON file found in directory ${directory}`)
                return
            }

            const formData = new FormData();

            // note: in formdata the order matters; content has to be first for the api to work
            const content = await contentFilePromise
            formData.append('content', content)

            const addFile = async (file: File, key: string) => {
                formData.append(`file_hash_${file.name}`, await getFileHash(file))
                formData.append(key, file)
            }

            for (const image of images) {
                await addFile(image, 'images')
            }

            for (const attachment of attachments) {
                await addFile(attachment, 'attachments')
            }

            await apiClient.post('/admin/knowledge-article', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        } catch (e) {
            if (remainingRetries === 0) {
                throw e
            }

            await this.uploadFile(files, remainingRetries - 1)
        }
    }
}
