import type { FC } from 'react'
import { useCallback } from 'react'
import './checkbox.css'

export interface CheckboxProps {
    value?: boolean
    onChange(value: boolean): void
}

export const Checkbox: FC<CheckboxProps> = ({ value, onChange }) => {
    const handleOnChange = useCallback(() => {
        onChange(!value)
    }, [onChange, value])

    return (
        <label className="checkbox">
            <input
                type="checkbox"
                checked={value ?? false}
                onChange={handleOnChange}
            />
            <span className="checkmark"></span>
        </label>
    )
}
