import { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { type PaginatedRequestQueryParams } from '@visorpro/client'
import { type APIGetQueueTaskRequestFilteringParams } from '@visorpro/client/dist/PipelineClient/services'
import { useInterval } from 'react-use'

import type { Stores } from '../../util/store'
import { useQueueTasks } from '../../stores/queue-tasks-store'
import { QueueTasksTable } from '../../components/tasks-table'

export interface QueueTasksPageProps {
    stores: Stores
}

const INTERVAL_DELAY = 5000

export const QueueTasksPage = observer(({ stores }: QueueTasksPageProps) => {
    const tasks = useQueueTasks(stores.queueTasks, {})

    useInterval(() => {
        void stores.queueTasks.list(stores.queueTasks.queryParams)
    }, INTERVAL_DELAY)

    const setPagination = useCallback((pagination: PaginatedRequestQueryParams) => {
        void stores.queueTasks.list({
            ...stores.queueTasks.queryParams,
            ...pagination,
        })
    }, [stores.queueTasks])

    const setFilters = useCallback((filters: APIGetQueueTaskRequestFilteringParams) => {
        void stores.queueTasks.list({
            offset: stores.queueTasks.queryParams.offset,
            limit: stores.queueTasks.queryParams.limit,
            ...filters,
        })
    }, [stores.queueTasks])

    return (
        <QueueTasksTable
            tasks={tasks}
            rowCount={stores.queueTasks.totalTasks}
            isLoading={stores.queueTasks.isFetching && !stores.queueTasks.isFetched}
            isSaving={stores.queueTasks.isUpdating}
            pagination={stores.queueTasks.queryParams}
            setPagination={setPagination}
            filters={stores.queueTasks.queryParams}
            setFilters={setFilters}
        />
    )
})
