import { observer } from "mobx-react-lite"
import { useInterval } from "react-use"
import { useCallback, useMemo } from "react"
import type { PaginatedRequestQueryParams } from "@visorpro/client"
import type { APIGetQueueTaskRequestFilteringParams } from "@visorpro/client/dist/PipelineClient/services"

import { QueueTasksTable } from "../../../../components/tasks-table"
import { useQueueTasks } from "../../../../stores/queue-tasks-store"
import { type Stores } from "../../../../util/store"
import { useParams } from "react-router-dom"
import { useDocumentById } from "../../../../stores/documents-store"


const INTERVAL_DELAY = 5000


export interface QueueTasksPageProps {
  stores: Stores
}

export const DocumentQueueTasks = observer(({ stores }: QueueTasksPageProps) => {
  const { documentId } = useParams()

  if (!documentId) {
    return null
  }

  useDocumentById(stores, documentId)

  const tasksStore = useMemo(() => stores.documents.documentQueueTasksStoresById[documentId], [stores.documents.documentQueueTasksStoresById, documentId])
  const tasks = useQueueTasks(tasksStore, { document_id: documentId })

  useInterval(() => {
    void stores.queueTasks.list(stores.queueTasks.queryParams)
  }, INTERVAL_DELAY)

  const setPagination = useCallback((pagination: PaginatedRequestQueryParams) => {
    void stores.queueTasks.list({
      ...stores.queueTasks.queryParams,
      ...pagination,
    })
  }, [stores.queueTasks])

  const setFilters = useCallback((filters: APIGetQueueTaskRequestFilteringParams) => {
    void stores.queueTasks.list({
      offset: stores.queueTasks.queryParams.offset,
      limit: stores.queueTasks.queryParams.limit,
      ...filters,
    })
  }, [stores.queueTasks])

  return (
    <QueueTasksTable
      tasks={tasks}
      rowCount={stores.queueTasks.totalTasks}
      isLoading={stores.queueTasks.isFetching && !stores.queueTasks.isFetched}
      isSaving={stores.queueTasks.isUpdating}
      pagination={stores.queueTasks.queryParams}
      setPagination={setPagination}
      filters={stores.queueTasks.queryParams}
      setFilters={setFilters}
      hideMetadataColumn={true}
    />
  )
})

export default DocumentQueueTasks
