import type { APITestQuestion } from '@visorpro/client'
import type {
    APICreateTestQuestionRequest,
    APIUpdateTestQuestionRequest,
} from '@visorpro/client/dist/RestClient/services/TestQuestionService'
import { makeAutoObservable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { observed } from '../util/observed-decorator'

export class TestQuestionsStore {
    public testQuestionsById: Record<string, APITestQuestion> = {}
    public testQuestionIdsByDataSetId: Record<string, string[]> = {}
    public testQuestionsIdsByDocumentId: Record<string, string[]> = {}
    public isUpdating = false

    constructor() {
        makeAutoObservable(this)
    }

    @observed('isUpdating')
    public async create(input: APICreateTestQuestionRequest) {
        if (!this.validate(input)) {
            return
        }

        try {
            const testQuestion =
                await visorPRORestClient.testQuestion.createTestQuestion(input)

            runInAction(() => {
                this.testQuestionsById[testQuestion.id] = testQuestion
                const newTestQuestionsIdsByDocumentId: Record<
                    string,
                    string[]
                > = {}
                const newTestQuestionIdsByDataSetId: Record<string, string[]> =
                    {}

                input.document_ids?.forEach((documentId) => {
                    newTestQuestionsIdsByDocumentId[documentId] = [
                        testQuestion.id,
                        ...this.testQuestionsIdsByDocumentId[documentId],
                    ]
                })

                input.data_set_ids?.forEach((dataSetId) => {
                    newTestQuestionIdsByDataSetId[dataSetId] = [
                        testQuestion.id,
                        ...this.testQuestionIdsByDataSetId[dataSetId],
                    ]
                })

                this.testQuestionsIdsByDocumentId = {
                    ...this.testQuestionsIdsByDocumentId,
                    ...newTestQuestionsIdsByDocumentId,
                }

                this.testQuestionIdsByDataSetId = {
                    ...this.testQuestionIdsByDataSetId,
                    ...newTestQuestionIdsByDataSetId,
                }
            })

            return testQuestion.id
        } catch (e) {
            toast.error(
                `Failed to create test question: ${(e as Error).message}`,
            )
        }
    }

    public async update(
        testQuestionId: string,
        input: APIUpdateTestQuestionRequest,
    ) {
        if (!this.validate(input)) {
            return
        }

        try {
            const testQuestion =
                await visorPRORestClient.testQuestion.updateTestQuestion(
                    testQuestionId,
                    input,
                )

            runInAction(() => {
                this.testQuestionsById[testQuestion.id] = testQuestion
            })

            return testQuestion.id
        } catch (e) {
            toast.error(
                `Failed to update test question: ${(e as Error).message}`,
            )
        }
    }

    public async delete(testQuestionId: string) {
        try {
            await visorPRORestClient.testQuestion.deleteTestQuestion(
                testQuestionId,
            )

            runInAction(() => {
                const testQuestion = this.testQuestionsById[testQuestionId]

                if (testQuestion) {
                    const newTestQuestionsIdsByDocumentId: Record<
                        string,
                        string[]
                    > = {}
                    const newTestQuestionIdsByDataSetId: Record<
                        string,
                        string[]
                    > = {}

                    testQuestion.documents.forEach((document) => {
                        newTestQuestionsIdsByDocumentId[document.document_id] =
                            this.testQuestionsIdsByDocumentId[
                                document.document_id
                            ].filter((id) => id !== testQuestionId)
                    })

                    testQuestion.data_sets.forEach((dataSet) => {
                        newTestQuestionIdsByDataSetId[dataSet.data_set_id] =
                            this.testQuestionIdsByDataSetId[
                                dataSet.data_set_id
                            ].filter((id) => id !== testQuestionId)
                    })

                    this.testQuestionsIdsByDocumentId = {
                        ...this.testQuestionsIdsByDocumentId,
                        ...newTestQuestionsIdsByDocumentId,
                    }

                    this.testQuestionIdsByDataSetId = {
                        ...this.testQuestionIdsByDataSetId,
                        ...newTestQuestionIdsByDataSetId,
                    }
                }

                delete this.testQuestionsById[testQuestionId]
            })
        } catch (e) {
            toast.error(
                `Failed to delete test question: ${(e as Error).message}`,
            )
        }
    }

    private validate(
        input: APICreateTestQuestionRequest | APIUpdateTestQuestionRequest,
    ) {
        if (input.text.length === 0) {
            toast.error('Question text is required')
            return false
        }

        if (input.acceptance_criteria.length === 0) {
            toast.error('At least one acceptance criteria is required')
            return false
        }

        return true
    }

    public async getByDocumentId(documentId: string) {
        try {
            const testQuestions =
                await visorPRORestClient.document.getTestQuestions(documentId)

            runInAction(() => {
                const testQuestionsById: Record<string, APITestQuestion> = {}
                const testQuestionIds: string[] = []

                testQuestions.items.forEach((testQuestion) => {
                    testQuestionsById[testQuestion.id] = testQuestion
                    testQuestionIds.push(testQuestion.id)
                })

                this.testQuestionsById = {
                    ...this.testQuestionsById,
                    ...testQuestionsById,
                }

                this.testQuestionsIdsByDocumentId[documentId] = testQuestionIds
            })
        } catch (e) {
            toast.error(`Failed to get test questions: ${(e as Error).message}`)
        }
    }

    public async getByDataSetId(dataSetId: string) {
        try {
            const testQuestions =
                await visorPRORestClient.dataSet.getTestQuestions(dataSetId)

            runInAction(() => {
                const testQuestionsById: Record<string, APITestQuestion> = {}
                const testQuestionIds: string[] = []

                testQuestions.items.forEach((testQuestion) => {
                    testQuestionsById[testQuestion.id] = testQuestion
                    testQuestionIds.push(testQuestion.id)
                })

                this.testQuestionsById = {
                    ...this.testQuestionsById,
                    ...testQuestionsById,
                }

                this.testQuestionIdsByDataSetId[dataSetId] = testQuestionIds
            })
        } catch (e) {
            toast.error(`Failed to get test questions: ${(e as Error).message}`)
        }
    }
}

export const useDocumentTestQuestions = (
    stores: Stores,
    documentId: string,
) => {
    useEffect(() => {
        if (!stores.testQuestions.testQuestionsIdsByDocumentId[documentId]) {
            void stores.testQuestions.getByDocumentId(documentId)
        }
    }, [documentId, stores.testQuestions])

    return useMemo(() => {
        const ids =
            stores.testQuestions.testQuestionsIdsByDocumentId[documentId] ?? []
        return ids.map(
            (testQuestionId) =>
                stores.testQuestions.testQuestionsById[testQuestionId],
        )
    }, [
        documentId,
        stores.testQuestions.testQuestionsIdsByDocumentId,
        stores.testQuestions.testQuestionsById,
    ])
}

export const useDataSetTestQuestions = (stores: Stores, dataSetId: string) => {
    useEffect(() => {
        if (!stores.testQuestions.testQuestionIdsByDataSetId[dataSetId]) {
            void stores.testQuestions.getByDataSetId(dataSetId)
        }
    }, [dataSetId, stores.testQuestions])

    return useMemo(() => {
        const ids =
            stores.testQuestions.testQuestionIdsByDataSetId[dataSetId] ?? []
        return ids.map(
            (testQuestionId) =>
                stores.testQuestions.testQuestionsById[testQuestionId],
        )
    }, [
        dataSetId,
        stores.testQuestions.testQuestionIdsByDataSetId,
        stores.testQuestions.testQuestionsById,
    ])
}
