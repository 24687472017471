import { TextField, type TextFieldProps } from '@mui/material'
import type { CSSProperties, FC } from 'react'
import { useCallback } from 'react'

export interface FormTextInputProps extends Omit<TextFieldProps, 'onChange'> {
    autoFocus?: boolean
    placeholder?: string
    onChange?: (value: string) => void
    style?: CSSProperties
}

export const FormTextInput: FC<FormTextInputProps> = ({
    autoFocus = true,
    value,
    onChange,
    placeholder,
    ...props
}) => {
    const _onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(e.target.value)
        },
        [onChange],
    )

    return (
        <TextField
            variant="standard"
            autoFocus={autoFocus}
            placeholder={placeholder}
            fullWidth
            value={value}
            onChange={_onChange}
            {...props}
        />
    )
}
