import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import type { APIOrganization } from '@visorpro/client'

import { BreadCrumbs } from '../../components/breadcrumbs'
import { DeleteButton } from '../../components/delete-button'
import { useModal } from '../../components/modal'
import { Table } from '../../components/table/table'
import { TableDateCell } from '../../components/table/table-date-cell'
import { TableHeader } from '../../components/table/table-header'
import { TableLinkCell } from '../../components/table/table-link-cell'
import { TableRow } from '../../components/table/table-row'
import { TextButton } from '../../components/text-button'
import { useUserById } from '../../stores/user-store'
import { formatUserName } from '../../util/format'
import type { Stores } from '../../util/store'
import { useOrganizations } from '../../stores/organization-store'
import { SelectableListModal } from '../../components/modals/search/selectable-list-modal'

export interface UserDetailsPageProps {
    stores: Stores
}

export const UserDetailsPage: FC<UserDetailsPageProps> = observer(
    ({ stores }) => {
        const { userId } = useParams()

        const user = useUserById(stores.users, userId!)

        const orgsModal = useModal()

        const onSelect = useCallback(
            (org: APIOrganization) => {
                if (
                    user.memberships?.some((m) => m.organization?.id === org.id)
                ) {
                    stores.organizations.removeOrganizationMember(
                        org.id,
                        userId!,
                    )
                } else {
                    stores.organizations.addOrganizationMember(org.id, userId!)
                }
            },
            [stores, userId, user?.memberships],
        )

        if (!user) return null

        const now = DateTime.local()

        const activeOrganizationIds = useMemo(() => {
            const result = new Set<string>()

            user.memberships?.forEach((membership) => {
                result.add(membership.organization_id)
            })

            return result
        }, [user.memberships])

        const organizations = useOrganizations(stores.organizations)

        return (
            <div style={{ padding: '1rem' }}>
                <BreadCrumbs
                    name={formatUserName(user) ?? user.id}
                    path={[
                        {
                            name: 'Users',
                            to: '/users',
                        },
                    ]}
                />

                <div
                    style={{
                        marginTop: '1rem',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                    }}>
                    <span className="color-dimmed">Organizations</span>
                    <TextButton onClick={orgsModal.open}>
                        Add organization
                    </TextButton>
                </div>

                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Member since</TableHeader>
                            <TableHeader> </TableHeader>
                        </tr>
                    </thead>

                    <tbody>
                        {user.memberships?.map((membership) => {
                            const organization = membership.organization!

                            return (
                                <TableRow key={membership.id}>
                                    <TableLinkCell
                                        to={`/organizations/${organization.id}/members`}>
                                        {organization.name}
                                    </TableLinkCell>

                                    <TableDateCell
                                        date={membership.created_at}
                                        now={now}
                                    />

                                    <td style={{ width: '200px' }}>
                                        <DeleteButton
                                            onDelete={() =>
                                                stores.organizations.removeOrganizationMember(
                                                    organization.id,
                                                    user.id,
                                                )
                                            }
                                        />
                                    </td>
                                </TableRow>
                            )
                        })}
                    </tbody>
                </Table>

                {orgsModal.state && (
                    <SelectableListModal
                        selectableListProps={{
                            items: organizations,
                            selectedIds: activeOrganizationIds,
                            onSelect: onSelect,
                            isLoading:
                                stores.organizations.isFetching,
                            placeholder: 'Search organizations...',
                        }}
                        anchor={orgsModal.state.anchor}
                        onClose={orgsModal.close}
                    />
                )}
            </div>
        )
    },
)
