import { observer } from "mobx-react-lite"
import { MetadataImportStep, MetadataImportStore, titlesByStep } from "./metadata-import-store"
import { MetadataImportStepMapColumns } from "./steps/metadata-import-step-map-columns"
import { MetadataImportStepPreviewDocuments } from "./steps/metadata-import-step-preview-documents"
import { MetadataImportStepPreviewModels } from "./steps/metadata-import-step-preview-models"
import { useMemo } from "react"
import { Stores } from "../../../util/store"
import { progressColor } from "../../../util/style"

export interface MetadataImportContentProps {
    stores: Stores
    metadataImportStore: MetadataImportStore
}

export const MetadataImportContent = observer(({
    stores,
    metadataImportStore,
}: MetadataImportContentProps) => {
    const content = useMemo(() => {
        switch (metadataImportStore.currentStep) {
            case MetadataImportStep.MapColumns:
                return (
                    <MetadataImportStepMapColumns
                        stores={stores}
                        metadataImportStore={metadataImportStore}
                    />
                )

            case MetadataImportStep.PreviewDocuments:
                return (
                    <MetadataImportStepPreviewDocuments
                        metadataImportStore={metadataImportStore}
                    />
                )

            case MetadataImportStep.PreviewModels:
                return (
                    <MetadataImportStepPreviewModels
                        stores={stores}
                        metadataImportStore={metadataImportStore}
                    />
                )
        }
    }, [metadataImportStore.currentStep, stores])

    const headerNotice = useMemo(() => {
        // 1. summary of models to be created
        if (metadataImportStore.currentStep === MetadataImportStep.PreviewModels) {
            const modelOrModels = metadataImportStore.modelsToCreateCount === 1 ? 'model' : 'models'
            return (
                <span style={{ color: 'var(--cyan-color)' }}>
                    {metadataImportStore.modelsToCreateCount} {modelOrModels} will be created
                </span>
            )
        }

        if (metadataImportStore.currentStep === MetadataImportStep.PreviewDocuments) {
            const documentOrDocuments = metadataImportStore.totalDocuments === 1 ? 'document' : 'documents'

            // 2. progress summary while submitting
            if (metadataImportStore.isSubmitting || metadataImportStore.didSubmit) {
                if (metadataImportStore.documentDiffs.length > 0) {
                    const perc = Math.floor(metadataImportStore.doneDocumentsCount / metadataImportStore.documentDiffs.length * 100)
                    return (
                        <span style={{ color: progressColor(perc), marginLeft: '2rem' }}>
                            {perc}% done
                        </span>
                    )
                }
            }

            // 3. summary of documents to be updated
            return (
                <span style={{ color: 'var(--cyan-color)', marginLeft: '2rem' }}>
                    {metadataImportStore.documentDiffs.length} / {metadataImportStore.totalDocuments} {documentOrDocuments} will be changed
                </span>
            )
        }
    }, [
        metadataImportStore.currentStep,
        metadataImportStore.modelsToCreateCount,
        metadataImportStore.totalDocuments,
        metadataImportStore.documentDiffs.length,
        metadataImportStore.isSubmitting,
        metadataImportStore.didSubmit,
        metadataImportStore.doneDocumentsCount,
    ])

    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '0.5rem',
            }}>
                <span style={{
                    color: 'var(--purple-color)',
                    fontSize: '1.2rem',
                }}>
                    {titlesByStep[metadataImportStore.currentStep]}
                </span>

                {headerNotice}
            </div>

            <div style={{
                overflow: 'auto',
                maxHeight: '80vh',
            }}>
                {content}
            </div>
        </div>
    )
})
