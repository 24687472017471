import type { CSSProperties, FC } from 'react'
import { NavLink } from 'react-router-dom'

export interface TableLinkCellProps {
    children: string
    to: string
    className?: string
    textStyle?: CSSProperties
    title?: string
}

export const TableLinkCell: FC<TableLinkCellProps> = ({
    children,
    to,
    className,
    textStyle,
    title,
}) => {
    return (
        <td className="table-cell">
            <NavLink
                className={`text-link ${className}`}
                to={to}
                style={textStyle}
                title={title}>
                {children}
            </NavLink>
        </td>
    )
}
