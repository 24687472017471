import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import type { APIOrganization, APIUser } from '@visorpro/client'
import type { SelectChangeEvent } from '@mui/material';
import { Chip, ListItemText, MenuItem, Select, FormControl, InputLabel, Box, Button } from '@mui/material';
import {
  MaterialReactTable,
  type MRT_ColumnDef
} from 'material-react-table';
import _ from 'lodash'
import { type DateTime } from 'luxon';
import { formatDate } from '../util/format';
import { type SearchableSelectOption } from './searchable-select';
import { useAdminMaterialReactTable, useMultiValueColumnDef } from './table/mui-table';

export interface UsersTableProps {
  users: APIUser[]
  allUsers?: APIUser[]
  organizations?: APIOrganization[]
  setOrganizations?: (userId: string, addedOrganizationIds: string[], removedOrganizationIds: string[]) => void
  isLoading?: boolean
  showProgressBars?: boolean
  isPrimaryBackgroundColor?: boolean
  addMember?: (userId: string) => void
  removeMember?: (userId: string) => void
  removeMembers?: (userIds: string[]) => void
}

const useOrganizationOptions = (organizations: APIOrganization[]): SearchableSelectOption[] => {
  return useMemo(() => organizations.map((org) => ({ label: org.name, value: org.id })), [organizations])
}

export const UsersTable = ({ users, allUsers, organizations, setOrganizations, isLoading, showProgressBars, isPrimaryBackgroundColor, addMember, removeMember, removeMembers }: UsersTableProps) => {
  const [addingMemberId, setAddingMemberId] = useState<string | undefined>(undefined)

  const organizationColumnDef = useMultiValueColumnDef<APIUser, APIOrganization>({
    fieldName: 'Organizations',
    optionsData: organizations,
    renderChip: (organization, index) => (
      <Link className='text-link' to={`/organizations/${organization.id}/members`} key={index}>
        <Chip label={organization.name} size="small" clickable />
      </Link>
    ),
    useOptions: useOrganizationOptions,
    onEdit: setOrganizations,
  })

  const columns = useMemo<MRT_ColumnDef<APIUser>[]>(() => [
    {
      accessorKey: 'id',
      header: 'ID',
      enableClickToCopy: true,
      Cell: ({ cell }) => {
        if (cell.row.index < 0) {
          return addingMemberId
        }
        const userId = cell.getValue<string>()
        return <>{userId}</>
      }
    },
    {
      accessorFn: (user) => [user.first_name, user.last_name].join(' '),
      header: 'Name',
      enableEditing: (row) => row.index < 0 && addMember !== undefined && allUsers !== undefined,
      Edit: () => {
        const filteredUsers = _.differenceWith(allUsers, users, (user, row) => user.id === row.id)
        const onChange = (event: SelectChangeEvent<string>) => {
          const userId = event.target.value
          setAddingMemberId(userId)
        }
        return (
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="models">User</InputLabel>
            <Select
              fullWidth
              required
              label='User'
              size='small'
              variant='standard'
              onChange={onChange}
            >
              {filteredUsers?.map((user, index) => {
                const credential = user.email || user.phone_number || user.id
                return (
                  <MenuItem key={index} value={user.id}>
                    <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={credential} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )
      },
    },
    {
      accessorFn: (user) => (user.email || user.phone_number),
      header: 'Credential',
      enableClickToCopy: (cell) => cell.row.index >= 0,
    },
    {
      accessorFn: (user) => user.memberships?.map((membership) => membership.organization!) ?? [],
      id: 'organizations',
      header: 'Organizations',
      visibleInShowHideMenu: organizations !== undefined,
      filterFn: (row, _columnId, filterValues: string[]) => row.original.memberships?.some((membership) => filterValues.some((filterValue) => membership.organization_id === filterValue)) ?? false,
      ...organizationColumnDef,
    },
    {
      accessorKey: 'auth0_sub',
      header: 'Auth0 Sub',
      enableClickToCopy: true,
    },
    {
      accessorFn: (user) => user.created_at,
      id: 'created_at',
      header: 'Created At',
      Cell: ({ cell }) => formatDate(cell.getValue<DateTime>()),
      filterVariant: 'datetime-range',
    }
  ], [organizations, organizationColumnDef, addingMemberId, addMember, allUsers, users]);

  const table = useAdminMaterialReactTable({
    columns,
    data: users,
    initialState: {
      columnVisibility: {
        id: false,
        auth0_sub: false,
        created_at: false,
        organizations: organizations !== undefined,
      },
    },
    defaultColumn: {
      enableColumnFilter: true,
      enableSorting: true,
    },
    getRowId: (user) => user.id,
    enableSorting: true,
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().flatRows

      const onAddMember = () => table.setCreatingRow(true)
      const onRemoveMembers = () => {
        const removingUserIds = selectedRows.map((row) => row.original.id)
        removeMembers?.(removingUserIds)
      }

      return (
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          {addMember && allUsers && <Button onClick={onAddMember}>Add member</Button>}
          {removeMember && <Button disabled={selectedRows.length < 1} onClick={onRemoveMembers}>
            {selectedRows.length > 1 ? `Remove ${selectedRows.length} members` : 'Remove member'}
          </Button>}
        </Box>
      )
    },
    createDisplayMode: 'row',
    onCreatingRowSave: ({ exitCreatingMode }) => {
      if (addingMemberId) {
        addMember?.(addingMemberId)
        exitCreatingMode()
      }
    },
    state: {
      isLoading,
      showProgressBars,
    },
    isPrimaryBackgroundColor,
  }, 'users-table');

  return <MaterialReactTable table={table} />;
}
