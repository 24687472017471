import type { APITestRunQuestion } from '@visorpro/client'
import type { FC } from 'react'
import { useMemo } from 'react'
import { MdClose } from 'react-icons/md'
import { progressColor } from '../../../util/style'
import { useTestRunQuestionScore } from '../../../util/test-run'
import { TestRunQuestionAcceptanceCriteria } from './test-run-question-acceptance-criteria'

export interface TestRunQuestionProps {
    item: APITestRunQuestion
    onClose(): void
}

export const TestRunQuestion: FC<TestRunQuestionProps> = ({
    item,
    onClose,
}) => {
    const score = useTestRunQuestionScore(item)

    const answer = useMemo(() => {
        if (!item.answer) {
            return null
        }

        try {
            const answerJson = JSON.parse(item.answer)

            const questionAnalysis = answerJson.question_analysis
            const documentAnalysis = answerJson.document_analysis
            const finalAnswer = answerJson.final_answer

            if (!questionAnalysis || !documentAnalysis || !finalAnswer) {
                return item.answer
            }

            return `Question Analysis:
${answerJson.question_analysis}

Document Analysis:
${answerJson.document_analysis}

Answer:
${answerJson.final_answer}
  `
        } catch (e) {
            return item.answer
        }
    }, [item.answer])

    return (
        <div>
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <span style={{ color: 'var(--comment-color)' }}>
                        Question:
                    </span>

                    <MdClose
                        size={16}
                        color="var(--red-color)"
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={onClose}
                    />
                </div>

                <p
                    style={{
                        color: 'var(--foreground-color)',
                        marginTop: '0.75rem',
                    }}>
                    {item.text}
                </p>
            </div>

            <div>
                <p
                    style={{
                        color: 'var(--comment-color)',
                        marginTop: '1.5rem',
                    }}>
                    A correct answer should...
                </p>

                <div
                    style={{
                        marginTop: '0.75rem',
                        gap: '0.5rem',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    {item.acceptance_criteria.map((item) => (
                        <TestRunQuestionAcceptanceCriteria
                            key={item.id}
                            item={item}
                        />
                    ))}
                </div>

                <p
                    style={{
                        marginTop: '0.75rem',
                        color: 'var(--comment-color)',
                    }}>
                    Result:{' '}
                    <span
                        style={{
                            color: progressColor(
                                score.percentage,
                                score.isComplete,
                            ),
                        }}>
                        {score.percentage}%
                    </span>
                </p>
            </div>

            {answer && (
                <div>
                    <p
                        style={{
                            color: 'var(--comment-color)',
                            marginTop: '1.5rem',
                        }}>
                        Answer:
                    </p>

                    <p
                        style={{
                            color: 'var(--foreground-color)',
                            marginTop: '0.75rem',
                            whiteSpace: 'pre-wrap',
                        }}>
                        {answer}
                    </p>
                </div>
            )}
        </div>
    )
}
