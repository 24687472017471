import { useEffect } from 'react'

export const useAutoResize = (
    textareaRef: React.RefObject<HTMLTextAreaElement>,
    maxHeightPx: number,
) => {
    useEffect(() => {
        const textarea = textareaRef.current!

        const handleResize = () => {
            textarea.style.height = 'auto'
            const newHeight = Math.min(textarea.scrollHeight, maxHeightPx)
            textarea.style.height = `${newHeight}px`
        }

        textarea.addEventListener('input', handleResize)

        handleResize()

        // clean up
        return () => textarea.removeEventListener('input', handleResize)
    }, [textareaRef, maxHeightPx])
}
