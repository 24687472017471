import { useEffect, useState } from "react"
import { MetadataImportStore } from "./metadata-import-store"
import { MetadataImportContent } from "./metadata-import-content"
import { MetadataImportFooter } from "./metadata-import-footer"
import { Stores } from "../../../util/store"

export interface MetadataImportModalProps {
    dataSetId: string
    stores: Stores
    file: File
    onClose(): void
}

export const MetadataImportModal = (({
    dataSetId,
    stores,
    file,
    onClose,
}: MetadataImportModalProps) => {
    const [metadataImportStore] = useState(() => new MetadataImportStore({
        dataSetId,
        onClose,
    }))

    useEffect(() => {
        metadataImportStore.setFile(file)
    }, [file])

    return (
        <div style={{
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div style={{
                backgroundColor: 'var(--background-dark-color)',
                padding: '1rem',
                borderRadius: '0.5rem',
                minWidth: '600px',
                maxWidth: '1400px',
                maxHeight: '95vh',
            }}>
                <MetadataImportContent
                    stores={stores}
                    metadataImportStore={metadataImportStore}
                />

                <MetadataImportFooter
                    metadataImportStore={metadataImportStore}
                />
            </div>
        </div >
    )
})
