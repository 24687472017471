import type {
    APICreatePromptRequest,
    APIUpdatePromptRequest,
} from '@visorpro/client/dist/RestClient/services/PromptService'
import type { APIPrompt } from '@visorpro/client/dist/types/APIPrompt'
import { makeAutoObservable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { observed } from '../util/observed-decorator'

export class PromptsStore {
    public promptsById: Record<string, APIPrompt> = {}
    public promptIds: string[] = []
    public isFetched = false
    public isFetching = false
    public isUpdating = false

    constructor() {
        makeAutoObservable(this)
    }

    @observed('isFetching')
    public async list() {
        try {
            const prompts = await visorPRORestClient.prompt.getPrompts()

            const promptsById: Record<string, APIPrompt> = {}
            const promptIds: string[] = []

            prompts.items.forEach((prompt) => {
                promptsById[prompt.id] = prompt
                promptIds.push(prompt.id)
            })

            runInAction(() => {
                this.promptsById = promptsById
                this.promptIds = promptIds
                this.isFetched = true
            })
        } catch (e) {
            toast.error(`Failed to create prompt: ${(e as Error).message}`)
        }
    }

    @observed('isUpdating')
    public async create(input: APICreatePromptRequest) {
        if (!this.validate(input)) {
            return
        }

        try {
            const prompt = await visorPRORestClient.prompt.createPrompt(input)

            runInAction(() => {
                this.promptsById = {
                    ...this.promptsById,
                    [prompt.id]: prompt,
                }
                this.promptIds = [prompt.id, ...this.promptIds]
            })

            return prompt.id
        } catch (e) {
            toast.error(`Failed to create prompt: ${(e as Error).message}`)
        }
    }

    @observed('isUpdating')
    public async update(id: string, input: APIUpdatePromptRequest) {
        if (!this.validate(input)) {
            return
        }

        try {
            const prompt = await visorPRORestClient.prompt.updatePrompt(
                id,
                input,
            )

            runInAction(() => {
                this.promptsById = {
                    ...this.promptsById,
                    [prompt.id]: prompt,
                }
            })

            return prompt.id
        } catch (e) {
            toast.error(`Failed to update prompt: ${(e as Error).message}`)
        }
    }

    @observed('isUpdating')
    public async delete(promptId: string) {
        try {
            await visorPRORestClient.prompt.deletePrompt(promptId)

            runInAction(() => {
                const copy = { ...this.promptsById }
                delete copy[promptId]
                this.promptsById = copy
                this.promptIds = this.promptIds.filter((id) => id !== promptId)
            })
        } catch (e) {
            toast.error(
                `Failed to delete test question: ${(e as Error).message}`,
            )
        }
    }

    private validate = (
        input: APICreatePromptRequest | APIUpdatePromptRequest,
    ) => {
        if (input.name.length === 0) {
            toast.error('Prompt name is required')
            return false
        }

        if (input.messages.length === 0) {
            toast.error('Prompt messages are required')
            return false
        }

        return true
    }
}

export const usePrompts = (stores: Stores) => {
    useEffect(() => {
        if (!stores.prompts.isFetched) {
            void stores.prompts.list()
        }
    }, [stores.prompts])

    return useMemo(() => {
        const ids = stores.prompts.promptIds
        return ids.map((promptId) => stores.prompts.promptsById[promptId])
    }, [stores.prompts.promptIds, stores.prompts.promptsById])
}
