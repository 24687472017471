import { type PaginatedRequestQueryParams } from "@visorpro/client"
import { WorkOrdersTable } from "../../components/work-orders-table"
import { type Stores } from "../../util/store"
import { observer } from "mobx-react-lite"

export interface WorkOrdersPageProps {
    stores: Stores
}

const WorkOrdersPage = observer(({ stores }: WorkOrdersPageProps) => {
    const setPagination = async (pagination: PaginatedRequestQueryParams) => {
        await stores.workOrders.list(pagination)
    }

    return (
        <WorkOrdersTable
            workOrders={stores.workOrders.workOrders}
            rowCount={stores.workOrders.totalWorkOrders}
            pagination={stores.workOrders.pagination}
            setPagination={setPagination}
            isPrimaryBackgroundColor
        />
    )
})

export default WorkOrdersPage