import { observer } from 'mobx-react-lite'
import type { CSSProperties, FC } from 'react'
import React, { useCallback, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import type { Stores } from '../../util/store'
import { FormTextInput } from '../modals/creation/form-text-input'
import { TextButton } from '../text-button'
import { MessageList } from './message-list'
import { PromptFormStore } from './prompt-form-store'

export interface PromptModalProps {
    promptId?: string
    stores: Stores
    onClose(): void
    style?: CSSProperties
}

export const PromptForm: FC<PromptModalProps> = ({
    promptId,
    stores,
    onClose,
    style,
}) => {
    const [formStore] = useState(() => {
        if (promptId) {
            const prompt = stores.prompts.promptsById[promptId]
            return new PromptFormStore(prompt)
        }

        return new PromptFormStore()
    })

    const submit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault()

            const didCreateOrUpdate = await formStore.createOrUpdate(stores)

            if (didCreateOrUpdate) {
                onClose()
            }
        },
        [formStore, stores, onClose],
    )

    const onAdd = useCallback(() => {
        formStore.promptMessagesStore.addEmptyItem()
    }, [formStore])

    return (
        <form
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'var(--background-dark-color)',
                ...style,
            }}
            onSubmit={submit}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '1rem 1rem 0',
                }}>
                <TextButton
                    onClick={submit}
                    disabled={formStore.isRunning(stores)}
                    disabledContent={promptId ? 'Updating...' : 'Creating...'}>
                    {promptId ? 'Update prompt' : 'Create prompt'}
                </TextButton>

                <TextButton
                    kind="danger"
                    onClick={onClose}
                    disabled={formStore.isRunning(stores)}
                    disabledContent={promptId ? 'Updating...' : 'Creating...'}
                    style={{ marginLeft: '1rem' }}>
                    Close
                </TextButton>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '1rem 1rem 0',
                }}>
                <span className="color-dimmed">Name</span>
            </div>

            <PromptNameTextInput
                store={formStore}
                style={{
                    marginTop: '0.5rem',
                    padding: '0 1rem',
                }}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '1rem 1rem 0',
                }}>
                <div>
                    <p className="color-dimmed" style={{}}>
                        Messages
                    </p>
                    <p
                        className="color-dimmed"
                        style={{
                            fontStyle: 'italic',
                            marginTop: '0.5rem',
                            fontSize: '0.8rem',
                        }}>
                        {
                            "{{SECTIONS}} will be replaced with the context sections, and {{QUESTION}} will be replaced with the user's question"
                        }
                    </p>
                </div>
                <MdAdd
                    size={16}
                    color="var(--green-color)"
                    style={{
                        flexShrink: 0,
                        cursor: 'pointer',
                    }}
                    onClick={onAdd}
                />
            </div>

            <MessageList
                promptFormStore={formStore}
                style={{
                    marginTop: '0.5rem',
                    padding: '0 1rem 2.5rem',
                    overflowY: 'auto',
                }}
            />
        </form>
    )
}

interface PromptTextInputProps {
    store: PromptFormStore
    style?: React.CSSProperties
}

// small wrapper to avoid re-rendering the entire modal when the text changes
const PromptNameTextInput: FC<PromptTextInputProps> = observer(
    ({ store, style }) => {
        return (
            <FormTextInput
                value={store.name}
                onChange={store.setName}
                placeholder=""
                style={style}
                autoFocus={true}
            />
        )
    },
)
