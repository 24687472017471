import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { UsersTable } from '../../../../components/users-table'
import { useOrganizationMembers } from '../../../../stores/organization-members-store'
import type { Stores } from '../../../../util/store'
import { useCallback } from 'react'
import { useUsers } from '../../../../stores/user-store'

export interface OrganizationMembersPageProps {
    stores: Stores
}

export const OrganizationMembersPage = observer(
    ({ stores }: OrganizationMembersPageProps) => {
        const { organizationId } = useParams()
        const allUsers = useUsers(stores.users)
        const members = useOrganizationMembers(
            stores.organizationMembers,
            organizationId!,
        )

        const addMember = useCallback(async (userId: string) => {
            await stores.organizationMembers.addOrganizationMembership(userId, organizationId!)
        }, [stores.organizationMembers, organizationId])

        const removeMember = useCallback(async (userId: string) => {
            await stores.organizationMembers.removeOrganizationMembership(userId, organizationId!)
        }, [stores.organizationMembers, organizationId])

        const removeMembers = useCallback(async (userIds: string[]) => {
            await stores.organizationMembers.removeOrganizationMemberships(userIds, organizationId!)
        }, [stores.organizationMembers, organizationId])

        return <UsersTable
            users={members}
            allUsers={allUsers}
            addMember={addMember}
            removeMember={removeMember}
            removeMembers={removeMembers}
            isLoading={stores.organizationMembers.isFetching}
            showProgressBars={stores.organizationMembers.isUpdating}
        />
    },
)
