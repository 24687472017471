import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { useModal } from '../../components/modal'
import { CreateOrganizationModal } from '../../components/modals'
import { Table } from '../../components/table/table'
import { TableDateCell } from '../../components/table/table-date-cell'
import { TableHeader } from '../../components/table/table-header'
import { TableLinkCell } from '../../components/table/table-link-cell'
import { TableRow } from '../../components/table/table-row'
import { TextButton } from '../../components/text-button'
import type {
    APICreateOrganizationRequest,
    OrganizationStore,
} from '../../stores/organization-store'
import { logBackendError } from '../../util/api'
import './index.css'

export interface OrganizationsPageProps {
    store: OrganizationStore
}

export const OrganizationsPage = observer(
    ({ store }: OrganizationsPageProps) => {
        useEffect(() => {
            if (!store.organizationIds.length) {
                store.list()
            }
        }, [store, store.organizationIds.length])

        const now = DateTime.local()
        const createOrgModal = useModal()

        const createOrganization = useCallback(
            async (input: APICreateOrganizationRequest) => {
                try {
                    await store.create(input)
                    createOrgModal.close()
                } catch (e) {
                    logBackendError(
                        e,
                        `There was an error while creating '${input.name}'`,
                    )
                }
            },
            [store, createOrgModal],
        )

        return (
            <div className="organizations">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                    }}>
                    <BreadCrumbs name={'Organizations'} path={[]} />

                    <TextButton onClick={createOrgModal.open}>
                        Create
                    </TextButton>
                </div>

                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Created</TableHeader>
                        </tr>
                    </thead>

                    <tbody>
                        {store.organizationIds.map((organizationId) => {
                            const organization =
                                store.organizationsById[organizationId]
                            return (
                                <TableRow key={organizationId}>
                                    <TableLinkCell
                                        to={`/organizations/${organization.id}/members`}>
                                        {organization.name}
                                    </TableLinkCell>
                                    <TableDateCell
                                        date={organization.created_at}
                                        now={now}
                                    />
                                </TableRow>
                            )
                        })}
                    </tbody>
                </Table>

                {createOrgModal.state && (
                    <CreateOrganizationModal
                        anchor={createOrgModal.state.anchor}
                        store={store}
                        onClose={createOrgModal.close}
                        onCreate={createOrganization}
                    />
                )}
            </div>
        )
    },
)
