import type { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { PageTitle } from '../page-title'
import './index.css'

export interface PathItem {
    name: string
    to: string
}

export interface BreadCrumbsProps {
    name: string
    path: PathItem[]
}

export const BreadCrumbs: FC<BreadCrumbsProps> = ({ name, path }) => {
    return (
        <div className="breadcrumbs">
            {path.map((segment) => {
                return (
                    <span key={`${segment.name}-${segment.to}`}>
                        <NavLink to={segment.to} className="path-item">
                            {segment.name}
                        </NavLink>
                        <span className="path-separator">/</span>
                    </span>
                )
            })}

            <PageTitle>{name}</PageTitle>
        </div>
    )
}
