import type { FC } from 'react'
import { useCallback } from 'react'
import { type APIDataSet } from '@visorpro/client'

import type { ActionMenuItem } from '../actions-menu'
import { ActionsMenu } from '../actions-menu'

export enum DataSetActionKind {
    EditName = 'edit-name',
    ManageOrganizations = 'manage-organizations',
    StartReview = 'start-review',
}

interface DataSetActionsModalProps {
    anchor: HTMLElement
    dataSet: APIDataSet
    onClose(): void
    onAction(dataSet: APIDataSet, kind: DataSetActionKind): void
}

export const DataSetActionsModal: FC<DataSetActionsModalProps> = ({
    anchor,
    dataSet,
    onClose,
    onAction,
}) => {
    const _onAction = useCallback(
        (item: ActionMenuItem) => {
            onAction(dataSet, item.id as DataSetActionKind)
        },
        [dataSet, onAction],
    )

    return (
        <ActionsMenu
            anchor={anchor}
            items={[
                {
                    id: DataSetActionKind.EditName,
                    name: 'Edit name',
                },
                {
                    id: DataSetActionKind.ManageOrganizations,
                    name: 'Manage organizations',
                },
                {
                    id: DataSetActionKind.StartReview,
                    name: 'Start review',
                },
            ]}
            onAction={_onAction}
            onClose={onClose}
        />
    )
}
