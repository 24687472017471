import { makeAutoObservable, observable, runInAction } from 'mobx'
import { useEffect } from 'react'
import type { APIUser, APIUserRaw } from '@visorpro/client'

import { visorPRORestClient } from '../util/api'
import { observed } from '../util/observed-decorator'
import { DictionaryArray } from '../types'

export interface APIGetUsersResponse {
    total: number
    items: APIUserRaw[]
}

export class UserStore {
    public usersById: Record<string, APIUser> = observable({})
    public isFetched = false
    public isFetching = false
    public isUpdating = false

    constructor() {
        makeAutoObservable(this)
    }

    @observed('isFetching')
    public async list() {
        const response = await visorPRORestClient.user.getAll()
        const users = response.items
        const usersById: Record<string, APIUser> = {}
        users.forEach((user) => {
            usersById[user.id] = user
        })

        runInAction(() => {
            this.usersById = usersById
            this.isFetched = true
        })
    }

    @observed('isUpdating')
    public async addOrganizationMemberships(
        userId: string,
        organizationIds: string[],
    ) {
        const memberships = await Promise.all(
            organizationIds.map((organizationId) =>
                visorPRORestClient.organization.createMembership(
                    organizationId,
                    userId,
                ),
            ),
        )

        runInAction(() => {
            this.usersById[userId] = {
                ...this.usersById[userId],
                memberships: [
                    ...this.usersById[userId].memberships ?? [],
                    ...memberships,
                ],
            }
        })
    }

    @observed('isUpdating')
    public async removeOrganizationMemberships(
        userId: string,
        organizationIds: string[],
    ) {
        await Promise.all(
            organizationIds.map((organizationId) =>
                visorPRORestClient.organization.deleteMembership(
                    organizationId,
                    userId,
                ),
            ),
        )

        runInAction(() => {
            this.usersById[userId] = {
                ...this.usersById[userId],
                memberships: this.usersById[userId].memberships?.filter(
                    (membership) =>
                        !organizationIds.includes(membership.organization!.id),
                ),
            }
        })
    }
}

export const useUserById = (store: UserStore, userId: string) => {
    useEffect(() => {
        // fixme: we shouldn't have to fetch every single user for this
        if (!store.isFetched) {
            void store.list()
        }
    }, [store])

    return store.usersById[userId]
}

export const useUsers = (store: UserStore) => {
    useEffect(() => {
        if (!store.isFetched) {
            void store.list()
        }
    }, [store, store.isFetched])

    return new DictionaryArray(store.usersById)
}
