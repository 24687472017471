import { CategoriesStore } from '../stores/categories-store'
import { DataSetStore } from '../stores/data-sets-store'
import { DocumentUploadStore } from '../stores/document-upload-store'
import { DocumentsStore } from '../stores/documents-store'
import { IssuesStore } from '../stores/issues-store'
import { ManufacturerStore } from '../stores/manufacturers-store'
import { ModelsStore } from '../stores/models-store'
import { OrganizationDataSetsStore } from '../stores/organization-data-sets-store'
import { OrganizationDomainsStore } from '../stores/organization-domains-store'
import { OrganizationInvitationCodesStore } from '../stores/organization-invitation-codes-store'
import { OrganizationMemberStore } from '../stores/organization-members-store'
import { OrganizationStore } from '../stores/organization-store'
import { PromptsStore } from '../stores/prompts-store'
import { QueueTasksStore } from '../stores/queue-tasks-store'
import { SectionPreviewStore } from '../stores/section-preview-store'
import { TestQuestionsStore } from '../stores/test-questions-store'
import { TestRunsStore } from '../stores/test-runs-store'
import { UserStore } from '../stores/user-store'
import { WorkOrdersStore } from '../stores/work-orders-store'

export class Stores {
    // remember to create an instance below when adding a new store
    public documentUploads: DocumentUploadStore
    public documents: DocumentsStore
    public workOrders: WorkOrdersStore
    public organizations: OrganizationStore
    public organizationMembers: OrganizationMemberStore // fixme: plural
    public organizationDataSets: OrganizationDataSetsStore
    public users: UserStore
    public organizationCodes: OrganizationInvitationCodesStore
    public organizationDomains: OrganizationDomainsStore
    public dataSets: DataSetStore
    public manufacturers: ManufacturerStore
    public categories: CategoriesStore
    public models: ModelsStore
    public issues: IssuesStore
    public sectionPreview: SectionPreviewStore
    public testQuestions: TestQuestionsStore
    public testRuns: TestRunsStore
    public prompts: PromptsStore
    public queueTasks: QueueTasksStore

    constructor() {
        this.documentUploads = new DocumentUploadStore(this)
        this.documents = new DocumentsStore(this)
        this.workOrders = new WorkOrdersStore()
        this.organizations = new OrganizationStore(this)
        this.organizationMembers = new OrganizationMemberStore()
        this.organizationDataSets = new OrganizationDataSetsStore(this)
        this.users = new UserStore()
        this.organizationCodes = new OrganizationInvitationCodesStore()
        this.organizationDomains = new OrganizationDomainsStore()
        this.dataSets = new DataSetStore(this)
        this.manufacturers = new ManufacturerStore(this)
        this.categories = new CategoriesStore()
        this.models = new ModelsStore(this)
        this.issues = new IssuesStore(this)
        this.sectionPreview = new SectionPreviewStore()
        this.testQuestions = new TestQuestionsStore()
        this.testRuns = new TestRunsStore()
        this.prompts = new PromptsStore()
        this.queueTasks = new QueueTasksStore()
    }
}
