import { CSSProperties, FC, useMemo } from "react"
import { progressColor } from "../../../../util/style"
import './model-selection-row.css'

export interface MetadataDiffRowAlternative {
    modelId?: string
    originalName: string
    similarityPercentage: number
    manufacturerName: string
    categoryName: string
    modelName: string
    isSelected: boolean
    isIgnored: boolean
    selectedColor: string
}

export interface MetadataDiffRowProps {
    alternatives: MetadataDiffRowAlternative[]
    onSelect?(originalName: string, alternativeModelId?: string): void
    onIgnore?(originalName: string): void
}

export const ModelSelectionRow: FC<MetadataDiffRowProps> = ({
    alternatives,
    onSelect,
    onIgnore,
}: MetadataDiffRowProps) => {
    return (
        <>
            {
                alternatives.map((alternative, index) => (
                    <ModelSelectionRowAlternative
                        key={index}
                        index={index}
                        alternative={alternative}
                        onSelect={onSelect}
                        onIgnore={onIgnore}
                        selectedColor={alternative.selectedColor}
                    />
                ))
            }
        </>
    )
}

export interface MetadataDiffRowAlternativeProps {
    index: number
    alternative: MetadataDiffRowAlternative
    onSelect?(originalName: string, alternativeModelId?: string): void
    onIgnore?(originalName: string): void
    selectedColor: string
}

export const ModelSelectionRowAlternative: FC<MetadataDiffRowAlternativeProps> = ({
    index,
    alternative,
    onSelect,
    onIgnore,
    selectedColor,
}) => {
    const textColor = alternative.isSelected
        ? selectedColor
        : 'var(--foreground-color)'
    const borderTopStyle: CSSProperties = index === 0
        ? { borderTop: '1px solid var(--background-color)', marginTop: '0.5rem' }
        : {}

    const selectedButton = useMemo(() => {
        const verb = alternative.similarityPercentage === 0
            ? 'Create'
            : 'Reuse'

        if (alternative.isSelected) {
            return (
                <span
                    className='model-selection-cell'
                    style={{
                        color: selectedColor,
                        ...borderTopStyle,
                    }}>
                    {verb}
                </span>
            )
        }

        return (
            <span
                className='model-selection-cell'
                style={{ color: 'var(--comment-color)', ...borderTopStyle }}
                onClick={() => {
                    onSelect?.(alternative.originalName, alternative.modelId)
                }}>
                {verb}
            </span>
        )
    }, [alternative.isSelected, index, onSelect])

    const ignoredButton = useMemo(() => {
        if (index > 0) {
            return (
                <span
                    className='model-selection-cell'
                    style={{ color: textColor, ...borderTopStyle }}
                />
            )
        }

        if (alternative.isIgnored) {
            return (
                <span
                    className='model-selection-cell'
                    style={{ color: 'var(--red-color)', ...borderTopStyle }}
                    onClick={() => onIgnore?.(alternative.originalName)}>
                    Ignored
                </span>
            )
        }

        return (
            <span
                className='model-selection-cell'
                style={{ color: 'var(--comment-color)', ...borderTopStyle }}
                onClick={() => onIgnore?.(alternative.originalName)}>
                Ignore
            </span>
        )
    }, [alternative.isIgnored, index, onIgnore])

    return (
        <div
            className='model-selection-row'
            style={{
                display: 'contents', // make this div invisible to the grid layout
            }}>
            <span
                className='model-selection-cell'
                style={{
                    color: progressColor(alternative.similarityPercentage),
                    ...borderTopStyle,
                }}>
                {alternative.similarityPercentage === 0 ? '' : alternative.similarityPercentage + '%'}
            </span>

            <span
                className='model-selection-cell'
                style={{ color: textColor, textWrap: 'wrap', ...borderTopStyle }}>
                {alternative.manufacturerName}
            </span>

            <span
                className='model-selection-cell'
                style={{ color: textColor, textWrap: 'wrap', ...borderTopStyle }}>
                {alternative.categoryName}
            </span>

            <span
                className='model-selection-cell'
                style={{ color: textColor, textWrap: 'wrap', ...borderTopStyle }}>
                {alternative.modelName}
            </span>

            {selectedButton}

            {ignoredButton}
        </div>
    )
}
