import type { PaletteOptions, Shadows } from '@mui/material/styles'
import { createTheme, alpha, darken } from '@mui/material/styles'

declare module '@mui/material/Paper' {
    interface PaperPropsVariantOverrides {
        highlighted: true
    }
}

declare module '@mui/material/styles/createPalette' {
    interface ColorRange {
        50: string
        100: string
        200: string
        300: string
        400: string
        500: string
        600: string
        700: string
        800: string
        900: string
    }

    interface PaletteColor extends ColorRange {}

    interface Palette {
        baseShadow: string
    }
}

const defaultTheme = createTheme()

export const brand = {
    50: 'hsl(265, 90%, 97%)',
    100: 'hsl(265, 90%, 94%)',
    200: 'hsl(265, 90%, 88%)',
    300: 'hsl(265, 90%, 80%)',
    400: 'hsl(265, 90%, 75%)',
    500: 'hsl(265, 90%, 52%)',
    600: 'hsl(265, 90%, 45%)',
    700: 'hsl(265, 90%, 35%)',
    800: 'hsl(265, 90%, 26%)',
    900: 'hsl(265, 90%, 13%)',
}

export const gray = {
    50: 'hsl(220, 35%, 97%)',
    100: 'hsl(220, 30%, 94%)',
    200: 'hsl(220, 20%, 88%)',
    300: 'hsl(220, 20%, 80%)',
    400: 'hsl(220, 20%, 65%)',
    500: 'hsl(220, 20%, 42%)',
    600: 'hsl(220, 20%, 35%)',
    700: 'hsl(220, 20%, 25%)',
    800: 'hsl(220, 30%, 16%)',
    900: 'hsl(220, 35%, 10%)',
}

export const green = {
    50: 'hsl(120, 80%, 98%)',
    100: 'hsl(120, 75%, 94%)',
    200: 'hsl(120, 75%, 87%)',
    300: 'hsl(120, 61%, 77%)',
    400: 'hsl(120, 44%, 53%)',
    500: 'hsl(120, 59%, 30%)',
    600: 'hsl(120, 70%, 25%)',
    700: 'hsl(120, 75%, 16%)',
    800: 'hsl(120, 84%, 10%)',
    900: 'hsl(120, 87%, 6%)',
}

export const orange = {
    50: 'hsl(45, 100%, 97%)',
    100: 'hsl(45, 92%, 90%)',
    200: 'hsl(45, 94%, 80%)',
    300: 'hsl(45, 90%, 65%)',
    400: 'hsl(45, 90%, 40%)',
    500: 'hsl(45, 90%, 35%)',
    600: 'hsl(45, 91%, 25%)',
    700: 'hsl(45, 94%, 20%)',
    800: 'hsl(45, 95%, 16%)',
    900: 'hsl(45, 93%, 12%)',
}

export const red = {
    50: 'hsl(0, 100%, 97%)',
    100: 'hsl(0, 92%, 90%)',
    200: 'hsl(0, 94%, 80%)',
    300: 'hsl(0, 90%, 65%)',
    400: 'hsl(0, 90%, 40%)',
    500: 'hsl(0, 90%, 30%)',
    600: 'hsl(0, 91%, 25%)',
    700: 'hsl(0, 94%, 18%)',
    800: 'hsl(0, 95%, 12%)',
    900: 'hsl(0, 93%, 6%)',
}

export const palette: PaletteOptions = {
    mode: 'dark',
    primary: {
        contrastText: brand[50],
        light: brand[300],
        main: brand[400],
        dark: brand[700],
    },
    info: {
        contrastText: brand[300],
        light: brand[400],
        main: brand[600],
        dark: brand[800],
    },
    warning: {
        light: orange[300],
        main: orange[400],
        dark: orange[600],
    },
    error: {
        light: red[300],
        main: red[400],
        dark: red[600],
    },
    success: {
        light: green[300],
        main: green[400],
        dark: green[600],
    },
    grey: {
        ...gray,
    },
    divider: alpha(gray[500], 0.8),
    background: {
        default: '#282a36',
        paper: gray[900],
    },
    text: {
        primary: '#f8f8f2',
        secondary: darken('#f8f8f2', 0.2),
    },
    action: {
        hover: alpha(gray[600], 0.2),
        selected: alpha(gray[600], 0.3),
    },
}

export const typography = {
    fontFamily: 'Inter, sans-serif',
    h1: {
        fontSize: defaultTheme.typography.pxToRem(48),
        fontWeight: 600,
        lineHeight: 1.2,
        letterSpacing: -0.5,
    },
    h2: {
        fontSize: defaultTheme.typography.pxToRem(36),
        fontWeight: 600,
        lineHeight: 1.2,
    },
    h3: {
        fontSize: defaultTheme.typography.pxToRem(30),
        lineHeight: 1.2,
    },
    h4: {
        fontSize: defaultTheme.typography.pxToRem(24),
        fontWeight: 600,
        lineHeight: 1.5,
    },
    h5: {
        fontSize: defaultTheme.typography.pxToRem(20),
        fontWeight: 600,
    },
    h6: {
        fontSize: defaultTheme.typography.pxToRem(18),
        fontWeight: 600,
    },
    subtitle1: {
        fontSize: defaultTheme.typography.pxToRem(18),
    },
    subtitle2: {
        fontSize: defaultTheme.typography.pxToRem(14),
        fontWeight: 500,
    },
    body1: {
        fontSize: defaultTheme.typography.pxToRem(14),
    },
    body2: {
        fontSize: defaultTheme.typography.pxToRem(14),
        fontWeight: 400,
    },
    caption: {
        fontSize: defaultTheme.typography.pxToRem(12),
        fontWeight: 400,
    },
}

export const shape = {
    borderRadius: 8,
}

export const shadows: Shadows = [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
]
