import {
    FloatingPortal,
    useFloating,
    useClick,
    useDismiss,
    useRole,
    useInteractions,
    FloatingFocusManager,
    FloatingOverlay,
    offset,
    flip,
    shift,
    autoUpdate,
} from '@floating-ui/react'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import './actions-menu.css'

export interface ActionMenuItem {
    id: string
    name: string
    shouldConfirm?: boolean
}

export interface ActionsMenuProps {
    anchor: HTMLElement
    items: ActionMenuItem[]
    onClose(): void
    onAction(item: ActionMenuItem): void
}

const overlayStyle = {
    backgroundColor: 'rgba(0,0,0,0.1)',
}

export const ActionsMenu = ({
    anchor,
    items,
    onClose,
    onAction,
}: ActionsMenuProps) => {
    const { refs, floatingStyles, context } = useFloating({
        elements: {
            reference: anchor,
        },
        strategy: 'absolute',
        placement: 'bottom-end',
        open: true,
        onOpenChange: (open) => {
            if (!open) onClose()
        },
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)

    const { getFloatingProps } = useInteractions([click, dismiss, role])

    return (
        <FloatingPortal>
            <FloatingOverlay style={overlayStyle} lockScroll>
                <FloatingFocusManager context={context} modal={true}>
                    <div
                        style={{
                            ...floatingStyles,
                            backgroundColor: 'var(--background-dark-color)',
                            padding: '1rem',
                            borderRadius: '6px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                        }}
                        onClick={(e) => e.stopPropagation()}
                        ref={refs.setFloating}
                        {...getFloatingProps()}>
                        {items.map((item) => (
                            <ActionsMenuItem
                                key={item.id}
                                item={item}
                                onAction={onAction}
                            />
                        ))}
                    </div>
                </FloatingFocusManager>
            </FloatingOverlay>
        </FloatingPortal>
    )
}

interface ActionMenuItemProps {
    item: ActionMenuItem
    onAction(item: ActionMenuItem): void
}

const ActionsMenuItem: FC<ActionMenuItemProps> = ({ item, onAction }) => {
    const [isConfirming, setIsConfirming] = useState(false)

    const _onClick = useCallback(() => {
        if (!item.shouldConfirm || isConfirming) {
            onAction(item)
        } else {
            setIsConfirming(true)
        }
    }, [item, isConfirming, onAction])

    if (isConfirming) {
        return (
            <span
                key={item.id}
                className="actions-menu-item__confirming"
                onClick={_onClick}>
                Are you sure?
            </span>
        )
    }

    return (
        <span key={item.id} className="actions-menu-item" onClick={_onClick}>
            {item.name}
        </span>
    )
}
