import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import type {
    APICreateOrganizationRequest,
    OrganizationStore,
} from '../../../stores/organization-store'
import { Modal } from '../../modal'
import { TextButton } from '../../text-button'
import { FormSeparator } from './form-separator'
import { FormTextInput } from './form-text-input'

export interface CreateOrganizationModalProps {
    anchor: HTMLElement
    onClose?(): void
    onCreate?(input: APICreateOrganizationRequest): void
    store: OrganizationStore
}

export const CreateOrganizationModal: FC<CreateOrganizationModalProps> =
    observer(({ anchor, onClose, onCreate, store }) => {
        const [name, setName] = useState('')
        const submit = useCallback(
            (e: React.FormEvent) => {
                e.preventDefault()
                const actualName = name.trim()

                if (actualName) {
                    onCreate?.({ name })
                }
            },
            [name, onCreate],
        )

        return (
            <Modal onClose={onClose} anchor={anchor}>
                <form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 1rem 1rem',
                    }}
                    onSubmit={submit}>
                    <span className="color-dimmed">Name</span>
                    <FormTextInput
                        value={name}
                        onChange={setName}
                        placeholder="Acme Inc."
                        style={{
                            marginTop: '0.75rem',
                        }}
                    />

                    <FormSeparator />

                    <TextButton
                        onClick={submit}
                        disabled={store.isUpdating}
                        disabledContent="Creating organization...">
                        Create organization
                    </TextButton>
                </form>
            </Modal>
        )
    })
