import type { CSSProperties, FC } from 'react'
import { TextButton, TextButtonProps } from '../text-button'

export interface TableButtonCellProps {
    children: string | string[] | number
    style?: CSSProperties
    title?: string
    onClick?: React.MouseEventHandler<HTMLElement>
    containerRef?: React.RefObject<HTMLTableCellElement>
    disabled?: boolean
    kind?: TextButtonProps['kind']
}

export const TableButtonCell: FC<TableButtonCellProps> = ({
    children,
    style,
    onClick,
    title,
    containerRef,
    disabled,
    kind = 'info',
}) => {
    return (
        <td
            ref={containerRef}
            className="table-cell"
            title={title}
            onClick={onClick}
            style={style}>
            <TextButton kind={kind} disabled={disabled}>
                {children}
            </TextButton>
        </td>
    )
}

