import { useCallback, useMemo, useState } from 'react'
import type { APIProductCategory, APIProductManufacturer, APIProductModel } from '@visorpro/client'
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_Cell,
} from 'material-react-table';
import type {
    APICreateModelRequest,
    APIUpdateModelRequest,
} from '@visorpro/client/dist/RestClient/services/ModelService'
import { Box, Button, MenuItem, Select } from '@mui/material';
import { formatDate } from '../util/format';
import { type SearchableSelectOption } from './searchable-select';
import { useAdminMaterialReactTable, useMultiValueColumnDef } from './table/mui-table';

export interface ModelsTableProps {
    models: APIProductModel[]
    manufacturers: APIProductManufacturer[]
    categories: APIProductCategory[]
    isLoading?: boolean
    showProgressBars?: boolean
    rowCount?: number
    isPrimaryBackgroundColor?: boolean
    createModel?: (body: APICreateModelRequest) => void
    updateModel?: (modelId: string, body: Partial<APIUpdateModelRequest>) => void
    deleteModel?: (modelId: string) => void
    mergeModels?: (modelIds: string[]) => void
}

const useManufacturerOptions = (manufacturers: APIProductManufacturer[]): SearchableSelectOption[] => {
    return useMemo(() => {
        return manufacturers.map((manufacturer) => ({
            value: manufacturer.id,
            label: manufacturer.name,
        }))
    }, [manufacturers])
}

const useCategoryOptions = (categories: APIProductCategory[]): SearchableSelectOption[] => {
    return useMemo(() => {
        return categories.map((category) => ({
            value: category.id,
            label: category.name,
        }))
    }, [categories])
}


export const ModelsTable = ({ models, manufacturers, categories, rowCount, isLoading, showProgressBars, isPrimaryBackgroundColor, createModel, updateModel, deleteModel: _deleteModel, mergeModels }: ModelsTableProps) => {
    const [createModelBody, setCreateModelBody] = useState<Partial<APICreateModelRequest>>({});

    const onEditCell = useCallback((cell: MRT_Cell<APIProductModel>, body: Partial<APIUpdateModelRequest> | Partial<APICreateModelRequest>) => {
        const original = cell.row.original
        const manufacturer = manufacturers.find((manufacturer) => manufacturer.id === body.product_manufacturer_id)
        const category = categories.find((category) => category.id === body.product_category_id)
        const updated = { ...original, ...body, product_manufacturer: manufacturer, product_category: category }

        if (cell.row.id === "mrt-row-create" || cell.row.index === -1) {
            setCreateModelBody((prev) => ({ ...prev, ...body }))
        } else {
            Object.assign(cell.row.original, updated)
            updateModel?.(cell.row.id, body)
        }
    }, [categories, manufacturers, updateModel])

    const { Filter: ManufacturerFilter, enableColumnFilter: enableManufacturerColumnFilter } = useMultiValueColumnDef<APIProductModel, APIProductManufacturer>({
        fieldName: 'Manufacturer',
        optionsData: manufacturers,
        useOptions: useManufacturerOptions,
    })

    const { Filter: CategoryFilter, enableColumnFilter: enableCategoryColumnFilter } = useMultiValueColumnDef<APIProductModel, APIProductCategory>({
        fieldName: 'Category',
        optionsData: categories,
        useOptions: useCategoryOptions,
    })

    const columns = useMemo<MRT_ColumnDef<APIProductModel>[]>(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            enableClickToCopy: true,
            size: 400,
        },
        {
            accessorKey: 'name',
            header: 'Name',
            size: 600,
            enableEditing: updateModel !== undefined,
            muiEditTextFieldProps: ({ cell }) => ({
                type: 'text',
                required: true,
                onBlur: (event) => {
                    onEditCell(cell, { "name": event.target.value })
                },
            }),
        },
        {
            accessorKey: 'product_manufacturer',
            header: 'Manufacturer',
            enableEditing: updateModel !== undefined,
            Cell: ({ cell }) => {
                const manufacturer = cell.getValue<APIProductManufacturer>()
                if (manufacturer === undefined) { return "" }
                return manufacturer.name
            },
            Edit: ({ cell, table }) => {
                const manufacturerId = cell.getValue<APIProductManufacturer>().id
                return (
                    <Select
                        fullWidth
                        defaultValue={manufacturerId}
                        size='small'
                        variant='standard'
                        onBlur={(event) => {
                            table.setEditingCell(null)
                            onEditCell(cell, { "product_manufacturer_id": event.target.value })
                        }}
                        label='Manufacturer'
                    >
                        {manufacturers.map((manufacturer) => (
                            <MenuItem key={manufacturer.id} value={manufacturer.id}>
                                {manufacturer.name}
                            </MenuItem>
                        ))}
                    </Select>
                )
            },
            enableColumnFilter: enableManufacturerColumnFilter,
            filterFn: (row, _, filterValue: string[]) => {
                const manufacturerId = row.original.product_manufacturer_id
                return filterValue.includes(manufacturerId)
            },
            Filter: ManufacturerFilter,
        },
        {
            accessorKey: 'product_category',
            header: 'Category',
            enableEditing: updateModel !== undefined,
            Cell: ({ cell }) => {
                const category = cell.getValue<APIProductCategory>()
                if (category === undefined) { return "" }
                return cell.getValue<APIProductCategory>().name
            },
            Edit: ({ cell, table }) => {
                const categoryId = cell.getValue<APIProductCategory>().id

                return (
                    <Select
                        fullWidth
                        defaultValue={categoryId}
                        label='Category'
                        size='small'
                        variant='standard'
                        onBlur={(event) => {
                            table.setEditingCell(null)
                            onEditCell(cell, { "product_category_id": event.target.value })
                        }}
                    >
                        {categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                )
            },
            enableColumnFilter: enableCategoryColumnFilter,
            filterFn: (row, _, filterValue: string[]) => {
                const categoryId = row.original.product_category_id
                return filterValue.includes(categoryId)
            },
            Filter: CategoryFilter,
        },
        {
            accessorFn: (doc) => doc.updated_at,
            header: 'Updated At',
            id: 'updated_at',
            Cell: ({ row }) => formatDate(row.getValue("updated_at")),
            filterVariant: 'datetime-range',
        },
        {
            accessorFn: (doc) => doc.created_at,
            header: 'Created At',
            id: 'created_at',
            Cell: ({ row }) => formatDate(row.getValue("created_at")),
            filterVariant: 'datetime-range',
        },
    ], [CategoryFilter, ManufacturerFilter, categories, enableCategoryColumnFilter, enableManufacturerColumnFilter, manufacturers, onEditCell, updateModel]);

    const table = useAdminMaterialReactTable({
        columns,
        data: models,
        rowCount,
        enableRowSelection: true,
        enableSorting: true,
        initialState: {
            columnVisibility: {
                id: false,
            },
        },
        defaultColumn: {
            enableColumnFilter: true,
            enableSorting: true,
        },
        getRowId: (doc) => doc.id,
        state: {
            isLoading,
            showProgressBars,
        },
        renderTopToolbarCustomActions: ({ table }) => {
            const onCreateModel = () => table.setCreatingRow(true)
            const onMergeModels = () => {
                const selectedRows = table.getSelectedRowModel().flatRows
                mergeModels?.(selectedRows.map((row) => row.original.id))
            }
            const selectedRows = table.getSelectedRowModel().flatRows

            return (
                <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    {createModel && <Button onClick={onCreateModel}>Create model</Button>}
                    {mergeModels && <Button onClick={onMergeModels} disabled={selectedRows.length < 2}>
                        {selectedRows.length > 1 ? `Merge ${selectedRows.length} models` : "Merge models"}
                    </Button>}
                </Box>
            )
        },
        createDisplayMode: 'row',
        onCreatingRowSave: ({ table }) => {
            createModel?.(createModelBody as APICreateModelRequest)
            table.setCreatingRow(null)
        },
        isPrimaryBackgroundColor,
    }, 'models-table');

    return <MaterialReactTable table={table} />
}
