import { observer } from "mobx-react-lite"
import { MetadataImportStore } from "../metadata-import-store"
import { MetadataDiffRowAlternative, ModelSelectionRow } from "./model-selection-row"
import { CSSProperties, useMemo } from "react"
import { useManufacturerById } from "../../../../stores/manufacturers-store"
import { useCategoryById } from "../../../../stores/categories-store"
import { Stores } from "../../../../util/store"

export interface MetadataImportStepPreviewModelsProps {
    stores: Stores
    metadataImportStore: MetadataImportStore
}

export const MetadataImportStepPreviewModels = observer(({
    stores,
    metadataImportStore,
}: MetadataImportStepPreviewModelsProps) => {
    const models = useMemo(() => {
        if (metadataImportStore.modelPreviewsByName) {
            return Object.values(metadataImportStore.modelPreviewsByName)
        }
    }, [metadataImportStore.modelPreviewsByName])

    const manufacturer = useManufacturerById(metadataImportStore.manufacturerId, stores)
    const category = useCategoryById(metadataImportStore.categoryId, stores)

    const rows = useMemo(() => {
        if (!models) {
            return (
                <div style={{ color: 'var(--cyan-color)' }}>
                    Loading...
                </div>
            )
        }

        return models.map(model => {
            const alternatives: MetadataDiffRowAlternative[] = []

            if (model.canBeCreated) {
                alternatives.push({
                    similarityPercentage: 0,
                    manufacturerName: manufacturer?.name ?? '???',
                    categoryName: category?.name ?? '???',
                    modelName: model.originalName,
                    originalName: model.originalName,
                    isSelected: model.selectedAlternativeModelId === undefined,
                    isIgnored: model.isIgnored,
                    selectedColor: 'var(--green-color)',
                })
            }

            model.alternatives.forEach((alternative) => {
                alternatives.push({
                    modelId: alternative.model.id,
                    similarityPercentage: alternative.similarityPercentage,
                    manufacturerName: alternative.model.product_manufacturer!.name,
                    categoryName: alternative.model.product_category!.name,
                    originalName: model.originalName,
                    modelName: alternative.model.name,
                    isSelected: model.selectedAlternativeModelId === alternative.model.id,
                    isIgnored: model.isIgnored,
                    selectedColor: 'var(--purple-color)'
                })
            })

            return (
                <ModelSelectionRow
                    key={model.originalName}
                    alternatives={alternatives}
                    onSelect={metadataImportStore.selectAlternative}
                    onIgnore={metadataImportStore.ignoreAlternative}
                />
            )
        })
    }, [models])

    const headerItemStyle: CSSProperties = {
        color: 'var(--comment-color)',
        position: 'sticky',
        top: 0,
        backgroundColor: 'var(--background-dark-color)',
    }

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            whiteSpace: 'nowrap',
            maxHeight: '75vh',
            overflow: 'auto',
        }}>
            <div style={headerItemStyle}>Similarity</div>
            <div style={headerItemStyle}>Manufacturer</div>
            <div style={headerItemStyle}>Category</div>
            <div style={headerItemStyle}>Model name</div>
            <div style={headerItemStyle}>Selected</div>
            <div style={headerItemStyle}>Ignored</div>

            {rows}
        </div>
    )
})



