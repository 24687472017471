import type { FC } from 'react'
import { useCallback } from 'react'
import type { ActionMenuItem } from '../../components/actions-menu'
import { ActionsMenu } from '../../components/actions-menu'

export enum PromptActionKind {
    Update = 'update',
    Delete = 'delete',
}

interface PromptActionsModalProps {
    anchor: HTMLElement
    promptId: string
    onClose(): void
    onAction(promptId: string, kind: PromptActionKind): void
}

export const PromptActionsModal: FC<PromptActionsModalProps> = ({
    anchor,
    promptId,
    onClose,
    onAction,
}) => {
    const _onAction = useCallback(
        (item: ActionMenuItem) => {
            onAction(promptId, item.id as PromptActionKind)
        },
        [onAction, promptId],
    )

    return (
        <ActionsMenu
            anchor={anchor}
            items={[
                {
                    id: PromptActionKind.Update,
                    name: 'Update prompt',
                },
                {
                    id: PromptActionKind.Delete,
                    name: 'Delete prompt',
                    shouldConfirm: true,
                },
            ]}
            onAction={_onAction}
            onClose={onClose}
        />
    )
}
