import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDocumentById } from '../../../../stores/documents-store'
import type { Stores } from '../../../../util/store'
import { DocumentVersionsTable } from './document-versions-table'

export interface DocumentVersionsProps {
    stores: Stores
}

export const DocumentVersions = observer(
    ({ stores }: DocumentVersionsProps) => {
        const { documentId } = useParams()
        const document = useDocumentById(stores, documentId!)

        const toggleVersionEnabled = useCallback(
            (versionId: string, enabled: boolean) => {
                void stores.documents.updateDocumentVersion(
                    documentId!,
                    versionId,
                    {
                        is_enabled: enabled,
                    },
                )
            },
            [documentId, stores.documents],
        )

        if (!document) {
            return null
        }

        return (
            <div>
                <DocumentVersionsTable
                    versions={document.versions ?? []}
                    onIsEnabledClick={toggleVersionEnabled}
                />
            </div>
        )
    },
)
