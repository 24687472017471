import type { CSSProperties, FormEvent } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FormSeparator } from '../../components/modals/creation/form-separator'
import { FormTextInput } from '../../components/modals/creation/form-text-input'
import { TextButton } from '../../components/text-button'
import { API_TOKEN_KEY, visorPRORestClient } from '../../util/api'

enum LoginStep {
    PhoneNumber,
    Code,
}

export const LoginPage = () => {
    const {
        phoneNumber,
        setPhoneNumber,
        code,
        setCode,
        step,
        onNextClick,
        isLoading,
    } = useLogin()

    const [params, setParams] = useSearchParams()
    const didNotifyRef = useRef(false)

    useEffect(() => {
        if (!didNotifyRef.current && params.get('logged_out') === 'true') {
            toast.error('Your session has expired. Please login again.')
            didNotifyRef.current = true
            setParams({})
        }
    }, [params, setParams, didNotifyRef])

    const textInput = useMemo(() => {
        const style: CSSProperties = {
            marginTop: '1rem',
        }

        if (step === LoginStep.PhoneNumber) {
            return (
                <FormTextInput
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    placeholder="+1 555 555 5555"
                    style={style}
                />
            )
        }

        return (
            <FormTextInput
                value={code}
                onChange={setCode}
                placeholder="000000"
                style={style}
            />
        )
    }, [phoneNumber, code, step, setCode, setPhoneNumber])

    return (
        <div
            style={{
                minWidth: '100vw',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <form
                style={{
                    padding: '1.5rem',
                    backgroundColor: 'var(--background-dark-color)',
                    transform: 'translateY(-100%)',
                    borderRadius: '6px',
                    width: '100%',
                    maxWidth: '350px',
                    boxSizing: 'border-box',
                }}>
                <span style={{ color: 'var(--foreground-color)' }}>Login</span>

                {textInput}

                <FormSeparator />

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <TextButton
                        onClick={onNextClick}
                        disabled={isLoading}
                        disabledContent="Loading...">
                        {step === LoginStep.PhoneNumber
                            ? 'Send SMS'
                            : 'Verify code'}
                    </TextButton>
                </div>
            </form>
        </div>
    )
}

const useLogin = () => {
    const [phoneNumber, setPhoneNumber] = useState('')
    const [code, setCode] = useState('')
    const [step, setStep] = useState(LoginStep.PhoneNumber)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const onNextClick = useCallback(
        async (e: FormEvent) => {
            e.preventDefault()

            if (isLoading) {
                return
            }

            setIsLoading(true)

            if (step === LoginStep.PhoneNumber) {
                // send sms and ask for code
                const payload = {
                    phone_number: phoneNumber.trim(),
                }

                if (payload.phone_number) {
                    try {
                        await visorPRORestClient.passwordlessSmsStart(payload)
                        setStep(LoginStep.Code)
                    } catch (e) {
                        toast.error('There was an error while sending the SMS')
                    } finally {
                        setIsLoading(false)
                    }
                }
            } else {
                // verify code and save token
                const payload = {
                    phone_number: phoneNumber.trim(),
                    code: code.trim(),
                }

                if (payload.phone_number && payload.code) {
                    try {
                        const apiAuthToken =
                            await visorPRORestClient.passwordlessSmsVerify(
                                payload,
                            )
                        localStorage.setItem(API_TOKEN_KEY, apiAuthToken.token)
                        navigate('/organizations')
                    } catch (e) {
                        toast.error(
                            'There was an error while verifying the code',
                        )
                    } finally {
                        setIsLoading(false)
                    }
                }
            }
        },
        [step, phoneNumber, code, navigate, isLoading],
    )

    return {
        phoneNumber,
        setPhoneNumber,
        code,
        setCode,
        step,
        onNextClick,
        isLoading,
    }
}
