import { observer } from "mobx-react-lite"
import { MetadataImportStore } from "../metadata-import-store"
import { MetadataDiffRow } from "./metadata-diff-row"

export interface MetadataImportStepPreviewDocumentsProps {
    metadataImportStore: MetadataImportStore
}

export const MetadataImportStepPreviewDocuments = observer(({
    metadataImportStore,
}: MetadataImportStepPreviewDocumentsProps) => {
    return (
        <div>
            {metadataImportStore.documentDiffs.length === 0 &&
                <div style={{ color: 'var(--foreground-color)' }}>
                    No documents will be changed.
                </div>
            }

            {metadataImportStore.documentDiffs.length > 0 &&
                metadataImportStore.documentDiffs.map(diff => (
                    <MetadataDiffRow
                        key={diff.rowNumber}
                        diff={diff}
                    />
                ))
            }
        </div>
    )
})

