import type { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback } from 'react'
import { MdMoreVert } from 'react-icons/md'
import type { Stores } from '../../util/store'
import { TableDateCell } from '../table/table-date-cell'
import { TableRow } from '../table/table-row'
import { TableTextCell } from '../table/table-text-cell'

export interface TestQuestionRowProps {
    stores: Stores
    testQuestionId: string
    now: DateTime
    onShowMenu(e: React.MouseEvent, testQuestionId: string): void
}

export const TestQuestionRow: FC<TestQuestionRowProps> = observer(
    ({ stores, testQuestionId, now, onShowMenu }) => {
        const testQuestion =
            stores.testQuestions.testQuestionsById[testQuestionId]

        const onMoreClick = useCallback(
            (e: React.MouseEvent) => {
                onShowMenu(e, testQuestionId)
            },
            [onShowMenu, testQuestionId],
        )

        return (
            <TableRow key={testQuestion.id}>
                <TableTextCell>{testQuestion.text}</TableTextCell>

                <TableTextCell>
                    {testQuestion.acceptance_criteria.length}
                </TableTextCell>

                <TableDateCell date={testQuestion.created_at} now={now} />

                <td className="table-cell">
                    <MdMoreVert
                        size={18}
                        onClick={onMoreClick}
                        className="dimmed-pressable-icon"
                    />
                </td>
            </TableRow>
        )
    },
)
