import type { APIDocumentType } from "@visorpro/client"
import { observer } from "mobx-react-lite"
import { type FC, useMemo } from "react"


export interface MetadataDocumentDiff {
    documentId: string
    rowNumber: number

    originalFileName: string
    matchString: string

    title?: {
        current: string
        proposed: string
    }

    models?: {
        added: string[]
    }

    type?: APIDocumentType
    statusLabel?: string
    statusColor?: string
}

export interface MetadataDiffRowProps {
    diff: MetadataDocumentDiff
}

export const MetadataDiffRow: FC<MetadataDiffRowProps> = observer(({
    diff,
}: MetadataDiffRowProps) => {
    const { rowNumber, title, models, originalFileName, matchString } = diff

    const match = useMemo(() => {
        const matchIndex = originalFileName.indexOf(matchString)
        if (matchIndex === -1) {
            return originalFileName
        }

        return (
            <>
                {originalFileName.substring(0, matchIndex)}

                <span style={{ color: 'var(--cyan-color)' }}>
                    {originalFileName.substring(matchIndex, matchIndex + matchString.length)}
                </span>

                {originalFileName.substring(matchIndex + matchString.length)}
            </>
        )
    }, [originalFileName, matchString])

    const addedSegments = useMemo(() => {
        if (!models?.added) {
            return []
        }

        return models.added.map((model, index) => (
            <span key={model}>
                {index > 0 && ', '}
                <span style={{ color: 'var(--purple-color)' }}>{model}</span>
            </span>
        ))
    }, [models?.added])

    return (
        <>
            <hr style={{
                margin: '0.5rem 0',
                border: 'none',
                borderBottom: '1px solid var(--background-color)',
            }} />

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <span style={{ color: 'var(--comment-color)' }}>
                    {rowNumber}
                </span>

                <div style={{ marginLeft: '1rem', width: '100%' }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: '1rem',
                    }}>
                        <div style={{ color: 'var(--comment-color)' }}>
                            <span style={{ color: 'var(--foreground-color)' }}>File name</span>: {match}
                        </div>

                        {
                            typeof diff.statusLabel === 'string' &&
                            <span style={{ color: diff.statusColor }}>
                                {diff.statusLabel}
                            </span>
                        }
                    </div>

                    {
                        title &&
                        <>
                            <div style={{ color: 'var(--foreground-color)' }}>
                                Current title<span style={{ color: 'var(--comment-color)' }}>:</span> {title.current}
                            </div>

                            <div style={{ color: 'var(--comment-color)' }}>
                                <span style={{ color: 'var(--foreground-color)' }}>New title</span>: <span style={{ color: 'var(--green-color)' }}>{title.proposed}</span>
                            </div>
                        </>
                    }

                    {
                        addedSegments.length > 0 &&
                        <div style={{ color: 'var(--comment-color)' }}>
                            <span style={{ color: 'var(--foreground-color)' }}>Added models</span>: {addedSegments}
                        </div>
                    }
                </div>
            </div>
        </>
    )
})
