import type { Placement } from '@floating-ui/react'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import type { Stores } from '../../util/store'
import { TestRunConfigFormStore } from './test-run-config-form-store'
import { TestRunConfigModalMain } from './test-run-config-modal-main'
import { TestRunConfigModalModel } from './test-run-config-modal-model'
import { TestRunConfigModalPrompt } from './test-run-config-modal-prompt'
import { TestRunConfigModalStep } from './test-run-config-modal-step'

export interface TestRunConfigModalProps {
    documentId?: string
    testRunIds?: string[]
    stores: Stores
    anchor: HTMLElement
    placement: Placement
    onClose(): void
}

export const TestRunConfigModal: FC<TestRunConfigModalProps> = ({
    documentId,
    testRunIds,
    stores,
    anchor,
    onClose,
}) => {
    const [step, setStep] = useState(TestRunConfigModalStep.Main)
    const [formStore] = useState(() => {
        return new TestRunConfigFormStore({
            documentId,
            testRunIds,
        })
    })

    const onPromptModalClose = useCallback(() => {
        setStep(TestRunConfigModalStep.Main)
    }, [])

    const onPromptSelect = useCallback(
        (promptId: string) => {
            formStore.setPromptId(promptId)
            setStep(TestRunConfigModalStep.Main)
        },
        [formStore],
    )

    const onModelModalClose = useCallback(() => {
        setStep(TestRunConfigModalStep.Main)
    }, [])

    const onModelSelect = useCallback(
        (model: string) => {
            formStore.setModel(model)
            setStep(TestRunConfigModalStep.Main)
        },
        [formStore],
    )

    if (step === TestRunConfigModalStep.Main) {
        return (
            <TestRunConfigModalMain
                stores={stores}
                onClose={onClose}
                anchor={anchor}
                formStore={formStore}
                onStepChange={setStep}
            />
        )
    }

    if (step === TestRunConfigModalStep.Prompt) {
        return (
            <TestRunConfigModalPrompt
                stores={stores}
                onClose={onPromptModalClose}
                anchor={anchor}
                onSelect={onPromptSelect}
            />
        )
    }

    if (step === TestRunConfigModalStep.Model) {
        return (
            <TestRunConfigModalModel
                stores={stores}
                onClose={onModelModalClose}
                anchor={anchor}
                onSelect={onModelSelect}
            />
        )
    }

    throw new Error(`Unknown step ${step}`)
}
