import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { Outlet, useMatches, useParams } from 'react-router-dom'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { useModal } from '../../components/modal'
import { QuestionModal } from '../../components/question-modal/question-modal'
import { TestRunConfigModal } from '../../components/test-run-config-modal/test-run-config-modal'
import { TextButton } from '../../components/text-button'
import { useDocumentById } from '../../stores/documents-store'
import { RouteName } from '../../util/router'
import type { Stores } from '../../util/store'
import { DocumentNavBar } from './documents-nav-bar'

export interface ModelsPageProps {
    stores: Stores
}

export const DocumentDetailsPage = observer(({ stores }: ModelsPageProps) => {
    const { documentId } = useParams()
    const matches = useMatches()
    const lastMatch = matches[matches.length - 1]

    const document = useDocumentById(stores, documentId!)
    const createQuestionModal = useModal()

    const testRunConfigModal = useModal()

    const createTestRun = useCallback(
        (e: React.MouseEvent) => {
            testRunConfigModal.open(e)
        },
        [testRunConfigModal],
    )

    if (!document) {
        return null
    }

    return (
        <div className="organization-details">
            <BreadCrumbs
                name={document.title}
                path={[
                    {
                        name: 'Documents',
                        to: '/documents',
                    },
                ]}
            />

            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    marginTop: '1rem',
                }}>
                <DocumentNavBar />

                {lastMatch.id === RouteName.DocumentDetailsQuestions && (
                    <TextButton onClick={createQuestionModal.open}>
                        Create question
                    </TextButton>
                )}

                {lastMatch.id === RouteName.DocumentDetailsTestRuns && (
                    <TextButton
                        onClick={createTestRun}
                        style={{ color: 'var(--green-color)' }}>
                        Setup new test run
                    </TextButton>
                )}
            </div>

            <div className="content">
                <Outlet />
            </div>

            {createQuestionModal.state && (
                <QuestionModal
                    stores={stores}
                    anchor={createQuestionModal.state.anchor}
                    onClose={createQuestionModal.close}
                    documentIds={[documentId!]}
                />
            )}

            {testRunConfigModal.state && (
                <TestRunConfigModal
                    documentId={documentId}
                    stores={stores}
                    anchor={testRunConfigModal.state.anchor}
                    placement="bottom-end"
                    onClose={testRunConfigModal.close}
                />
            )}
        </div>
    )
})
