import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import './index.css'
import { DataSetDetailsPage } from './pages/data-set-details'
import { DataSetDocuments } from './pages/data-set-details/pages/documents'
import { DataSetQuestions } from './pages/data-set-details/pages/questions'
import { DataSetTestRuns } from './pages/data-set-details/pages/test-runs'
import { DataSetsPage } from './pages/data-sets'
import { DocumentDetailsPage } from './pages/document-detais'
import { DocumentQuestions } from './pages/document-detais/pages/questions'
import { DocumentTestRuns } from './pages/document-detais/pages/test-runs'
import { DocumentVersions } from './pages/document-detais/pages/versions'
import { DocumentTestRunDetailsPage } from './pages/document-test-run-details'
import { DocumentsPage } from './pages/documents'
import { IssueDetailsPage } from './pages/issue-details'
import { IssuesPage } from './pages/issues'
import { LoginPage } from './pages/login'
import { ModelsPage } from './pages/models'
import { OrganizationDetailsPage } from './pages/organization-details'
import { OrganizationDataSetsPage } from './pages/organization-details/pages/data-sets'
import { OrganizationDomainsPage } from './pages/organization-details/pages/domains'
import { OrganizationInvitationCodesPage } from './pages/organization-details/pages/invitation-codes'
import { OrganizationMembersPage } from './pages/organization-details/pages/members'
import { OrganizationProfilePage } from './pages/organization-details/pages/profile'
import { OrganizationsPage } from './pages/organizations'
import { PromptsListPage } from './pages/prompts'
import { RootPage } from './pages/root'
import { TestRunsPage } from './pages/test-runs'
import { UserDetailsPage } from './pages/user-details'
import { UsersListPage } from './pages/users-list'
import { RouteName } from './util/router'
import { Stores } from './util/store'
import { theme } from './theme/theme';
import { KnowledgeArticlesPage } from './pages/knowledge-articles';
import { QueueTasksPage } from './pages/queue-tasks';
import { DocumentQueueTasks } from './pages/document-detais/pages/queue-tasks';
import WorkOrdersPage from './pages/work-orders';
import DocumentInfo from './pages/document-detais/pages/info';

const stores = new Stores()

const router = createBrowserRouter([
    {
        id: RouteName.Login,
        path: '/login',
        element: <LoginPage />,
    },
    {
        id: RouteName.Root,
        path: '/',
        element: <RootPage />,
        children: [
            {
                id: RouteName.OrganizationsList,
                path: 'organizations',
                element: <OrganizationsPage store={stores.organizations} />,
            },
            {
                id: RouteName.OrganizationDetails,
                path: 'organizations/:organizationId',
                element: <OrganizationDetailsPage stores={stores} />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="members" replace />,
                    },
                    {
                        id: RouteName.OrganizationDetailsMembers,
                        path: 'members',
                        element: <OrganizationMembersPage stores={stores} />,
                    },
                    {
                        id: RouteName.OrganizationDetailsDataSets,
                        path: 'data-sets',
                        element: <OrganizationDataSetsPage stores={stores} />,
                    },
                    {
                        id: RouteName.OrganizationDetailsInvitationCodes,
                        path: 'invitation-codes',
                        element: (
                            <OrganizationInvitationCodesPage stores={stores} />
                        ),
                    },
                    {
                        id: RouteName.OrganizationDetailsProfile,
                        path: 'profile',
                        element: <OrganizationProfilePage stores={stores} />,
                    },
                    {
                        id: RouteName.OrganizationDetailsDomains,
                        path: 'domains',
                        element: <OrganizationDomainsPage stores={stores} />,
                    },
                ],
            },
            {
                id: RouteName.UsersList,
                path: 'users',
                element: <UsersListPage stores={stores} />,
            },
            {
                id: RouteName.UserDetails,
                path: 'users/:userId',
                element: <UserDetailsPage stores={stores} />,
            },
            {
                id: RouteName.DataSets,
                path: 'data-sets',
                element: <DataSetsPage stores={stores} />,
            },
            {
                id: RouteName.DataSetDetails,
                path: 'data-sets/:dataSetId',
                element: <DataSetDetailsPage stores={stores} />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="documents" replace />,
                    },
                    {
                        id: RouteName.DataSetDetailsDocuments,
                        path: 'documents',
                        element: <DataSetDocuments stores={stores} />,
                    },
                    {
                        id: RouteName.DataSetDetailsQuestions,
                        path: 'questions',
                        element: <DataSetQuestions stores={stores} />,
                    },
                    {
                        id: RouteName.DataSetDetailsTestRuns,
                        path: 'test-runs',
                        element: <DataSetTestRuns stores={stores} />,
                    },
                ],
            },
            {
                id: RouteName.Models,
                path: 'models',
                element: <ModelsPage stores={stores} />,
            },
            {
                id: RouteName.Documents,
                path: 'documents',
                element: <DocumentsPage stores={stores} />,
            },
            {
                id: RouteName.DocumentDetails,
                path: 'documents/:documentId',
                element: <DocumentDetailsPage stores={stores} />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="info" replace />,
                    },
                    {
                        id: RouteName.DocumentDetailsInfo,
                        path: 'info',
                        element: <DocumentInfo stores={stores} />,
                    },
                    {
                        id: RouteName.DocumentDetailsQuestions,
                        path: 'questions',
                        element: <DocumentQuestions stores={stores} />,
                    },
                    {
                        id: RouteName.DocumentDetailsTestRuns,
                        path: 'test-runs',
                        element: <DocumentTestRuns stores={stores} />,
                    },
                    {
                        id: RouteName.DocumentDetailsVersions,
                        path: 'versions',
                        element: <DocumentVersions stores={stores} />,
                    },
                    {
                        id: RouteName.DocumentDetailsQueueTasks,
                        path: 'tasks',
                        element: <DocumentQueueTasks stores={stores} />,
                    }
                ],
            },
            {
                id: RouteName.Issues,
                path: 'issues',
                element: <IssuesPage stores={stores} />,
            },
            {
                id: RouteName.IssueDetails,
                path: 'issues/:issueId',
                element: <IssueDetailsPage stores={stores} />,
            },
            {
                id: RouteName.DocumentTestRunDetails,
                path: 'documents/:documentId/test-runs/:testRunId',
                element: <DocumentTestRunDetailsPage stores={stores} />,
            },
            {
                id: RouteName.Prompts,
                path: 'prompts',
                element: <PromptsListPage stores={stores} />,
            },
            {
                id: RouteName.TestRuns,
                path: 'test-runs',
                element: <TestRunsPage stores={stores} />,
            },
            {
                id: RouteName.KnowledgeArticles,
                path: 'knowledge-articles',
                element: <KnowledgeArticlesPage stores={stores} />
            },
            {
                id: RouteName.QueueTasks,
                path: 'tasks',
                element: <QueueTasksPage stores={stores} />,
            },
            {
                id: RouteName.WorkOrders,
                path: 'work-orders',
                element: <WorkOrdersPage stores={stores} />,
            }
        ],
    },
])

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CssBaseline />

                    <RouterProvider router={router} />

                    <ToastContainer
                        theme="dark"
                        position="bottom-right"
                        autoClose={5000}
                    />
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    )
}

export default App
