import { makeObservable, observable, runInAction } from 'mobx'

export interface Keyed {
    key: string
}

export interface ListStoreOptions<T> {
    initialItems?: T[]
    createItem: () => T
}

export class ListStore<T extends Keyed> {
    public itemsByKey: Record<string, T> = {}
    public itemKeys: string[] = []

    // used to indicate component to focus after the list changes
    public autoFocusKey: string | undefined

    constructor(private readonly options: ListStoreOptions<T>) {
        options.initialItems?.forEach((item) => {
            this.itemsByKey[item.key] = item
            this.itemKeys.push(item.key)
        })

        makeObservable(this, {
            itemsByKey: observable,
            itemKeys: observable,
            autoFocusKey: observable,
        })
    }

    public clearAutoFocusKey = () => {
        runInAction(() => {
            this.autoFocusKey = undefined
        })
    }

    public addEmptyItem = () => {
        runInAction(() => {
            // const newItem =
            //   this.autoFocusKey = newItem.key
            const newItem = this.options.createItem()
            this.autoFocusKey = newItem.key
            this.itemsByKey = {
                ...this.itemsByKey,
                [newItem.key]: newItem,
            }
            this.itemKeys = [...this.itemKeys, newItem.key]
        })
    }

    public removeItem = (key: string) => {
        runInAction(() => {
            for (let i = 0; i < this.itemKeys.length; i++) {
                if (this.itemKeys[i] === key) {
                    // remove item
                    delete this.itemsByKey[key]
                    this.itemKeys = [
                        ...this.itemKeys.slice(0, i),
                        ...this.itemKeys.slice(i + 1),
                    ]

                    // focus previous or next item
                    if (this.itemKeys.length > 0) {
                        const newIndex = Math.max(0, i - 1)
                        this.autoFocusKey = this.itemKeys[newIndex]
                    }

                    break
                }
            }
        })
    }

    public updateItem = (key: string, fn: (item: T) => T) => {
        runInAction(() => {
            const item = this.itemsByKey[key]
            if (item) {
                this.itemsByKey = {
                    ...this.itemsByKey,
                    [key]: fn(item),
                }
            }
        })
    }

    public getItems = () => {
        return this.itemKeys.map((key) => this.itemsByKey[key])
    }
}
