import type { CSSProperties, FC } from 'react'

export interface TableTextCellProps {
    kind?: 'delete' | 'info' | 'comment'
    children:
    | string
    | number
    | JSX.Element
    | Array<string | number | JSX.Element>
    style?: CSSProperties
    title?: string
    onClick?: React.MouseEventHandler<HTMLElement>
    containerRef?: React.RefObject<HTMLTableCellElement>
}

export const TableTextCell: FC<TableTextCellProps> = ({
    kind = 'info',
    children,
    style,
    onClick,
    title,
    containerRef,
}) => {
    return (
        <td
            ref={containerRef}
            className={`table-cell table-cell_${kind}`}
            title={title}
            onClick={onClick}>
            <span style={style}>{children}</span>
        </td>
    )
}
