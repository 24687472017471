import type { Placement } from '@floating-ui/react'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { TextButton } from '../../text-button'
import { FormSeparator } from '../creation/form-separator'
import { FormTextInput } from '../creation/form-text-input'
import { Modal } from '../../modal'

export interface SingleTextFieldModalProps {
    identifier: string
    initialName: string
    anchor: HTMLElement
    onClose?(): void
    onSubmit?(identifier: string, name: string): void
    onSubmitWhileEmpty?(identifier: string): void
    isLoading: boolean
    contentStyle?: React.CSSProperties
    placeholder?: string
    placement?: Placement
    submitText?: string
    submitTextLoading?: string
}

export const SingleTextFieldModal: FC<SingleTextFieldModalProps> = observer(
    ({
        identifier,
        initialName,
        anchor,
        onClose,
        onSubmit,
        onSubmitWhileEmpty,
        isLoading,
        contentStyle,
        placeholder,
        placement = 'bottom-end',
        submitText = 'Update',
        submitTextLoading = 'Updating...',
    }) => {
        const [name, setName] = useState(initialName)
        const submit = useCallback(
            (e: React.FormEvent) => {
                e.preventDefault()
                const actualName = name.trim()

                if (actualName) {
                    onSubmit?.(identifier, name)
                } else {
                    onSubmitWhileEmpty?.(identifier)
                }
            },
            [identifier, name, onSubmit, onSubmitWhileEmpty],
        )

        return (
            <Modal
                anchor={anchor}
                style={contentStyle}
                placement={placement}
                onClose={onClose}>
                <form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 1rem 1rem',
                    }}
                    onSubmit={submit}>
                    <span className="color-dimmed">Name</span>

                    <FormTextInput
                        value={name}
                        onChange={setName}
                        placeholder={placeholder}
                        style={{
                            marginTop: '0.5rem',
                        }}
                    />

                    <FormSeparator />

                    <TextButton
                        onClick={submit}
                        disabled={isLoading}
                        disabledContent={submitTextLoading}>
                        {submitText}
                    </TextButton>
                </form>
            </Modal>
        )
    },
)
