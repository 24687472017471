import type { CSSProperties, FC, ReactNode } from 'react'
import './table.css'

export interface TableProps {
    children: ReactNode
    style?: CSSProperties
}

export const Table: FC<TableProps> = ({ children, style }) => {
    return (
        <table className="table" style={style}>
            {children}
        </table>
    )
}
