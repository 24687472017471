import type { CSSProperties } from 'react'
import { forwardRef } from 'react'
import './table-header.css'

export interface TableHeaderProps {
    children: React.ReactNode
    style?: CSSProperties
}

export const TableHeader = forwardRef(
    ({ children, style }: TableHeaderProps, ref) => {
        return (
            <th className="table-header" ref={ref as any} style={style}>
                {children}
            </th>
        )
    },
)
