import type { APIPrompt } from '@visorpro/client/dist/types/APIPrompt'
import type { DateTime } from 'luxon'
import type { CSSProperties, FC } from 'react'
import type { Stores } from '../../util/store'
import { Table } from '../table/table'
import { TableHeader } from '../table/table-header'
import { PromptRow } from './prompt-row'

export interface PromptsTableProps {
    now: DateTime
    stores: Stores
    prompts: APIPrompt[]
    onShowMenu(e: React.MouseEvent, promptId: APIPrompt): void
    style?: CSSProperties
}

export const PromptsTable: FC<PromptsTableProps> = ({
    now,
    stores,
    prompts,
    onShowMenu,
    style,
}) => {
    return (
        <>
            <Table style={style}>
                <thead>
                    <tr>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Content</TableHeader>
                        <TableHeader>Created</TableHeader>
                        <TableHeader> </TableHeader>
                    </tr>
                </thead>

                <tbody>
                    {prompts.map((prompt) => {
                        return (
                            <PromptRow
                                key={prompt.id}
                                promptId={prompt.id}
                                now={now}
                                onShowMenu={onShowMenu}
                                stores={stores}
                            />
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}
