import { observer } from 'mobx-react-lite'
import { useCallback, useRef, useState, type FC } from 'react'
import type { Stores } from '../../util/store'
import { Stack } from '@mui/material'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { TextButton } from '../../components/text-button'
import { KnowledgeArticlesUploadStore } from './knowledge-articles-upload-store'

export interface DataSetDetailsPageProps {
    stores: Stores
}

export const KnowledgeArticlesPage: FC<DataSetDetailsPageProps> = observer(
    () => {
        const [kaUploadStore] = useState(() => new KnowledgeArticlesUploadStore())
        const fileInputRef = useRef<HTMLInputElement>(null)

        const handleButtonClick = useCallback(() => {
            fileInputRef.current?.click()
        }, [])

        const handleFileChange = useCallback(
            async (e: React.ChangeEvent<HTMLInputElement>) => {
                kaUploadStore.handleFileChange(e.target.files)
            },
            [],
        )

        const totalCompleted = kaUploadStore.totalSuccesses + kaUploadStore.totalFailures

        const secondsSinceStart = kaUploadStore.uploadStartDate
            ? (kaUploadStore.lastUpdateDate!.getTime() - kaUploadStore.uploadStartDate.getTime()) / 1000
            : 0

        return (
            <Stack
                style={{
                    padding: '1rem',
                    boxSizing: 'border-box',
                    overflow: 'none',
                    height: '100vh',
                }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}>
                    <BreadCrumbs
                        name='Knowledge articles'
                        path={[]}
                    />

                    <TextButton onClick={handleButtonClick}>
                        Upload knowledge articles
                    </TextButton>
                </div>

                {
                    kaUploadStore.totalItemsToUpload > 0 &&
                    <>
                        <div>
                            Success: {kaUploadStore.totalSuccesses}
                        </div>

                        <div>
                            Error: {kaUploadStore.totalFailures}
                        </div>

                        <div>
                            Total: {kaUploadStore.totalItemsToUpload}
                        </div>

                        <div>
                            Avg: {Math.round(kaUploadStore.totalDuration / (totalCompleted || 1))}ms ({Math.floor(totalCompleted / kaUploadStore.totalItemsToUpload * 100)}%)
                        </div>

                        <div>
                            Duration: {Math.floor(secondsSinceStart)}s
                        </div>
                    </>
                }

                <input
                    // no `accept` so we can handle directories and random files
                    type="file"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    // odd, but it works!
                    {...{ webkitdirectory: '' }}
                />
            </Stack>
        )
    },
)


