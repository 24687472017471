import type { APIPrompt } from '@visorpro/client'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback } from 'react'
import { MdChevronLeft } from 'react-icons/md'
import { Modal } from '../../components/modal'
import { SelectableList } from '../../components/modals'
import { usePrompts } from '../../stores/prompts-store'
import type { Stores } from '../../util/store'

export interface PromptModalProps {
    anchor: HTMLElement
    stores: Stores
    onClose(): void
    onSelect(promptId: string): void
}

export const TestRunConfigModalPrompt: FC<PromptModalProps> = observer(
    ({ anchor, stores, onClose, onSelect }) => {
        const prompts = usePrompts(stores)

        const _onSelect = useCallback(
            (item: APIPrompt) => {
                onSelect(item.id)
            },
            [onSelect],
        )

        return (
            <Modal anchor={anchor} onClose={onClose}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.5rem',
                    }}>
                    <MdChevronLeft
                        size={24}
                        color="var(--comment-color)"
                        style={{
                            flexShrink: 0,
                            cursor: 'pointer',
                            paddingLeft: '0.5rem',
                        }}
                        onClick={onClose}
                    />

                    <span
                        style={{
                            color: 'var(--comment-color)',
                        }}>
                        Select prompt
                    </span>
                </div>

                <SelectableList
                    items={prompts}
                    onSelect={_onSelect}
                    isLoading={stores.prompts.isFetching}
                    placeholder=""
                />
            </Modal>
        )
    },
)
