import { MetadataImportStore } from "../metadata-import-store"
import { useRef } from "react"
import { observer } from "mobx-react-lite"
import { CategoryModal } from "../../../models/model-category-step"
import { Stores } from "../../../../util/store"
import { MappingRow } from "./mapping-row"
import { MappingSelectRow } from "./mapping-select-row"
import { ManufacturerModal } from "../../../models/model-manufacturer-step"
import { useManufacturerById } from "../../../../stores/manufacturers-store"
import { useCategoryById } from "../../../../stores/categories-store"

export interface MetadataImportStepMapColumnsProps {
    stores: Stores
    metadataImportStore: MetadataImportStore
}

export const MetadataImportStepMapColumns = observer(({
    stores,
    metadataImportStore,
}: MetadataImportStepMapColumnsProps) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const manufacturer = useManufacturerById(
        metadataImportStore.manufacturerId,
        stores
    )

    const category = useCategoryById(
        metadataImportStore.categoryId,
        stores
    )

    return (
        <div
            ref={containerRef}
            style={{
                gap: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
            }}>
            <MappingRow
                name='Match column'
                value={metadataImportStore.matchColumnMapping}
                onChange={metadataImportStore.setMatchColumnMapping}
                description='Will be used to link documents in the csv with documents in our database.'
            />

            <MappingRow
                name='Description'
                value={metadataImportStore.descriptionMapping}
                onChange={metadataImportStore.setDescriptionMapping}
            />

            <MappingRow
                name='Serial number'
                value={metadataImportStore.serialNumberMapping}
                onChange={metadataImportStore.setSerialNumberMapping}
                description='Will be included at the end of the title.'
            />

            <MappingRow
                name='Manual type'
                value={metadataImportStore.manualTypeMapping}
                onChange={metadataImportStore.setManualTypeMapping}
                description='Operator manual, service manual, etc.'
            />

            <MappingRow
                name='Models'
                value={metadataImportStore.modelsMapping}
                onChange={metadataImportStore.setModelsMapping}
                description='A list of comma-separated model names. We will attempt to use existing models whenever possible.'
            />

            <MappingRow
                name='Release date'
                value={metadataImportStore.releaseDateMapping}
                onChange={metadataImportStore.setReleaseDateMapping}
                description='Will be used to sort documents in the source selector.'
            />

            <MappingSelectRow
                label='Manufacturer'
                value={manufacturer?.name}
                createModal={
                    ({ anchor, onClose }) => (
                        <ManufacturerModal
                            stores={stores}
                            anchor={anchor}
                            containerStyle={{ zIndex: 5 }}
                            onClose={onClose}
                            onManufacturerIdChange={async (manufacturerId) => {
                                metadataImportStore.setManufacturerId(manufacturerId)
                                onClose()
                            }}
                        />
                    )
                }
            />

            <MappingSelectRow
                label='Category'
                value={category?.name}
                createModal={
                    ({ anchor, onClose }) => (
                        <CategoryModal
                            stores={stores}
                            anchor={anchor}
                            containerStyle={{ zIndex: 5 }}
                            onClose={onClose}
                            onCategoryIdChange={async (categoryId) => {
                                metadataImportStore.setCategoryId(categoryId)
                                onClose()
                            }}
                        />
                    )
                }
            />
        </div>
    )
})

