import type { APIProductManufacturer } from '@visorpro/client'
import { makeAutoObservable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { observed } from '../util/observed-decorator'

interface APICreateManufacturerRequest {
    name: string
}

export class ManufacturerStore {
    public manufacturersById: Record<string, APIProductManufacturer> = {}
    public manufacturerIds: string[] = []
    public isFetched = false
    public isFetching = false
    public isUpdating = false

    constructor(private readonly stores: Stores) {
        makeAutoObservable(this)
    }

    @observed('isFetching')
    public async list() {
        try {
            const response = await visorPRORestClient.manufacturer.get()

            const manufacturers: Record<string, APIProductManufacturer> = {}
            const manufacturerIds: string[] = []

            response.items.forEach((manufacturer) => {
                manufacturers[manufacturer.id] = manufacturer
                manufacturerIds.push(manufacturer.id)
            })

            runInAction(() => {
                this.manufacturersById = manufacturers
                this.manufacturerIds = manufacturerIds
                this.isFetched = true
            })
        } catch (e) {
            toast.error(
                `Failed to fetch manufacturers: ${(e as Error).message}`,
            )
        }
    }

    @observed('isUpdating')
    public async create(input: APICreateManufacturerRequest) {
        try {
            const manufacturer =
                await visorPRORestClient.manufacturer.create(input)

            runInAction(() => {
                this.manufacturersById[manufacturer.id] = manufacturer
                this.manufacturerIds = [
                    manufacturer.id,
                    ...this.manufacturerIds,
                ]
            })

            return manufacturer.id
        } catch (e) {
            toast.error(
                `Failed to create manufacturer: ${(e as Error).message}`,
            )
        }
    }

    public addManufacturers(manufacturers: APIProductManufacturer[]) {
        const copy = {
            ...this.manufacturersById,
        }

        manufacturers.forEach((manufacturer) => {
            copy[manufacturer.id] = manufacturer
        })

        this.manufacturersById = copy
    }
}

export const useManufacturers = (stores: Stores) => {
    useEffect(() => {
        if (!stores.manufacturers.isFetched) {
            void stores.manufacturers.list()
        }
    }, [stores.manufacturers])

    return useMemo(() => {
        const manufacturers: APIProductManufacturer[] = Object.values(
            stores.manufacturers.manufacturersById,
        )
        manufacturers.sort((a, b) => a.name.localeCompare(b.name))
        return manufacturers
    }, [stores.manufacturers.manufacturersById])
}

export const useManufacturerById = (id: string | undefined, stores: Stores) => {
    useEffect(() => {
        if (!stores.manufacturers.isFetched) {
            void stores.manufacturers.list()
        }
    }, [stores.manufacturers])

    if (id) {
        return stores.manufacturers.manufacturersById[id]
    }
}
