import type {
    APIIssueInstance,
    APIRandomDocumentSection,
} from '@visorpro/client'
import type { APIGetRandomSectionsRequest } from '@visorpro/client/dist/RestClient/services'
import { makeAutoObservable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'

export class SectionPreviewStore {
    public didFetchSections = false
    public sectionsById: Record<string, APIRandomDocumentSection> = {}
    public sectionIds: string[] = []
    public sectionIndexUnderReview = 0

    constructor() {
        makeAutoObservable(this)
    }

    public get sectionUnderReview(): APIRandomDocumentSection | undefined {
        if (this.sectionIndexUnderReview >= this.sectionIds.length) {
            return undefined
        }

        return this.sectionsById[this.sectionIds[this.sectionIndexUnderReview]]
    }

    public visitNextSection() {
        runInAction(() => {
            this.sectionIndexUnderReview++
        })
    }

    public async getSections(request: APIGetRandomSectionsRequest) {
        try {
            const response =
                await visorPRORestClient.document.getRandomSections({
                    limit: 100,
                    ...request,
                })

            const sectionsById: Record<string, APIRandomDocumentSection> = {}
            const sectionIds: string[] = []

            response.items.forEach((section) => {
                sectionsById[section.id] = section
                sectionIds.push(section.id)
            })

            runInAction(() => {
                this.sectionsById = sectionsById
                this.sectionIds = sectionIds
                this.didFetchSections = true
            })
        } catch (e) {
            toast.error(
                `Failed to fetch document sections: ${(e as Error).message}`,
            )
        }
    }

    private _previousRequest: APIGetRandomSectionsRequest | undefined

    public async getSectionsIfNeeded(
        request: APIGetRandomSectionsRequest = {},
    ) {
        if (!this.didFetchSections || request !== this._previousRequest) {
            this._previousRequest = request
            await this.getSections(request)
        }
    }

    public addInstanceToSection = (
        instance: APIIssueInstance,
        section: APIRandomDocumentSection,
    ) => {
        if (!this.sectionsById[section.id]) {
            return
        }

        runInAction(() => {
            this.sectionsById = {
                ...this.sectionsById,
                [section.id]: {
                    ...section,
                    issue_instances: [...section.issue_instances, instance],
                },
            }
        })
    }

    public removeInstanceFromSection = (
        instanceId: string,
        sectionId: string,
    ) => {
        const section = this.sectionsById[sectionId]

        if (!section) {
            return
        }

        runInAction(() => {
            this.sectionsById = {
                ...this.sectionsById,
                [sectionId]: {
                    ...section,
                    issue_instances: section.issue_instances.filter(
                        (instance) => instance.id !== instanceId,
                    ),
                },
            }
        })
    }

    public getIssueInstance = (issueId: string, sectionId: string) => {
        const section = this.sectionsById[sectionId]

        if (!section) {
            return
        }

        return section.issue_instances.find(
            (instance) => instance.issue_id === issueId,
        )
    }
}
