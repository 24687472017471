import { useMemo } from 'react'
import { type PaginatedRequestQueryParams, type APIWorkOrder, type APIWorkOrderSegment } from '@visorpro/client'
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_Updater,
    type MRT_PaginationState,
} from 'material-react-table';
import _ from 'lodash'
import { useAdminMaterialReactTable } from './table/mui-table';
import { Chip } from '@mui/material';
import { Link } from 'react-router-dom';

export interface DocumentsTableProps {
    workOrders: APIWorkOrder[]
    isLoading?: boolean
    isSaving?: boolean
    rowCount?: number
    pagination?: PaginatedRequestQueryParams
    setPagination?: (pagination: PaginatedRequestQueryParams) => void
    isPrimaryBackgroundColor?: boolean
}

export const WorkOrdersTable = ({ workOrders: data, rowCount, pagination, setPagination, isLoading, isSaving, isPrimaryBackgroundColor }: DocumentsTableProps) => {
    const paginationState = useMemo(() => {
        if (pagination === undefined) return undefined

        return {
            pageIndex: (pagination.offset && pagination.limit) ? pagination.offset / pagination.limit : 0,
            pageSize: pagination.limit || 1000,
        }
    }, [pagination])

    const onPaginationChange = (pagination: MRT_Updater<MRT_PaginationState>) => {
        if (typeof pagination === 'function') {
            const newPagination = pagination(table.getState().pagination)
            setPagination?.({
                offset: newPagination.pageIndex * newPagination.pageSize,
                limit: newPagination.pageSize,
            })
        } else {
            setPagination?.({
                offset: pagination.pageIndex * pagination.pageSize,
                limit: pagination.pageSize,
            })
        }
    }

    const columns = useMemo<MRT_ColumnDef<APIWorkOrder>[]>(() => [
        {
            accessorKey: 'id',
            header: 'ID',
        },
        {
            accessorKey: 'title',
            header: 'Title',
        },
        {
            accessorKey: 'kind',
            header: 'Kind',
        },
        {
            accessorKey: 'organization_id',
            header: 'Organization',
            Cell: ({ cell }) => {
                const organizationId = cell.getValue<string>()
                return <Link className='text-link' to={`/organizations/${organizationId}`}>
                    <Chip label={organizationId} size="small" clickable />
                </Link>
            }
        },
        {
            accessorKey: 'segments',
            header: 'Segments',
            Cell: ({ cell }) => cell.getValue<APIWorkOrderSegment[]>().length
        },
    ], []);

    const table = useAdminMaterialReactTable({
        columns,
        data,
        rowCount,
        initialState: {
            columnVisibility: {
                sha512_hash: false,
                updated_at: false,
            },
            columnPinning: {
                left: ['id', 'title'],
            }
        },
        defaultColumn: {
            minSize: 80,
            size: 200,
            enableSorting: true,
        },
        enableSorting: false,
        enableRowSelection: false,
        getRowId: (doc) => doc.id,
        ...(setPagination && { manualPagination: true, onPaginationChange }),
        state: {
            isLoading,
            showProgressBars: isSaving,
            ...(pagination && paginationState && { pagination: paginationState }),
        },
        isPrimaryBackgroundColor,
    }, 'queue-tasks-table');

    return <MaterialReactTable table={table} />
}
