import type {
    APIDocumentToProductModel,
    APIProductCategory,
    APIProductManufacturer,
    APIProductModel,
} from '@visorpro/client'
import type {
    APICreateModelRequest,
    APIUpdateModelRequest,
} from '@visorpro/client/dist/RestClient/services/ModelService'
import { makeAutoObservable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { observed } from '../util/observed-decorator'
import { DictionaryArray } from '../types'

export type StoredModel = Omit<
    APIProductModel,
    'product_category' | 'product_manufacturer'
>

export class ModelsStore {
    public didFetchModels = false
    public modelsById: Record<string, StoredModel> = {}
    public modelIds: string[] = []
    public totalModels: number = 0
    public isFetching = false
    public isUpdating = false

    constructor(private readonly stores: Stores) {
        makeAutoObservable(this)
    }

    @observed('isFetching')
    public async list() {
        try {
            const response = await visorPRORestClient.model.get()

            const models: Record<string, StoredModel> = {}
            const modelIds: string[] = []
            const manufacturers: APIProductManufacturer[] = []
            const categories: APIProductCategory[] = []

            response.items.forEach((model) => {
                models[model.id] = model
                modelIds.push(model.id)
                manufacturers.push(model.product_manufacturer!)
                categories.push(model.product_category!)
            })

            runInAction(() => {
                this.modelsById = models
                this.modelIds = modelIds
                this.didFetchModels = true
                this.totalModels = response.total
                this.stores.manufacturers.addManufacturers(manufacturers)
                this.stores.categories.addCategories(categories)
            })
        } catch (e) {
            toast.error(`Failed to fetch models: ${(e as Error).message}`)
        }
    }

    @observed('isUpdating')
    public async create(input: APICreateModelRequest) {
        try {
            const model = await visorPRORestClient.model.create(input)

            runInAction(() => {
                this.modelsById[model.id] = model
                this.modelIds = [model.id, ...this.modelIds]
                this.stores.manufacturers.addManufacturers([
                    model.product_manufacturer!,
                ])
            })
        } catch (e) {
            toast.error(`Failed to create model: ${(e as Error).message}`)
        }
    }

    @observed('isUpdating')
    public async update(
        modelId: string,
        options: Partial<APIUpdateModelRequest>,
    ) {
        const model = this.modelsById[modelId]
        const response = await visorPRORestClient.model.update(modelId, {
            name: model.name,
            product_manufacturer_id: model.product_manufacturer_id,
            product_category_id: model.product_category_id,
            ...options,
        })

        runInAction(() => {
            this.modelsById[modelId] = response
        })
    }

    public addDocumentToProductModels(models: APIDocumentToProductModel[]) {
        const copy = {
            ...this.modelsById,
        }
        const manufacturers: APIProductManufacturer[] = []
        const categories: APIProductCategory[] = []

        models.forEach((model) => {
            copy[model.product_model.id] = model.product_model
            manufacturers.push(model.product_model.product_manufacturer!)
            categories.push(model.product_model.product_category!)
        })

        runInAction(() => {
            this.modelsById = copy
            this.stores.manufacturers.addManufacturers(manufacturers)
            this.stores.categories.addCategories(categories)
        })
    }

    @observed('isUpdating')
    public async merge(modelIds: string[]) {
        await visorPRORestClient.model.mergeModels(modelIds)
        await this.list()
        return true
    }

    @observed('isUpdating')
    public async delete(modelId: string) {
        await visorPRORestClient.model.deleteModel(modelId)

        runInAction(() => {
            delete this.modelsById[modelId]
            this.modelIds = this.modelIds.filter((id) => id !== modelId)
        })

        return true
    }
}

export const useModels = (stores: Stores) => {
    useEffect(() => {
        if (!stores.models.didFetchModels) {
            void stores.models.list()
        }
    }, [stores.models])

    return new DictionaryArray(stores.models.modelsById)
}

export const useModelsById = (
    stores: Stores,
    ids: Set<string>,
): StoredModel[] => {
    useEffect(() => {
        if (!stores.models.didFetchModels) {
            void stores.models.list()
        }
    }, [stores.models])

    return useMemo(() => {
        return Array.from(ids).map((id) => stores.models.modelsById[id])
    }, [ids, stores.models.modelsById])
}
