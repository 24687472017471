import type { APITestRun, APITestRunQuestion } from '@visorpro/client'
import { useMemo } from 'react'

export interface CompletablePercentage {
    percentage: number
    isComplete: boolean
}

export const useTestRunScore = (testRun: APITestRun): CompletablePercentage => {
    return useMemo(() => {
        let total = 0
        let success = 0
        let pending = 0

        testRun.test_run_questions?.forEach((question) => {
            question.acceptance_criteria.forEach((acceptanceCriteria) => {
                total += 1

                if (acceptanceCriteria.success) {
                    success += 1
                }

                if (acceptanceCriteria.success === null) {
                    pending += 1
                }
            })
        })

        return {
            percentage: Math.round((success / (total || 1)) * 100),
            isComplete: pending === 0,
        }
    }, [testRun.test_run_questions])
}

export const useTestRunQuestionScore = (
    testRunQuestion: APITestRunQuestion,
): CompletablePercentage => {
    return useMemo(() => {
        let total = 0
        let success = 0
        let pending = 0

        testRunQuestion.acceptance_criteria.forEach((item) => {
            total += 1

            if (item.success) {
                success += 1
            }

            if (item.success === null) {
                pending += 1
            }
        })

        return {
            percentage: Math.round((success / (total || 1)) * 100),
            isComplete: pending === 0,
        }
    }, [testRunQuestion.acceptance_criteria])
}
