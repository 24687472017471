import { type APIDataSet } from '@visorpro/client'
import type { APIGetRandomSectionsRequest } from '@visorpro/client/dist/RestClient/services'
import { DateTime } from 'luxon'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useModal } from '../modal'
import { Table } from '../table/table'
import { TableHeader } from '../table/table-header'
import { DataSetRow } from './data-set-row'
import {
    DataSetActionKind,
    DataSetActionsModal,
} from './data-set-actions-modal'
import { DataSetNameModal } from '../modals/updates/data-set-name'
import type { Stores } from '../../util/store'
import { observer } from 'mobx-react-lite'
import { DataSetOrganizationsModal } from './data-set-organizations-modal'
import { DataSetTableStore } from './data-set-table-store'
import { Checkbox } from '../checkbox/checkbox'
import { DataSetsBulkSelectModal } from './bulk-select-modal'
import { DataSetBulkOrganizationsModal } from './data-set-bulk-organizations-modal'

export interface DataSetsTableProps {
    stores: Stores
    dataSets: APIDataSet[]
    createLink(dataSet: APIDataSet): string
    onReviewStart?(request: APIGetRandomSectionsRequest): void
}

export const DataSetsTable: FC<DataSetsTableProps> = observer(
    ({ stores, dataSets, createLink, onReviewStart }) => {
        const now = DateTime.now()
        const dataSetsPopup = useModal<{
            dataSetId: string
            kind?: DataSetActionKind
        }>()
        const [dataSetsBulkIds, setDataSetsBulkIds] = useState<string[]>()

        const onShowMenu = useCallback(
            (e: React.MouseEvent, dataSet: APIDataSet) => {
                dataSetsPopup.open(e, { dataSetId: dataSet.id })
            },
            [dataSetsPopup],
        )

        const updateDataSet = useCallback(
            async (documentId: string, name: string) => {
                await stores.dataSets.update(documentId, name)
                dataSetsPopup.close()
            },
            [stores.dataSets, dataSetsPopup],
        )

        const popupDataSet = useMemo(() => {
            const id = dataSetsPopup.state?.data?.dataSetId

            if (id) {
                return stores.dataSets.dataSetsById[id]
            }
        }, [stores.dataSets, dataSetsPopup.state?.data?.dataSetId])

        const [store] = useState(() => new DataSetTableStore())

        const headerCheckboxValue = useMemo(() => {
            return dataSets.every(
                (dataSet) => store.selectedDataSetIds[dataSet.id],
            )
        }, [dataSets, store.selectedDataSetIds])

        const onHeaderChange = useCallback(() => {
            if (headerCheckboxValue) {
                store.clearSelection()
            } else {
                store.selectAll(dataSets)
            }
        }, [headerCheckboxValue, store, dataSets])

        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>
                                <Checkbox
                                    value={headerCheckboxValue}
                                    onChange={onHeaderChange}
                                />
                            </TableHeader>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Organizations</TableHeader>
                            <TableHeader>Documents</TableHeader>
                            <TableHeader>Created</TableHeader>
                            <TableHeader> </TableHeader>
                        </tr>
                    </thead>

                    <tbody>
                        {dataSets.map((dataSet) => {
                            return (
                                <DataSetRow
                                    key={dataSet.id}
                                    dataSetId={dataSet.id}
                                    createLink={createLink}
                                    now={now}
                                    onShowMenu={onShowMenu}
                                    stores={stores}
                                    tableStore={store}
                                />
                            )
                        })}
                    </tbody>
                </Table>

                {dataSetsPopup.state &&
                    dataSetsPopup.state?.data?.kind === undefined && (
                        <DataSetActionsModal
                            anchor={dataSetsPopup.state.anchor}
                            dataSet={popupDataSet!}
                            onClose={dataSetsPopup.close}
                            onAction={(dataSet, kind) => {
                                if (kind === DataSetActionKind.StartReview) {
                                    onReviewStart?.({
                                        data_set_ids: [dataSet.id],
                                    })
                                    dataSetsPopup.close()
                                } else {
                                    dataSetsPopup.update({
                                        dataSetId: dataSet.id,
                                        kind,
                                    })
                                }
                            }}
                        />
                    )}

                {dataSetsPopup.state?.data?.kind ===
                    DataSetActionKind.EditName && (
                        <DataSetNameModal
                            anchor={dataSetsPopup.state.anchor}
                            onClose={dataSetsPopup.close}
                            onSubmit={updateDataSet}
                            isLoading={stores.dataSets.isUpdating}
                            contentStyle={{ marginTop: 0, width: '500px' }}
                            dataSet={popupDataSet!}
                            placement="bottom-end"
                        />
                    )}

                {dataSetsPopup.state?.data?.kind ===
                    DataSetActionKind.ManageOrganizations && (
                        <DataSetOrganizationsModal
                            stores={stores}
                            dataSetId={popupDataSet!.id}
                            anchor={dataSetsPopup.state.anchor}
                            onClose={dataSetsPopup.close}
                        />
                    )}

                {dataSetsBulkIds && (
                    <DataSetBulkOrganizationsModal
                        stores={stores}
                        dataSetIds={dataSetsBulkIds}
                        onClose={() => setDataSetsBulkIds(undefined)}
                    />
                )}

                {!dataSetsBulkIds &&
                    Object.keys(store.selectedDataSetIds).length > 0 && (
                        <DataSetsBulkSelectModal
                            onManageOrganizationsPress={() =>
                                setDataSetsBulkIds(
                                    Object.keys(store.selectedDataSetIds),
                                )
                            }
                        />
                    )}
            </>
        )
    },
)
