import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import type { Stores } from '../../util/store'
import type { APIGetRandomSectionsRequest } from '@visorpro/client/dist/RestClient/services'
import { DocumentSectionPreview } from './section-preview'
import { DocumentsTable } from '../../components/documents-table';
import { useDocuments, type APIUpdateDocumentRequest } from '../../stores/documents-store'
import { useDataSets } from '../../stores/data-sets-store'
import { useModels } from '../../stores/models-store'
import { useDocumentUploads } from '../../stores/document-upload-store'

export interface ModelsPageProps {
    stores: Stores
}

export const DocumentsPage = observer(({ stores }: ModelsPageProps) => {
    const [pagination, setPagination] = useState({})
    const [filters, setFilters] = useState({})
    const [sorting, setSorting] = useState<string[]>([])

    const options = useMemo(() => {
        return {
            ...pagination,
            ...filters,
            sort_by: sorting,
        }
    }, [pagination, filters, sorting])

    const documents = useDocuments(stores.documents, options)
    const documentUploads = useDocumentUploads(stores)
    const dataSets = useDataSets(stores)
    const models = useModels(stores)

    const [documentSectionPreviewRequest, setDocumentSectionPreviewRequest] =
        useState<APIGetRandomSectionsRequest>()

    const setEnabled = useCallback((documentId: string, isEnabled: boolean) => {
        void stores.documents.setDocumentEnabled(documentId, isEnabled)
    }, [stores.documents])

    const updateDocument = useCallback((documentId: string, body: APIUpdateDocumentRequest) => {
        void stores.documents.update(documentId, body)
    }, [stores.documents])

    const setDataSets = useCallback(async (documentId: string, addedDatasetIds: string[], removedDataSetIds: string[]) => {
        await Promise.all([
            stores.documents.addDocumentToDataSets(documentId, addedDatasetIds),
            stores.documents.removeDocumentFromDataSets(documentId, removedDataSetIds),
        ])
    }, [stores.documents])

    const setModels = useCallback(async (documentId: string, addedModelIds: string[], removedModelIds: string[]) => {
        await Promise.all([
            stores.documents.addDocumentToModels(documentId, addedModelIds),
            stores.documents.removeDocumentFromModels(documentId, removedModelIds),
        ])
    }, [stores.documents])

    return (
        <>
            <DocumentsTable
                rowCount={stores.documents.totalDocuments}
                documents={documents}
                dataSets={dataSets}
                models={models}
                documentUploads={documentUploads}
                isLoading={stores.documents.isFetching}
                showProgressBars={stores.documents.isUpdating}
                isPrimaryBackgroundColor={true}
                pagination={pagination}
                setPagination={setPagination}
                filters={filters}
                setFilters={setFilters}
                sorting={sorting}
                setSorting={setSorting}
                updateDocumentEnabled={setEnabled}
                updateDocument={updateDocument}
                setDataSets={setDataSets}
                setModels={setModels}
            />

            {documentSectionPreviewRequest && (
                <DocumentSectionPreview
                    request={documentSectionPreviewRequest}
                    stores={stores}
                    onClose={() => {
                        setDocumentSectionPreviewRequest(undefined)
                    }}
                />
            )}
        </>
    )
})
