import type { FC } from 'react'
import { useCallback, useEffect, useRef } from 'react'
import { MdRemove } from 'react-icons/md'
import { observer } from 'mobx-react-lite'
import { FormTextArea } from '../modals/creation/form-text-area'
import type { PromptFormStore, PromptMessageInput } from './prompt-form-store'
import type { APIPromptMessageRole } from '@visorpro/client/dist/types/APIPromptMessage'

export interface PromptMessageItemProps {
    store: PromptFormStore
    item: PromptMessageInput
    onContentChange(key: string, content: string): void
    onRoleChange(key: string, role: APIPromptMessageRole): void
    onAdd(): void
    onRemove(item: PromptMessageInput): void
}

const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export enum MessageRole {
    SYSTEM = 'SYSTEM',
    USER = 'USER',
    ASSISTANT = 'ASSISTANT',
}

const roles: APIPromptMessageRole[] = [
    MessageRole.SYSTEM,
    MessageRole.USER,
    MessageRole.ASSISTANT,
]

export const PromptMessageItem: FC<PromptMessageItemProps> = observer(
    ({ store, item, onContentChange, onRoleChange, onAdd, onRemove }) => {
        const textAreaRef = useRef<HTMLTextAreaElement>(null)

        const onChange = useCallback(
            (text: string) => {
                onContentChange(item.key, text)
            },
            [item.key, onContentChange],
        )

        const _onRemove = useCallback(() => {
            onRemove(item)
        }, [item, onRemove])

        const onRoleClick = useCallback(() => {
            const next = roles[(roles.indexOf(item.role) + 1) % roles.length]
            onRoleChange(item.key, next)
        }, [onRoleChange, item.key, item.role])

        useEffect(() => {
            if (store.itemIsFocused(item.key)) {
                textAreaRef.current?.focus()
                store.promptMessagesStore.clearAutoFocusKey()
            }
        }, [store, item.key, store.promptMessagesStore])

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '0.5rem',
                }}>
                <span
                    style={{
                        borderRadius: '50%',
                        width: '5px',
                        height: '5px',
                        backgroundColor: 'var(--purple-color)',
                        flexShrink: 0,
                        // makes the bullet point be at the same level as first line of text
                        marginTop: '10px',
                    }}
                />

                <div style={{ width: '100%' }}>
                    <p
                        style={{
                            marginTop: '4px',
                            color: 'var(--purple-color)',
                            cursor: 'pointer',
                        }}
                        onClick={onRoleClick}>
                        {capitalize(item.role.toLowerCase())}
                    </p>

                    <FormTextArea
                        textAreaRef={textAreaRef}
                        value={item.content}
                        onChange={onChange}
                        onEnterPress={onAdd}
                        onDeleteWhileEmpty={_onRemove}
                        style={{
                            marginTop: '5px',
                        }}
                    />
                </div>

                <MdRemove
                    size={16}
                    color="var(--red-color)"
                    style={{
                        flexShrink: 0,
                        cursor: 'pointer',
                        marginTop: '5px',
                    }}
                    onClick={_onRemove}
                />
            </div>
        )
    },
)
