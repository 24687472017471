import { visorPRORestClient } from '../util/api'
import { useEffect, useMemo } from 'react'
import type { Stores } from '../util/store'
import { makeAutoObservable, runInAction } from 'mobx'

export class OrganizationDataSetsStore {
    public dataSetIdsByOrganizationId: Record<string, string[] | undefined> = {}

    constructor(private readonly stores: Stores) {
        makeAutoObservable(this)
    }

    public async getOrganizationDataSets(organizationId: string) {
        const response =
            await visorPRORestClient.organization.getDataSets(organizationId)

        runInAction(() => {
            this.stores.dataSets.addDataSets(response.items)
            this.dataSetIdsByOrganizationId[organizationId] =
                response.items.map((dataSet) => dataSet.id)
        })
    }

    public fetchOrganizationDataSetsIfNeeded(organizationId: string) {
        if (!this.dataSetIdsByOrganizationId[organizationId]) {
            void this.getOrganizationDataSets(organizationId)
        }
    }

    public addDataSetIdToOrganization(
        organizationId: string,
        dataSetId: string,
    ) {
        this.stores.organizationDataSets.dataSetIdsByOrganizationId[
            organizationId
        ] = [
            dataSetId,
            ...(this.stores.organizationDataSets.dataSetIdsByOrganizationId[
                organizationId
            ] || []),
        ]
    }

    public removeDataSetIdFromOrganization(
        organizationId: string,
        dataSetId: string,
    ) {
        this.stores.organizationDataSets.dataSetIdsByOrganizationId[
            organizationId
        ] = [
            ...(
                this.stores.organizationDataSets.dataSetIdsByOrganizationId[
                    organizationId
                ] || []
            ).filter((id) => id !== dataSetId),
        ]
    }
}

export const useOrganizationDataSets = (
    stores: Stores,
    organizationId: string,
) => {
    useEffect(() => {
        stores.organizationDataSets.fetchOrganizationDataSetsIfNeeded(
            organizationId,
        )
    }, [organizationId, stores])

    return (
        stores.organizationDataSets.dataSetIdsByOrganizationId[
            organizationId
        ]?.map((dataSetId) => stores.dataSets.dataSetsById[dataSetId]) ?? []
    )
}

export const useOrganizationDataSet = (
    stores: Stores,
    organizationId: string,
    dataSetId: string,
) => {
    const dataSets = useOrganizationDataSets(stores, organizationId)

    return useMemo(() => {
        return dataSets.find((dataSet) => dataSet.id === dataSetId)
    }, [dataSets, dataSetId])
}
