import type { APITestRun } from '@visorpro/client'
import { makeObservable, observable, runInAction } from 'mobx'

export class TestRunsTableStore {
    // mobx can be strange with sets
    public selectedTestRunIds: Record<string, true> = {}

    constructor() {
        makeObservable(this, {
            selectedTestRunIds: observable,
        })
    }

    public clearSelection = () => {
        runInAction(() => {
            this.selectedTestRunIds = {}
        })
    }

    public selectAll = (testRuns: APITestRun[]) => {
        runInAction(() => {
            const newSelection: Record<string, true> = {}

            for (const testRun of testRuns) {
                newSelection[testRun.id] = true
            }

            this.selectedTestRunIds = newSelection
        })
    }

    public setTestRunSelection = (testRunId: string, value: boolean) => {
        runInAction(() => {
            if (value) {
                this.selectedTestRunIds[testRunId] = true
            } else {
                delete this.selectedTestRunIds[testRunId]
            }
        })
    }
}
