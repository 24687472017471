import type { APIIssueInstance } from '@visorpro/client'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'
import { usePdfDocument } from '../../util/pdf'

interface IssueInstanceThumbnailsProps {
    instance: APIIssueInstance
}

export const IssueInstanceThumbnail: FC<IssueInstanceThumbnailsProps> = ({
    instance,
}) => {
    const doc = usePdfDocument(instance.url)

    const pageRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const element = pageRef.current
        const didCancel = {
            current: false,
        }

        if (!doc.pdfDoc || !element) return

        const renderPage = async (pageNumber: number) => {
            await doc.renderPage({
                element,
                pageNumber,
                pageWidth: element.clientWidth,
                didCancel,
            })
        }

        renderPage(1)

        return () => {
            element.innerHTML = ''
            didCancel.current = true
        }
    }, [doc, instance.page_number])

    return (
        <div style={{ width: '240px' }}>
            <div
                ref={pageRef}
                style={{
                    width: '100%',
                    height: '316px',
                    backgroundColor: 'var(--selection-color)',
                    overflow: 'hidden',
                }}
            />

            <p
                style={{
                    marginTop: '1rem',
                    color: 'var(--foreground-color)',
                    fontWeight: 'bold',
                }}>
                {instance.document.title}
            </p>

            <p
                style={{
                    marginTop: '1rem',
                    color: 'var(--comment-color)',
                }}>
                Page {instance.page_number}
            </p>
        </div>
    )
}
