import { CSSProperties, FC } from "react"

export interface FormSeparatorProps {
    style?: CSSProperties
}

export const FormSeparator: FC<FormSeparatorProps> = ({ style }) => {
    return (
        <hr
            style={{
                border: 'none',
                borderTop: '1px solid var(--background-color)',
                width: '100%',
                margin: '0.75rem 0',
                ...style,
            }}
        />
    )
}
