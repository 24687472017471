import React, { forwardRef } from 'react'
import type { CSSProperties } from 'react'
import './text-button.css'

export interface TextButtonProps {
    className?: string
    children: string | string[] | number
    onClick?(e: React.MouseEvent): void
    disabled?: boolean
    disabledContent?: string
    style?: CSSProperties
    kind?: 'danger' | 'primary' | 'active' | 'info' | 'comment'
    title?: string
}

export const TextButton = forwardRef(
    (
        {
            className,
            children,
            onClick,
            disabled,
            disabledContent,
            style,
            kind,
            title,
        }: TextButtonProps,
        ref,
    ) => {
        const disabledClass = disabled ? 'disabled' : ''
        const kindClass = kind ? `text-button__${kind}` : ''
        return (
            <button
                ref={ref as any}
                className={`text-button ${disabledClass} ${kindClass} ${className || ''}`}
                onClick={onClick}
                disabled={disabled}
                style={style}
                title={title}>
                {disabled && disabledContent ? disabledContent : children}
            </button>
        )
    },
)
