import type { FC } from 'react'
import { useCallback, useEffect, useRef } from 'react'
import type {
    AcceptanceCriteriaInput,
    QuestionFormStore,
} from './question-form-store'
import { MdRemove } from 'react-icons/md'
import { observer } from 'mobx-react-lite'
import { FormTextArea } from '../modals/creation/form-text-area'

export interface AcceptanceCriteriaListItemProps {
    store: QuestionFormStore
    item: AcceptanceCriteriaInput
    onTextChange(key: string, text: string): void
    onAdd(): void
    onRemove(item: AcceptanceCriteriaInput): void
}

export const AcceptanceCriteriaListItem: FC<AcceptanceCriteriaListItemProps> =
    observer(({ store, item, onTextChange, onAdd, onRemove }) => {
        const textAreaRef = useRef<HTMLTextAreaElement>(null)

        const onChange = useCallback(
            (text: string) => {
                onTextChange(item.key, text)
            },
            [item.key, onTextChange],
        )

        const _onRemove = useCallback(() => {
            onRemove(item)
        }, [item, onRemove])

        useEffect(() => {
            if (store.acceptanceCriteriaStore.autoFocusKey === item.key) {
                textAreaRef.current?.focus()
                store.acceptanceCriteriaStore.clearAutoFocusKey()
            }
        }, [store, item.key, store.acceptanceCriteriaStore.autoFocusKey])

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '0.5rem',
                }}>
                <span
                    style={{
                        borderRadius: '50%',
                        width: '5px',
                        height: '5px',
                        backgroundColor: 'var(--purple-color)',
                        flexShrink: 0,
                        // makes the bullet point be at the same level as first line of text
                        marginTop: '10px',
                    }}
                />

                <FormTextArea
                    textAreaRef={textAreaRef}
                    value={item.text}
                    onChange={onChange}
                    onEnterPress={onAdd}
                    onDeleteWhileEmpty={_onRemove}
                />

                <MdRemove
                    size={16}
                    color="var(--red-color)"
                    style={{
                        flexShrink: 0,
                        cursor: 'pointer',
                        marginTop: '5px',
                    }}
                    onClick={_onRemove}
                />
            </div>
        )
    })
