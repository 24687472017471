import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import * as pdfjs from 'pdfjs-dist'
import type { RefObject } from 'react'
import { toast } from 'react-toastify'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface RenderPageProps {
    element: HTMLDivElement
    pageNumber: number
    onPageClick?(page: number): void
    pageWidth?: number
    didCancel?: RefObject<boolean>
}

export const usePdfDocument = (url?: string | null) => {
    const lastUrl = useRef<string | null>(null)
    const [numPages, setNumPages] = useState<number>(0)
    const [pdfDoc, setPdfDoc] = useState<pdfjs.PDFDocumentProxy>()

    useEffect(() => {
        if (!url || lastUrl.current === url) return
        lastUrl.current = url

        const time = Date.now()

        if (pdfDoc) {
            pdfDoc.destroy()
            setNumPages(0)
            setPdfDoc(undefined)
        }

        pdfjs
            .getDocument(url)
            .promise.then((pdfDoc_) => {
                console.log('pdf loaded in', Date.now() - time, 'ms')
                setPdfDoc(pdfDoc_)
                setNumPages(pdfDoc_.numPages)
            })
            .catch((e) => {
                toast.error(
                    `Failed to load PDF: ${(e as Error).message.slice(0, 200)}`,
                )
            })

        return () => {
            pdfDoc?.destroy()
        }
    }, [pdfDoc, url])

    const renderPage = useCallback(
        async ({
            element,
            pageNumber,
            onPageClick,
            pageWidth = 200,
            didCancel,
        }: RenderPageProps) => {
            if (!pdfDoc || didCancel?.current) return

            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')!
            element.appendChild(canvas)

            canvas.addEventListener('click', () => {
                onPageClick?.(pageNumber)
            })

            try {
                await pdfDoc.getPage(pageNumber).then((page) => {
                    if (didCancel?.current) {
                        canvas.remove()
                        return
                    }

                    const viewport = page.getViewport({ scale: 1 })

                    const scale = pageWidth / viewport.width
                    const scaledViewport = page.getViewport({ scale })

                    canvas.width = scaledViewport.width
                    canvas.height = scaledViewport.height

                    return page.render({
                        canvasContext: context,
                        viewport: scaledViewport,
                    }).promise
                })
            } catch (e) {
                if ((e as any).name === 'RenderingCancelledException') {
                    canvas.remove()
                } else {
                    toast.error(
                        `Failed to render page ${pageNumber}: ${(e as Error).message}`,
                    )
                }
            }
        },
        [pdfDoc],
    )

    const result = useMemo(
        () => ({
            numPages,
            pdfDoc,
            renderPage,
        }),
        [numPages, pdfDoc, renderPage],
    )

    return result
}
