import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { PromptMessageItem } from './message-list-item'
import type { PromptFormStore, PromptMessageInput } from './prompt-form-store'

export interface MessageListProps {
    promptFormStore: PromptFormStore
    style?: React.CSSProperties
}

export const MessageList: FC<MessageListProps> = observer(
    ({ promptFormStore, style }) => {
        const onAdd = useCallback(() => {
            promptFormStore.promptMessagesStore.addEmptyItem()
        }, [promptFormStore])

        const onRemove = useCallback(
            (item: PromptMessageInput) => {
                promptFormStore.promptMessagesStore.removeItem(item.key)
            },
            [promptFormStore],
        )

        const items = useMemo(() => {
            return promptFormStore.promptMessagesStore.itemKeys.map(
                (key) => promptFormStore.promptMessagesStore.itemsByKey[key],
            )
        }, [
            promptFormStore.promptMessagesStore.itemKeys,
            promptFormStore.promptMessagesStore.itemsByKey,
        ])

        const content = useMemo(() => {
            return items.map((item) => (
                <PromptMessageItem
                    key={item.key}
                    item={item}
                    onContentChange={promptFormStore.setMessageContent}
                    onRoleChange={promptFormStore.setMessageRole}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    store={promptFormStore}
                />
            ))
        }, [items, onAdd, onRemove, promptFormStore])

        return (
            <div
                style={{
                    gap: '0.25rem',
                    display: 'flex',
                    flexDirection: 'column',
                    ...style,
                }}>
                {content}
            </div>
        )
    },
)
