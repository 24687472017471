import type { APIPrompt } from '@visorpro/client/dist/types/APIPrompt'
import type { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { MdMoreVert } from 'react-icons/md'
import type { Stores } from '../../util/store'
import { TableDateCell } from '../table/table-date-cell'
import { TableRow } from '../table/table-row'
import { TableTextCell } from '../table/table-text-cell'

export interface PromptRowProps {
    stores: Stores
    promptId: string
    now: DateTime
    onShowMenu(e: React.MouseEvent, prompt: APIPrompt): void
}

export const PromptRow: FC<PromptRowProps> = observer(
    ({ stores, promptId, now, onShowMenu }) => {
        const prompt = stores.prompts.promptsById[promptId]

        const onMoreClick = useCallback(
            (e: React.MouseEvent) => {
                onShowMenu(e, prompt)
            },
            [onShowMenu, prompt],
        )

        const content = useMemo(() => {
            return (
                prompt.messages[0]?.content
                    .replaceAll('\n', ' ')
                    .trim()
                    .slice(0, 100) ?? ''
            )
        }, [prompt.messages])

        return (
            <TableRow>
                <TableTextCell>{prompt.name}</TableTextCell>

                <TableTextCell>{content}</TableTextCell>

                <TableDateCell date={prompt.created_at} now={now} />

                <td className="table-cell">
                    <MdMoreVert
                        size={18}
                        onClick={onMoreClick}
                        className="dimmed-pressable-icon"
                    />
                </td>
            </TableRow>
        )
    },
)
