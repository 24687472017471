import type { APICreateCategoryRequest } from '@visorpro/client/dist/RestClient/services/CategoryService'
import type { APIProductCategory } from '@visorpro/client'
import { makeObservable, observable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { observed } from '../util/observed-decorator'

export class CategoriesStore {
    public categoriesById: Record<string, APIProductCategory | undefined> = {}
    public categoryIds: string[] = []
    public isFetched = false
    public isFetching = false
    public isUpdating = false

    constructor() {
        makeObservable(this, {
            categoriesById: observable,
            categoryIds: observable,
        })
    }

    @observed('isFetching')
    public async list() {
        try {
            const response = await visorPRORestClient.category.get()

            const categories: Record<string, APIProductCategory> = {}
            const categoryIds: string[] = []

            response.items.forEach((category) => {
                categories[category.id] = category
                categoryIds.push(category.id)
            })

            runInAction(() => {
                this.categoriesById = categories
                this.categoryIds = categoryIds
                this.isFetched = true
            })
        } catch (e) {
            toast.error(`Failed to fetch categories: ${(e as Error).message}`)
        }
    }

    @observed('isUpdating')
    public async create(input: APICreateCategoryRequest) {
        try {
            const category = await visorPRORestClient.category.create(input)

            runInAction(() => {
                this.categoriesById[category.id] = category
                this.categoryIds = [category.id, ...this.categoryIds]
            })

            return category.id
        } catch (e) {
            toast.error(`Failed to create category: ${(e as Error).message}`)
        }
    }

    public addCategories(categories: APIProductCategory[]) {
        const copy = {
            ...this.categoriesById,
        }

        categories.forEach((category) => {
            copy[category.id] = category
        })

        this.categoriesById = copy
    }
}

export const useCategories = (stores: Stores) => {
    useEffect(() => {
        if (!stores.categories.isFetched) {
            void stores.categories.list()
        }
    }, [stores.categories])

    return useMemo(() => {
        const categories: APIProductCategory[] = []

        stores.categories.categoryIds.forEach((id) => {
            const category = stores.categories.categoriesById[id]
            if (category) {
                categories.push(category)
            }
        })

        categories.sort((a, b) => a.name.localeCompare(b.name))

        return categories
    }, [stores.categories.categoryIds, stores.categories.categoriesById])
}

export const useCategoryById = (id: string | undefined, stores: Stores) => {
    useEffect(() => {
        if (!stores.categories.isFetched) {
            void stores.categories.list()
        }
    }, [stores.categories])

    if (id) {
        return stores.categories.categoriesById[id]
    }
}
