import type { APIOrganization, APIMedia } from '@visorpro/client'
import { makeAutoObservable, runInAction } from 'mobx'

import { apiClient, visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { useEffect, useMemo } from 'react'
import { observed } from '../util/observed-decorator'

export interface APICreateOrganizationRequest {
    name: string
}

export class OrganizationStore {
    public organizationsById: Record<string, APIOrganization> = {}
    public organizationIds: string[] = []
    public membersBeingAddedByOrganizationId: Record<string, Set<string>> = {}
    public membersBeingRemovedByOrganizationId: Record<string, Set<string>> = {}
    public isFetching = false
    public isUpdating = false

    constructor(private readonly stores: Stores) {
        makeAutoObservable(this)
    }

    @observed('isFetching')
    public async list() {
        const response = await visorPRORestClient.organization.list()
        const organizations = response.items

        const organizationsById: Record<string, APIOrganization> = {}
        const organizationIds: string[] = []

        organizations.forEach((organization) => {
            organizationsById[organization.id] = organization
            organizationIds.push(organization.id)
        })

        runInAction(() => {
            this.organizationsById = organizationsById
            this.organizationIds = organizationIds
        })
    }

    @observed('isUpdating')
    public async create({ name }: APICreateOrganizationRequest) {
        const organization = await visorPRORestClient.organization.create(name)

        runInAction(() => {
            this.organizationsById[organization.id] = organization
            this.organizationIds = [organization.id, ...this.organizationIds]
        })
    }

    public async uploadLogo(organizationId: string, file: File) {
        const formData = new FormData()
        formData.append('file', file)

        const url = `/organization/${organizationId}/logo`
        const response = await apiClient.post<APIMedia>(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        runInAction(() => {
            if (this.organizationsById[organizationId]) {
                this.organizationsById = {
                    ...this.organizationsById,
                    [organizationId]: {
                        ...this.organizationsById[organizationId],
                        logo: response.data,
                    },
                }
            }
        })
    }

    public async addOrganizationMember(organizationId: string, userId: string) {
        if (this.membersBeingAddedByOrganizationId[organizationId]?.has(userId))
            return

        if (!this.membersBeingAddedByOrganizationId[organizationId]) {
            this.membersBeingAddedByOrganizationId[organizationId] = new Set()
        }

        this.membersBeingAddedByOrganizationId[organizationId].add(userId)

        try {
            await visorPRORestClient.organization.createMembership(
                organizationId,
                userId,
            )
        } finally {
            runInAction(() => {
                const membersBeingAdded =
                    this.membersBeingAddedByOrganizationId[organizationId]

                if (membersBeingAdded) {
                    membersBeingAdded.delete(userId)
                }
            })
        }
    }

    public async removeOrganizationMember(
        organizationId: string,
        userId: string,
    ) {
        if (
            this.membersBeingRemovedByOrganizationId[organizationId]?.has(
                userId,
            )
        )
            return

        if (!this.membersBeingRemovedByOrganizationId[organizationId]) {
            this.membersBeingRemovedByOrganizationId[organizationId] = new Set()
        }

        this.membersBeingRemovedByOrganizationId[organizationId].add(userId)

        try {
            await visorPRORestClient.organization.deleteMembership(
                organizationId,
                userId,
            )
        } finally {
            runInAction(() => {
                const membersBeingRemoved =
                    this.membersBeingRemovedByOrganizationId[organizationId]

                if (membersBeingRemoved) {
                    membersBeingRemoved.delete(userId)
                }
            })
        }
    }
}

export const useOrganizations = (store: OrganizationStore) => {
    useEffect(() => {
        if (!store.organizationIds.length) {
            void store.list()
        }
    }, [store])

    return useMemo(() => {
        return store.organizationIds.map((id) => store.organizationsById[id])
    }, [store.organizationIds, store.organizationsById])
}

export const useOrganization = (stores: Stores, organizationId: string) => {
    useEffect(() => {
        if (!stores.organizations.organizationsById[organizationId]) {
            void stores.organizations.list()
        }
    }, [stores, organizationId])

    return stores.organizations.organizationsById[organizationId]
}
