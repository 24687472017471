import type { APIProductModel } from '@visorpro/client'
import { APIDocumentType } from '@visorpro/client'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import Papa from 'papaparse'
import { visorPRORestClient } from '../../../util/api'
import fuzzysort from 'fuzzysort'
import type { MetadataDocumentDiff } from './steps/metadata-diff-row'
import pMap from 'p-map'

export enum MetadataImportStep {
    MapColumns,
    PreviewModels,
    PreviewDocuments,
}

export const titlesByStep: Record<MetadataImportStep, string> = {
    [MetadataImportStep.MapColumns]: 'Step 1 / 3: Map csv columns',
    [MetadataImportStep.PreviewModels]: 'Step 2 / 3: Preview model changes',
    [MetadataImportStep.PreviewDocuments]:
        'Step 3 / 3: Preview document changes',
}

export interface ModelPreviewAlternative {
    model: APIProductModel
    similarityPercentage: number
}

export interface ModelPreview {
    originalName: string
    isIgnored: boolean
    selectedAlternativeModelId?: string
    newModelId?: string
    alternatives: ModelPreviewAlternative[]
    canBeCreated: boolean
}

interface MetadataRow {
    matchString: string
    title: string
    modelNames: string[]
    releaseDate?: Date
    type?: APIDocumentType
}

const cleanupString = (str: string) => {
    return (
        str
            // replace multiple space-like chars with one
            .replace(/\s+/g, ' ')
            .trim()
    )
}

export interface MetadataImportStoreOptions {
    dataSetId: string
    onClose(): void
}

export class MetadataImportStore {
    public currentStep = MetadataImportStep.MapColumns

    // mappings
    public matchColumnMapping?: string
    public descriptionMapping?: string
    public serialNumberMapping?: string
    public manualTypeMapping?: string
    public modelsMapping?: string
    public releaseDateMapping?: string

    public manufacturerId?: string
    public categoryId?: string

    public metadataRows: MetadataRow[] = []
    public documentDiffs: MetadataDocumentDiff[] = []
    public isLoadingDocumentsDiff = false
    public totalDocuments = 0

    // models
    public modelPreviewsByName?: Record<string, ModelPreview>

    private databaseModels: APIProductModel[] = []

    constructor(private readonly options: MetadataImportStoreOptions) {
        makeObservable(this, {
            currentStep: observable,
            matchColumnMapping: observable,
            descriptionMapping: observable,
            serialNumberMapping: observable,
            manualTypeMapping: observable,
            modelsMapping: observable,
            releaseDateMapping: observable,
            manufacturerId: observable,
            categoryId: observable,
            modelPreviewsByName: observable,
            modelsToCreateCount: computed,
            isLoadingDocumentsDiff: observable,
            documentDiffs: observable,
            metadataRows: observable,
            totalDocuments: observable,
            isSubmitting: observable,
            doneDocumentsCount: observable,
        })
    }

    public get modelsToCreateCount() {
        const values = Object.values(this.modelPreviewsByName ?? {})
        const modelsToCreate = values.filter(
            (model) => !model.isIgnored && !model.selectedAlternativeModelId,
        )
        return modelsToCreate.length
    }

    public setMatchColumnMapping = (value: string) => {
        runInAction(() => {
            this.matchColumnMapping = value
        })
    }

    public setDescriptionMapping = (value: string) => {
        runInAction(() => {
            this.descriptionMapping = value
        })
    }

    public setSerialNumberMapping = (value: string) => {
        runInAction(() => {
            this.serialNumberMapping = value
        })
    }

    public setManualTypeMapping = (value: string) => {
        runInAction(() => {
            this.manualTypeMapping = value
        })
    }

    public setModelsMapping = (value: string) => {
        runInAction(() => {
            this.modelsMapping = value
        })
    }

    public setReleaseDateMapping = (value: string) => {
        runInAction(() => {
            this.releaseDateMapping = value
        })
    }

    public setManufacturerId = (value: string) => {
        runInAction(() => {
            this.manufacturerId = value
        })
    }

    public setCategoryId = (value: string) => {
        runInAction(() => {
            this.categoryId = value
        })
    }

    public moveNext = async () => {
        switch (this.currentStep) {
            case MetadataImportStep.MapColumns:
                runInAction(() => {
                    this.currentStep = MetadataImportStep.PreviewModels
                })
                this.extractModelsFromCsv()
                break

            case MetadataImportStep.PreviewModels:
                runInAction(() => {
                    this.currentStep = MetadataImportStep.PreviewDocuments
                    this.didSubmit = false
                })
                await this.createModels()
                await this.getDocumentPreviews()
                break

            case MetadataImportStep.PreviewDocuments:
                if (this.didSubmit) {
                    this.options.onClose()
                } else {
                    await this.updateDocuments()
                }
                break
        }
    }

    public movePrevious = () => {
        runInAction(() => {
            switch (this.currentStep) {
                case MetadataImportStep.MapColumns:
                    break

                case MetadataImportStep.PreviewModels:
                    this.currentStep = MetadataImportStep.MapColumns
                    break

                case MetadataImportStep.PreviewDocuments:
                    this.currentStep = MetadataImportStep.PreviewModels
                    break
            }
        })
    }

    private async createModels() {
        if (!this.manufacturerId || !this.categoryId) {
            console.warn('Missing manufacturerId or categoryId')
            return
        }

        const modelsToCreate = Object.values(
            this.modelPreviewsByName ?? {},
        ).filter(
            (model) => !model.isIgnored && !model.selectedAlternativeModelId,
        )

        await pMap(
            modelsToCreate,
            async (model) => {
                try {
                    const response = await visorPRORestClient.model.create({
                        name: model.originalName,
                        product_manufacturer_id: this.manufacturerId!,
                        product_category_id: this.categoryId!,
                    })

                    runInAction(() => {
                        model.newModelId = response.id
                    })
                } catch (e) {
                    console.error(
                        'Failed to create model',
                        model.originalName,
                        e,
                    )
                }
            },
            { concurrency: 5 },
        )
    }

    public get mapColumnsStepIsValid() {
        return (
            !!this.matchColumnMapping &&
            !!this.descriptionMapping &&
            !!this.manualTypeMapping &&
            !!this.modelsMapping &&
            !!this.manufacturerId &&
            !!this.categoryId
        )
    }

    private file?: File

    // 1. initial setup
    public setFile = (file: File) => {
        this.file = file

        Papa.parse(file, {
            complete: (result) => {
                result.meta.fields?.forEach((field) => {
                    switch (field.toLowerCase()) {
                        case 'item number':
                            this.setMatchColumnMapping(field)
                            break

                        case 'description':
                            this.setDescriptionMapping(field)
                            break

                        case 'p.i.n.':
                            this.setSerialNumberMapping(field)
                            break

                        case 'type':
                            this.setManualTypeMapping(field)
                            break

                        case 'model':
                            this.setModelsMapping(field)
                            break

                        case 'date added':
                            this.setReleaseDateMapping(field)
                            break
                    }
                })
            },
            header: true,
        })
    }

    // 2. get models and alternatives
    private extractModelsFromCsv = () => {
        const { file, modelsMapping } = this

        if (!file || !modelsMapping) {
            return
        }

        Papa.parse(file, {
            complete: async (result) => {
                if (!this.databaseModels?.length) {
                    const response = await visorPRORestClient.model.get()
                    this.databaseModels = response.items
                }

                const metadataRows: MetadataRow[] = []
                const modelsSet = new Set<string>()

                result.data.forEach((row: any) => {
                    const parsedRow = this.parseRow(row)

                    if (parsedRow) {
                        metadataRows.push(parsedRow)
                        parsedRow.modelNames.forEach((model) => {
                            modelsSet.add(model)
                        })
                    }
                })

                runInAction(() => {
                    const models = Array.from(modelsSet).map<ModelPreview>(
                        (model) => ({
                            originalName: model,
                            isIgnored: false,
                            alternatives: [],
                            canBeCreated: true,
                        }),
                    )

                    models.forEach((model) => {
                        const sortedModels = fuzzysort.go(
                            model.originalName,
                            this.databaseModels,
                            {
                                keys: ['name'],
                                limit: 5,
                                threshold: 0.5,
                            },
                        )

                        sortedModels.forEach((result) => {
                            if (
                                result.score === 1 &&
                                result.obj.product_manufacturer_id ===
                                    this.manufacturerId
                            ) {
                                model.selectedAlternativeModelId = result.obj.id
                                // a model with this manufacturer and name already exists.
                                // the backend will throw an error if we try to create it again.
                                model.canBeCreated = false
                            }

                            model.alternatives.push({
                                model: result.obj,
                                similarityPercentage: Math.floor(
                                    result.score * 100,
                                ),
                            })
                        })

                        model.alternatives.sort(
                            (a, b) =>
                                b.similarityPercentage - a.similarityPercentage,
                        )
                    })

                    const modelsByName: Record<string, ModelPreview> = {}

                    models.forEach((model) => {
                        modelsByName[model.originalName] = model
                    })

                    this.metadataRows = metadataRows
                    this.modelPreviewsByName = modelsByName
                })
            },
            header: true,
        })
    }

    private parseRow = (row: any): MetadataRow | undefined => {
        // 1. validate
        const requiredValues = [
            this.matchColumnMapping,
            this.descriptionMapping,
            this.manualTypeMapping,
            this.modelsMapping,
        ]

        for (const value of requiredValues) {
            if (!value || !row[value]) {
                console.warn('Missing required value', value, row)
                return
            }
        }

        // 2. assemble
        let releaseDate: Date | undefined

        if (this.releaseDateMapping) {
            const date = cleanupString(row[this.releaseDateMapping])

            if (date) {
                releaseDate = new Date(date)
            }
        }

        let title = cleanupString(row[this.descriptionMapping!])

        if (this.serialNumberMapping) {
            const pin = cleanupString(row[this.serialNumberMapping])

            if (pin) {
                title = title + ' ' + pin
            }
        }

        const typeString = cleanupString(row[this.manualTypeMapping!])
        let type: APIDocumentType = APIDocumentType.OTHER
        if (typeString.toLowerCase().startsWith('service')) {
            type = APIDocumentType.SERVICE_MANUAL
        } else if (typeString.toLowerCase().startsWith('operator')) {
            type = APIDocumentType.OPERATORS_MANUAL
        }

        return {
            matchString: cleanupString(row[this.matchColumnMapping!]),
            title,
            modelNames: cleanupString(row[this.modelsMapping!])
                .split(',')
                .map((item) => item.trim()),
            releaseDate,
            type,
        }
    }

    // 3. get document previews
    public getDocumentPreviews = async () => {
        if (!this.file || this.isLoadingDocumentsDiff) {
            return
        }

        runInAction(() => {
            this.documentDiffs = []
            this.totalDocuments = 0
            this.isLoadingDocumentsDiff = true
        })

        try {
            // 1. get all documents in data set
            const { dataSetId } = this.options
            const response =
                await visorPRORestClient.dataSet.getDocuments(dataSetId)
            runInAction(() => {
                this.totalDocuments = response.total
            })

            // 2. calculate diff
            const diffs: MetadataDocumentDiff[] = []

            response.items.forEach((document) => {
                const row = this.metadataRows.find((row) =>
                    document.original_filename.includes(row.matchString),
                )

                if (row) {
                    let hasChanges = false
                    const result: MetadataDocumentDiff = {
                        documentId: document.id,
                        rowNumber: diffs.length + 1,
                        matchString: row.matchString,
                        originalFileName: document.original_filename,
                    }

                    // 1. title
                    if (row.title !== document.title) {
                        hasChanges = true
                        result.title = {
                            current: document.title,
                            proposed: row.title,
                        }
                    }

                    // 2. todo: add support for release date

                    // 3. models
                    if (row.modelNames.length) {
                        const newModelSet = new Set(row.modelNames)
                        const added = Array.from(newModelSet).filter(
                            (model) => {
                                if (
                                    this.modelPreviewsByName?.[model]?.isIgnored
                                ) {
                                    return false
                                }
                                return !document.document_to_product_model?.some(
                                    (d2m) => {
                                        return (
                                            d2m.product_model
                                                ?.product_manufacturer_id ===
                                                this.manufacturerId &&
                                            d2m.product_model
                                                ?.product_category_id ===
                                                this.categoryId &&
                                            d2m.product_model?.name === model
                                        )
                                    },
                                )
                            },
                        )

                        if (added.length) {
                            hasChanges = true
                            result.models = {
                                added: added,
                            }
                        }
                    }

                    // 4. type
                    if (row.type !== document.type) {
                        hasChanges = true
                        result.type = row.type
                    }

                    if (hasChanges) {
                        diffs.push(result)
                    }
                }
            })

            runInAction(() => {
                this.documentDiffs = diffs
            })
        } finally {
            runInAction(() => {
                this.isLoadingDocumentsDiff = false
            })
        }
    }

    // 4. submit
    public isSubmitting = false
    public didSubmit = false
    public doneDocumentsCount = 0

    public updateDocuments = async () => {
        if (this.isSubmitting) {
            return
        }

        runInAction(() => {
            this.isSubmitting = true
            this.didSubmit = false
            this.doneDocumentsCount = 0
        })

        runInAction(() => {
            this.documentDiffs.forEach((diff) => {
                diff.statusLabel = 'Pending'
                diff.statusColor = 'var(--cyan-color)'
            })
        })

        try {
            await pMap(
                this.documentDiffs,
                async (diff) => {
                    runInAction(() => {
                        diff.statusLabel = 'Updating title'
                        diff.statusColor = 'var(--green-color)'
                    })

                    if (diff.title) {
                        await visorPRORestClient.document.update(
                            diff.documentId,
                            {
                                title: diff.title.proposed,
                            },
                        )
                    }

                    if (diff.type) {
                        await visorPRORestClient.document.update(
                            diff.documentId,
                            {
                                type: diff.type,
                            },
                        )
                    }

                    runInAction(() => {
                        diff.statusLabel = 'Adding models'
                    })

                    for (const modelName of diff.models?.added ?? []) {
                        const model = this.modelPreviewsByName?.[modelName]

                        if (model) {
                            await visorPRORestClient.model.addDocumentsToProductModel(
                                model.selectedAlternativeModelId ??
                                    model.newModelId!,
                                [diff.documentId],
                            )
                        }
                    }

                    runInAction(() => {
                        diff.statusLabel = 'Done'
                        diff.statusColor = 'var(--comment-color)'
                        this.doneDocumentsCount += 1
                    })
                },
                { concurrency: 5 },
            )

            runInAction(() => {
                this.didSubmit = true
            })
        } finally {
            runInAction(() => {
                this.isSubmitting = false
            })
        }
    }

    public selectAlternative = (
        modelName: string,
        alternativeModelId: string | undefined,
    ) => {
        runInAction(() => {
            const model = this.modelPreviewsByName?.[modelName]

            if (model) {
                model.selectedAlternativeModelId = alternativeModelId
            }

            this.modelPreviewsByName = { ...this.modelPreviewsByName }
        })
    }

    public ignoreAlternative = (modelName: string) => {
        runInAction(() => {
            const model = this.modelPreviewsByName?.[modelName]

            if (model) {
                model.isIgnored = !model.isIgnored
            }

            this.modelPreviewsByName = { ...this.modelPreviewsByName }
        })
    }
}
