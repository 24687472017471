import type { CSSProperties, FC } from 'react'
import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import type { NavLinkFnProps } from '../root'

export enum DocumentNavBarItem {
    Info,
    Questions,
    TestRuns,
    Versions,
    QueueTasks,
}

const nameByItem: Record<DocumentNavBarItem, string> = {
    [DocumentNavBarItem.Info]: 'Info',
    [DocumentNavBarItem.Questions]: 'Questions',
    [DocumentNavBarItem.TestRuns]: 'Test runs',
    [DocumentNavBarItem.Versions]: 'Versions',
    [DocumentNavBarItem.QueueTasks]: 'Queue tasks',
}

const routeByItem: Record<DocumentNavBarItem, string> = {
    [DocumentNavBarItem.Info]: 'info',
    [DocumentNavBarItem.Questions]: 'questions',
    [DocumentNavBarItem.TestRuns]: 'test-runs',
    [DocumentNavBarItem.Versions]: 'versions',
    [DocumentNavBarItem.QueueTasks]: 'tasks',
}

export interface NavBarProps {
    style?: CSSProperties
}

export const DocumentNavBar: FC<NavBarProps> = ({ style }) => {
    const items = [
        DocumentNavBarItem.Info,
        DocumentNavBarItem.Questions,
        DocumentNavBarItem.TestRuns,
        DocumentNavBarItem.Versions,
        DocumentNavBarItem.QueueTasks,
    ]

    const itemClassName = useCallback(({ isActive }: NavLinkFnProps) => {
        return isActive ? 'active' : ''
    }, [])

    return (
        <div className="navbar" style={style}>
            {items.map((item) => (
                <span key={item}>
                    <NavLink to={routeByItem[item]} className={itemClassName}>
                        {nameByItem[item]}
                    </NavLink>
                </span>
            ))}
        </div>
    )
}
