import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useMemo, useState } from 'react'
import fuzzysort from 'fuzzysort'
import { Modal } from '../../components/modal'
import type { Stores } from '../../util/store'
import { FormTextInput } from '../../components/modals/creation/form-text-input'
import { useIssues } from '../../stores/issues-store'
import './issue-selection-modal.css'
import { MdCheck } from 'react-icons/md'

export interface OrganizationsSearchModalProps {
    anchor: HTMLElement
    onClose(): void
    onSelect(issueId: string): void
    stores: Stores
    selectedIssueIds: Set<string>
}

export const IssueSelectionModal: FC<OrganizationsSearchModalProps> = observer(
    ({ anchor, onClose, stores, onSelect, selectedIssueIds }) => {
        const [query, setQuery] = useState('')
        const issues = useIssues(stores)
        const results = useMemo(() => {
            return fuzzysort.go(query, issues, {
                keys: ['name'],
                all: true,
            })
        }, [query, issues])

        return (
            <Modal
                onClose={onClose}
                style={{
                    width: '100%',
                    maxWidth: '800px',
                }}
                anchor={anchor}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        const issueId =
                            void stores.issues.createIssue(query)

                        if (issueId) {
                            onSelect(issueId)
                        }
                    }}>
                    <FormTextInput
                        value={query}
                        onChange={setQuery}
                        placeholder="Search or create issue"
                        style={{
                            padding: '0 1rem 0.25rem',
                        }}
                    />
                </form>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        maxHeight: '400px',
                        paddingBottom: '0.75rem',
                        boxSizing: 'border-box',
                    }}>
                    {results.map((result) => {
                        return (
                            <div
                                key={result.obj.id}
                                className="issue-selection-modal-item"
                                onClick={() => onSelect?.(result.obj.id)}>
                                <div>
                                    <span className="color-dimmed">
                                        {result.obj.kind ?? 'None'}
                                    </span>

                                    <span
                                        className="color-foreground"
                                        style={{ marginLeft: '1rem' }}>
                                        {query.length === 0 && result.obj.name}

                                        {query.length > 0 &&
                                            result[0].highlight(
                                                (m, i) => (
                                                    <span
                                                        key={i}
                                                        style={{
                                                            color: 'var(--purple-color)',
                                                        }}>
                                                        {m}
                                                    </span>
                                                ),
                                            )}
                                    </span>
                                </div>

                                <MdCheck
                                    size={16}
                                    color="var(--green-color)"
                                    style={{
                                        flexShrink: 0,
                                        // this prevents the layout from changing when selecting / unselecting items
                                        opacity: selectedIssueIds.has(
                                            result.obj.id,
                                        )
                                            ? 1
                                            : 0,
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            </Modal>
        )
    },
)
