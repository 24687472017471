import fuzzysort from 'fuzzysort'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useMemo, useState } from 'react'
import { MdCheck } from 'react-icons/md'
import { useUsers } from '../../../stores/user-store'
import { formatUserName } from '../../../util/format'
import type { Stores } from '../../../util/store'
import { Modal } from '../../modal'
import { FormTextInput } from '../creation/form-text-input'
import './members.css'

export interface MembersSearchModalProps {
    stores: Stores
    anchor: HTMLElement
    onClose(): void
    onSelect(userId: string): void
    activeUserIds?: Set<string>
}

export const MembersSearchModal: FC<MembersSearchModalProps> = observer(
    ({ stores, anchor, onClose, onSelect, activeUserIds }) => {
        const [query, setQuery] = useState('')

        const users = useUsers(stores.users)

        const usersForSearch = useMemo(() => {
            return users.map((user) => ({
                id: user.id,
                name: formatUserName(user) ?? '<no name>',
            }))
        }, [users])

        const results = useMemo(() => {
            return fuzzysort.go(query, usersForSearch, {
                keys: ['name'],
                all: true,
            })
        }, [query, usersForSearch])

        return (
            <Modal onClose={onClose} anchor={anchor}>
                <FormTextInput
                    value={query}
                    onChange={setQuery}
                    placeholder="Search user"
                    style={{
                        padding: '0 1rem 0.25rem',
                    }}
                />

                {stores.users.isFetching && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0.25rem 1rem',
                        }}>
                        <span className="color-dimmed">Loading...</span>
                    </div>
                )}

                {!stores.users.isFetching && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'auto',
                            maxHeight: '400px',
                            paddingBottom: '0.75rem',
                        }}>
                        {results.map((result) => {
                            return (
                                <div
                                    key={result.obj.id}
                                    className="member-item"
                                    onClick={() => onSelect?.(result.obj.id)}>
                                    <span
                                        className="color-foreground"
                                        style={{
                                            paddingRight: '0.5rem',
                                        }}>
                                        {query.length === 0 && result.obj.name}

                                        {query.length > 0 &&
                                            result[0].highlight(
                                                (m, i) => (
                                                    <span
                                                        key={i}
                                                        style={{
                                                            color: 'var(--purple-color)',
                                                        }}>
                                                        {m}
                                                    </span>
                                                ),
                                            )}
                                    </span>

                                    <MdCheck
                                        size={16}
                                        color="var(--green-color)"
                                        style={{
                                            flexShrink: 0,
                                            // this prevents the layout from changing when selecting / unselecting items
                                            opacity: activeUserIds?.has(
                                                result.obj.id,
                                            )
                                                ? 1
                                                : 0,
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )}
            </Modal>
        )
    },
)
