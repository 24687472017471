import type { CSSProperties, FC, RefObject } from 'react'
import { NavLink } from 'react-router-dom'
import { TableTextCell } from './table/table-text-cell'
import { TableButtonCell } from './table/table-button-cell'

export interface TextListItem {
    id: string
    name: string
}

export interface TextListProps {
    visibleItems?: number
    items: TextListItem[]
    createLink?(item: TextListItem): string
    onClick?(e: React.MouseEvent): void
    containerRef?: RefObject<HTMLTableCellElement>
}

export const TableTextList: FC<TextListProps> = ({
    visibleItems = 1,
    items,
    createLink,
    onClick,
    containerRef,
}) => {
    if (!items.length) {
        return (
            <TableTextCell
                containerRef={containerRef}
                style={{ color: 'var(--comment-color)' }}
                onClick={onClick}>
                None
            </TableTextCell>
        )
    }

    const content: Array<JSX.Element> = []

    const tagStyle: CSSProperties = {
        whiteSpace: 'nowrap',
    }

    for (let i = 0; i < visibleItems && i < items.length; i++) {
        const item = items[i]
        const suffix =
            i === visibleItems - 1 || i === items.length - 1 ? '' : ', '
        const link = createLink?.(item)

        if (link) {
            content.push(
                <NavLink
                    key={item.id}
                    to={link}
                    className="text-link"
                    style={tagStyle}>
                    {item.name}
                    {suffix}
                </NavLink>,
            )
        } else if (onClick) {
            content.push(
                <TableButtonCell
                    key={item.id}
                    style={tagStyle}
                    onClick={onClick}>
                    {item.name}
                    {suffix}
                </TableButtonCell>,
            )
        } else {
            content.push(
                <span
                    key={item.id}
                    style={{ color: 'var(--foreground-color)', ...tagStyle }}>
                    {item.name}
                    {suffix}
                </span>,
            )
        }
    }

    if (items.length > visibleItems) {
        const othersString = items
            .map((item) => item.name)
            .slice(visibleItems)
            .join(', ')

        content.push(
            <span
                key="other-items"
                style={{
                    color: 'var(--comment-color)',
                    marginLeft: '0.25rem',
                    fontSize: '0.825rem',
                    transform: 'translateY(-0.25rem)',
                }}
                title={othersString}>
                +{items.length - visibleItems}
            </span>,
        )
    }

    return (
        <TableTextCell
            containerRef={containerRef}
            style={{ display: 'inline-flex', flexWrap: 'wrap', gap: '0.25rem' }}
            onClick={onClick}>
            {content}
        </TableTextCell>
    )
}
