import { type Dictionary } from 'lodash'

export class DictionaryArray<T> extends Array<T> {
    private dictionary: Dictionary<T>

    public constructor(dictionary: Dictionary<T>)
    public constructor(array: T[], key: string)
    public constructor(data: Dictionary<T> | T[], key?: keyof T & string) {
        if (Array.isArray(data) && key) {
            super(...data)

            this.dictionary = data.reduce((acc, item) => {
                const recordKey = item[key] as string
                acc[recordKey] = item
                return acc
            }, {} as Dictionary<T>)
        } else if (
            typeof data === 'object' &&
            !Array.isArray(data) &&
            key === undefined
        ) {
            super(...Object.values(data))
            this.dictionary = data
        } else {
            throw new Error('Invalid constructor arguments')
        }
    }

    public static get [Symbol.species]() {
        return Array
    }

    public get [Symbol.toStringTag]() {
        return 'DictionaryArray'
    }

    public get(key: string): T {
        return this.dictionary[key]
    }
}
