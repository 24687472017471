import type { APIPrompt } from '@visorpro/client/dist/types/APIPrompt'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { useModal } from '../../components/modal'
import { PromptForm } from '../../components/prompt-form/prompt-form'
import { PromptsTable } from '../../components/prompts-table/prompts-table'
import { TextButton } from '../../components/text-button'
import { usePrompts } from '../../stores/prompts-store'
import type { Stores } from '../../util/store'
import { PromptActionKind, PromptActionsModal } from './prompts-action-modal'

export interface PromptsListPageProps {
    stores: Stores
}

interface PromptPopupContent {
    promptId: string
    kind?: PromptActionKind
}

interface PromptFormState {
    is_visible: boolean
    promptId?: string
}

export const PromptsListPage = observer(({ stores }: PromptsListPageProps) => {
    const prompts = usePrompts(stores)
    const [promptForm, setPromptForm] = useState<PromptFormState>({
        is_visible: false,
    })

    const [now] = useState(() => DateTime.local())

    const promptPopup = useModal<PromptPopupContent>()

    const onShowMenu = useCallback(
        (e: React.MouseEvent, prompt: APIPrompt) => {
            promptPopup.open(e, { promptId: prompt.id })
        },
        [promptPopup],
    )

    const onCreatePromptClick = useCallback(() => {
        setPromptForm({
            is_visible: true,
        })
    }, [])

    return (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
            }}>
            <div
                style={{
                    width: promptForm.is_visible ? '50%' : '100%',
                    boxSizing: 'border-box',
                }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '1rem 1rem 0',
                    }}>
                    <BreadCrumbs name="Prompts" path={[]} />

                    <TextButton onClick={onCreatePromptClick}>
                        Create prompt
                    </TextButton>
                </div>

                <div
                    style={{
                        width: '100%',
                        overflow: 'auto',
                        boxSizing: 'border-box',
                        paddingLeft: '1rem',
                    }}>
                    <PromptsTable
                        now={now}
                        onShowMenu={onShowMenu}
                        stores={stores}
                        prompts={prompts}
                    />
                </div>
            </div>

            {promptForm.is_visible && (
                <PromptForm
                    onClose={() => setPromptForm({ is_visible: false })}
                    promptId={promptForm.promptId}
                    stores={stores}
                    style={{
                        width: '50%',
                        height: '100vh',
                        boxSizing: 'border-box',
                    }}
                />
            )}

            {promptPopup.state &&
                promptPopup.state?.data?.kind === undefined && (
                    <PromptActionsModal
                        anchor={promptPopup.state.anchor}
                        promptId={promptPopup.state.data!.promptId}
                        onClose={promptPopup.close}
                        onAction={(promptId, kind) => {
                            if (kind === PromptActionKind.Delete) {
                                stores.prompts.delete(promptId)
                                promptPopup.close()
                            } else {
                                setPromptForm({
                                    is_visible: true,
                                    promptId,
                                })
                                promptPopup.close()
                            }
                        }}
                    />
                )}
        </div>
    )
})
