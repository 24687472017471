import { useCallback, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { type PaginatedRequestQueryParams } from '@visorpro/client'

import { DocumentsTable } from '../../../../components/documents-table'
import type { Stores } from '../../../../util/store'
import { useDataSets } from '../../../../stores/data-sets-store'
import { useModels } from '../../../../stores/models-store'
import { useDocuments, type APIUpdateDocumentRequest } from '../../../../stores/documents-store'

export interface DataSetDocumentsProps {
    stores: Stores
}

export const DataSetDocuments = observer(({ stores }: DataSetDocumentsProps) => {
    const { dataSetId } = useParams()
    if (!dataSetId) return null

    const [pagination, setPagination] = useState<PaginatedRequestQueryParams>({
        offset: 0, limit: 1000,
    })
    const [filters, setFilters] = useState({})
    const [sorting, setSorting] = useState<string[]>([])

    const options = useMemo(() => {
        return {
            ...pagination,
            ...filters,
            sort_by: sorting,
            data_set_ids: [dataSetId],
        }
    }, [pagination, filters, sorting, dataSetId])

    const dataSets = useDataSets(stores)
    const models = useModels(stores)
    const documentsStore = useMemo(() => stores.dataSets.documentsStoresById[dataSetId], [stores, dataSetId])
    const documents = useDocuments(documentsStore, options)

    const setEnabled = useCallback((documentId: string, isEnabled: boolean) => {
        void documentsStore.setDocumentEnabled(documentId, isEnabled)
    }, [documentsStore])

    const updateDocument = useCallback((documentId: string, body: APIUpdateDocumentRequest) => {
        void documentsStore.update(documentId, body)
    }, [documentsStore])

    const setModels = useCallback(async (documentId: string, addedModelIds: string[], removedModelIds: string[]) => {
        await Promise.all([
            documentsStore.addDocumentToModels(documentId, addedModelIds),
            documentsStore.removeDocumentFromModels(documentId, removedModelIds),
        ])
    }, [documentsStore])

    const setDataSets = useCallback(async (documentId: string, addedDataSetIds: string[], removedDataSetIds: string[]) => {
        await Promise.all([
            documentsStore.removeDocumentFromDataSets(documentId, removedDataSetIds),
            documentsStore.addDocumentToDataSets(documentId, addedDataSetIds),
        ])
    }, [documentsStore])

    return (
        <DocumentsTable
            documents={documents}
            dataSets={dataSets}
            models={models}
            documentUploads={stores.documentUploads.getDataSetUploads(dataSetId)}
            isLoading={documentsStore.isFetching}
            showProgressBars={documentsStore.isUpdating}
            rowCount={documentsStore.totalDocuments}
            updateDocumentEnabled={setEnabled}
            updateDocument={updateDocument}
            setModels={setModels}
            setDataSets={setDataSets}
            pagination={pagination}
            setPagination={setPagination}
            filters={filters}
            setFilters={setFilters}
            sorting={sorting}
            setSorting={setSorting}
        />
    )
},
)
