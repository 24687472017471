import type {
    APIIssue,
    APIIssueInstance,
    APIRandomDocumentSection,
} from '@visorpro/client'
import { makeAutoObservable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'

export class IssuesStore {
    public didFetchIssues = false
    public issuesById: Record<string, APIIssue> = {}
    public issueIds: string[] = []
    public instancesByIssueId: Record<string, APIIssueInstance[]> = {}
    public instanceIds: string[] = []

    constructor(private readonly stores: Stores) {
        makeAutoObservable(this)
    }

    public async getIssues() {
        try {
            const response = await visorPRORestClient.issue.getIssues({
                limit: 100,
            })

            const issuesById: Record<string, APIIssue> = {}
            const issueIds: string[] = []

            response.items.forEach((item) => {
                issuesById[item.id] = item
                issueIds.push(item.id)
            })

            runInAction(() => {
                this.didFetchIssues = true
                this.issuesById = issuesById
                this.issueIds = issueIds
            })
        } catch (e) {
            toast.error(`Failed to fetch issues: ${(e as Error).message}`)
        }
    }

    public async getInstances(issueId: string) {
        try {
            const response = await visorPRORestClient.issue.getIssueInstances(
                issueId,
                {
                    limit: 100,
                },
            )

            runInAction(() => {
                this.instancesByIssueId = {
                    [issueId]: response.items,
                    ...this.instancesByIssueId,
                }
            })
        } catch (e) {
            toast.error(
                `Failed to fetch issue instances: ${(e as Error).message}`,
            )
        }
    }

    public async createIssue(name: string) {
        try {
            const issue = await visorPRORestClient.issue.createIssue(name)

            runInAction(() => {
                this.issuesById[issue.id] = issue
                this.issueIds = [issue.id, ...this.issueIds]
            })

            return issue.id
        } catch (e) {
            toast.error(
                `Failed to create issue instance: ${(e as Error).message}`,
            )
        }
    }

    public async createInstance(
        issueId: string,
        section: APIRandomDocumentSection,
    ) {
        try {
            const instance = await visorPRORestClient.issue.createIssueInstance(
                {
                    issue_id: issueId,
                    document_id: section.document_id,
                    page_number: section.page_start_number,
                },
            )

            runInAction(() => {
                if (instance.issue) {
                    this.issuesById[instance.issue_id] = instance.issue
                }

                // instances are only loaded after visiting a specific issue page.
                // if the user hasn't visited the page for this issue, we don't need
                // to set the array, as it will be loaded when the issue is visited.
                if (this.instancesByIssueId[issueId]) {
                    this.instancesByIssueId = {
                        ...this.instancesByIssueId,
                        [issueId]: [
                            instance,
                            ...(this.instancesByIssueId[issueId] ?? []),
                        ],
                    }
                }

                this.stores.sectionPreview.addInstanceToSection(
                    instance,
                    section,
                )
            })
        } catch (e) {
            toast.error(
                `Failed to create issue instance: ${(e as Error).message}`,
            )
        }
    }

    public async deleteInstance(
        issueId: string,
        section: APIRandomDocumentSection,
    ) {
        try {
            const instance = this.stores.sectionPreview.getIssueInstance(
                issueId,
                section.id,
            )

            if (!instance) {
                console.warn('Could not find issue instance to delete')
                return
            }

            const deletedInstance =
                await visorPRORestClient.issue.deleteIssueInstance(instance.id)

            runInAction(() => {
                if (deletedInstance.issue) {
                    this.issuesById[deletedInstance.issue_id] =
                        deletedInstance.issue
                }

                if (this.instancesByIssueId[issueId]) {
                    this.instancesByIssueId = {
                        ...this.instancesByIssueId,
                        [issueId]: this.instancesByIssueId[issueId].filter(
                            (i) => i.id !== instance.id,
                        ),
                    }
                }

                this.stores.sectionPreview.removeInstanceFromSection(
                    instance.id,
                    section.id,
                )
            })
        } catch (e) {
            toast.error(
                `Failed to create issue instance: ${(e as Error).message}`,
            )
        }
    }
}

export const useIssues = (stores: Stores) => {
    useEffect(() => {
        if (!stores.issues.didFetchIssues) {
            void stores.issues.getIssues()
        }
    }, [stores.issues])

    return useMemo(() => {
        return stores.issues.issueIds.map((id) => stores.issues.issuesById[id])
    }, [stores.issues.issueIds, stores.issues.issuesById])
}

export const useIssue = (id: string, stores: Stores) => {
    useEffect(() => {
        if (!stores.issues.didFetchIssues) {
            void stores.issues.getIssues()
        }
    }, [stores.issues])

    return useMemo<APIIssue | undefined>(() => {
        return stores.issues.issuesById[id]
    }, [id, stores.issues.issuesById])
}

export const useIssueInstances = (issueId: string, stores: Stores) => {
    useEffect(() => {
        if (!stores.issues.instancesByIssueId[issueId]) {
            void stores.issues.getInstances(issueId)
        }
    }, [issueId, stores.issues])

    return useMemo(() => {
        return stores.issues.instancesByIssueId[issueId] ?? []
    }, [issueId, stores.issues.instancesByIssueId])
}
