import type { APIIssue } from '@visorpro/client'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import type { Stores } from '../../util/store'
import { Table } from '../table/table'
import { TableHeader } from '../table/table-header'
import { IssueRow } from './issue-row'

export interface IssuesTableProps {
    stores: Stores
    items: APIIssue[]
}

export const IssueTable: FC<IssuesTableProps> = observer(
    ({ items, stores }) => {
        return (
            <Table>
                <thead>
                    <tr>
                        <TableHeader>Kind</TableHeader>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Fixed</TableHeader>
                        <TableHeader>Instances</TableHeader>
                    </tr>
                </thead>

                <tbody>
                    {items.map((item) => {
                        return (
                            <IssueRow
                                key={item.id}
                                stores={stores}
                                issueId={item.id}
                            />
                        )
                    })}
                </tbody>
            </Table>
        )
    },
)
