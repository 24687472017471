import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import type { Stores } from '../../util/store'
import { maybeZeroColor } from '../../util/style'
import { TableLinkCell } from '../table/table-link-cell'
import { TableRow } from '../table/table-row'
import { TableTextCell } from '../table/table-text-cell'

export interface IssueRowProps {
    stores: Stores
    issueId: string
}

export const IssueRow: FC<IssueRowProps> = observer(({ stores, issueId }) => {
    const issue = stores.issues.issuesById[issueId]

    return (
        <TableRow key={issue.id}>
            <TableTextCell>{issue.kind ?? ''}</TableTextCell>

            <TableLinkCell to={`/issues/${issue.id}`}>
                {issue.name ?? ''}
            </TableLinkCell>

            <TableTextCell>{issue.is_fixed ? 'Yes' : 'No'}</TableTextCell>

            <TableTextCell
                style={{
                    color: maybeZeroColor(issue._count.document_sections),
                }}>
                {issue._count.document_sections}
            </TableTextCell>
        </TableRow>
    )
})
