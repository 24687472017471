import type { CSSProperties, FC } from 'react'
import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import type { NavLinkFnProps } from '../../pages/root'

export enum DataSetNavBarItem {
    Documents,
    Questions,
    TestRuns,
}

const nameByItem: Record<DataSetNavBarItem, string> = {
    [DataSetNavBarItem.Documents]: 'Documents',
    [DataSetNavBarItem.Questions]: 'Questions',
    [DataSetNavBarItem.TestRuns]: 'Test runs',
}

const routeByItem: Record<DataSetNavBarItem, string> = {
    [DataSetNavBarItem.Documents]: 'documents',
    [DataSetNavBarItem.Questions]: 'questions',
    [DataSetNavBarItem.TestRuns]: 'test-runs',
}

export interface NavBarProps {
    style?: CSSProperties
}

export const DataSetNavBar: FC<NavBarProps> = ({ style }) => {
    const items = [
        DataSetNavBarItem.Documents,
        DataSetNavBarItem.Questions,
        DataSetNavBarItem.TestRuns,
    ]

    const itemClassName = useCallback(({ isActive }: NavLinkFnProps) => {
        return isActive ? 'active' : ''
    }, [])

    return (
        <div className="navbar" style={style}>
            {items.map((item) => (
                <span key={item}>
                    <NavLink to={routeByItem[item]} className={itemClassName}>
                        {nameByItem[item]}
                    </NavLink>
                </span>
            ))}
        </div>
    )
}
