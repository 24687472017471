import { observer } from 'mobx-react-lite'
import { useModels } from '../../stores/models-store'
import type { Stores } from '../../util/store'
import { ModelsTable } from '../../components/models-table'
import { useManufacturers } from '../../stores/manufacturers-store'
import { useCallback } from 'react'
import { useCategories } from '../../stores/categories-store'
import type { APICreateModelRequest, APIUpdateModelRequest } from '@visorpro/client/dist/RestClient/services'

export interface ModelsPageProps {
    stores: Stores
}

export const ModelsPage = observer(({ stores }: ModelsPageProps) => {
    const models = useModels(stores)
    const manufacturers = useManufacturers(stores)
    const categories = useCategories(stores)

    const createModel = useCallback(async (options: APICreateModelRequest) => {
        await stores.models.create(options)
    }, [stores.models])

    const updateModel = useCallback(async (modelId: string, options: Partial<APIUpdateModelRequest>) => {
        await stores.models.update(modelId, options)
    }, [stores.models])

    const deleteModel = useCallback(async (modelId: string) => {
        await stores.models.delete(modelId)
    }, [stores.models])

    const mergeModels = useCallback(async (modelIds: string[]) => {
        await stores.models.merge(modelIds)
    }, [stores.models])

    return (
        <ModelsTable
            models={models}
            manufacturers={manufacturers}
            categories={categories}
            isLoading={stores.models.isFetching}
            showProgressBars={stores.models.isUpdating}
            rowCount={stores.models.totalModels}
            isPrimaryBackgroundColor={true}
            createModel={createModel}
            updateModel={updateModel}
            deleteModel={deleteModel}
            mergeModels={mergeModels}
        />
    )
})
