import type { DateTime } from 'luxon'
import type { CSSProperties, FC } from 'react'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { createDateParts } from '../../util/format'
import { TableTextCell } from './table-text-cell'

export interface TableTextCellProps {
    date: DateTime
    now: DateTime
    style?: CSSProperties
    to?: string
}

export const TableDateCell: FC<TableTextCellProps> = ({
    date,
    now,
    style,
    to,
}) => {
    const created = createDateParts(date, now)

    const content = useMemo(() => {
        if (to) {
            return (
                <NavLink className="text-link" to={to}>
                    {created.default}
                </NavLink>
            )
        }

        return created.default
    }, [created.default, to])

    return (
        <TableTextCell
            title={created.title}
            onClick={created.copyToClipboard}
            style={{
                color: 'var(--comment-color)',
                whiteSpace: 'nowrap',
                ...style,
            }}>
            {content}
        </TableTextCell>
    )
}
