export const maybeNullColor = (value: unknown) => {
    return value === null ? 'var(--comment-color)' : undefined
}

export const booleanColor = (value: boolean | null) => {
    if (value) {
        return 'var(--green-color)'
    }

    return 'var(--comment-color)'
}

export const maybeZeroColor = (value: number) => {
    return value === 0 ? 'var(--comment-color)' : undefined
}

export const progressColor = (percentage: number, isComplete = true) => {
    if (!isComplete) {
        return 'var(--comment-color)'
    }

    if (percentage < 20) {
        return 'var(--red-color)'
    }

    if (percentage < 40) {
        return 'var(--orange-color)'
    }

    if (percentage < 60) {
        return 'var(--yellow-color)'
    }

    if (percentage < 80) {
        return 'var(--cyan-color)'
    }

    return 'var(--green-color)'
}

export const successColor = (success: boolean | null) => {
    if (success === null) {
        return 'var(--comment-color)'
    }

    return success ? 'var(--green-color)' : 'var(--red-color)'
}
