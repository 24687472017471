import { FC, ReactNode, useCallback, useRef, useState } from "react"
import { MdArrowForward } from "react-icons/md"
import { TextButton } from "../../../../components/text-button"

export interface CreateModalProps {
    anchor: HTMLDivElement
    onClose(): void
}

export interface MappingSelectRowProps {
    label: string
    value?: string | null
    createModal?(options: CreateModalProps): ReactNode
}

export const MappingSelectRow: FC<MappingSelectRowProps> = ({
    label,
    value,
    createModal,
}) => {
    const modalAnchor = useRef<HTMLDivElement>(null)
    const [modalIsVisible, setModalIsVisible] = useState(false)

    const showModal = useCallback(() => {
        setModalIsVisible(true)
    }, [])

    const hideModal = useCallback(() => {
        setModalIsVisible(false)
    }, [])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }}>
            <div>{label}</div>

            <MdArrowForward
                color='var(--comment-color)'
                style={{ marginLeft: '1rem' }}
            />

            {
                typeof value === 'string' &&
                <TextButton
                    ref={modalAnchor}
                    kind='primary'
                    style={{ marginLeft: '1rem' }}
                    onClick={showModal}>
                    {value}
                </TextButton>
            }

            {
                typeof value !== 'string' &&
                <TextButton
                    ref={modalAnchor}
                    kind='comment'
                    style={{ marginLeft: '1rem' }}
                    onClick={showModal}>
                    Not set
                </TextButton>
            }

            {
                modalIsVisible && createModal?.({
                    anchor: modalAnchor.current!,
                    onClose: hideModal,
                })
            }
        </div>
    )
}
