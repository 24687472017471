import { DateTime } from 'luxon'
import type { APIUser } from '@visorpro/client'

export const formatDate = (date: DateTime, now = DateTime.local()) => {
    if (!date) return ''
    if (date.year === now.year) return date.toFormat('MMM d h:mma')
    return date.toFormat('MMM d, y h:mma')
}

export const createDateParts = (date: DateTime, now = DateTime.local()) => {
    const iso = date.toISO()!
    return {
        // default user-visible
        default: formatDate(date, now),
        // visible when hovering the component
        title: iso,
        copyToClipboard: () => {
            navigator.clipboard.writeText(iso)
        },
    }
}

export const formatUserName = (user: APIUser): string | undefined => {
    const segments: string[] = []
    if (user.first_name) segments.push(user.first_name)
    if (user.last_name) segments.push(user.last_name)
    if (segments.length > 0) return segments.join(' ')
}

export const formatDuration = (secondsTotal: number): string => {
    const hours = Math.floor(secondsTotal / 3600)
    const minutes = Math.floor((secondsTotal % 3600) / 60)
    const seconds = Math.floor(secondsTotal % 60)

    let formattedDuration = ''

    if (hours > 0) {
        formattedDuration += `${hours}h `
    }

    if (minutes > 0 || hours > 0) {
        formattedDuration += `${minutes}m `
    }

    formattedDuration += `${seconds}s`

    return formattedDuration.trim()
}
