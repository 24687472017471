import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback } from 'react'
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { TestRunQuestionsTable } from '../../components/test-run-questions-table/test-run-questions-table'
import { TestRunsTable } from '../../components/test-runs-table/test-runs-table'
import { useDocumentById } from '../../stores/documents-store'
import { useTestRun } from '../../stores/test-runs-store'
import { formatDate } from '../../util/format'
import type { Stores } from '../../util/store'
import { TestRunQuestion } from './test-run-question/test-run-question'

export interface DocumentTestRunDetailsPageProps {
    stores: Stores
}

export const DocumentTestRunDetailsPage: FC<DocumentTestRunDetailsPageProps> =
    observer(({ stores }) => {
        const { documentId, testRunId } = useParams()
        const document = useDocumentById(stores, documentId!)
        const testRun = useTestRun(stores, testRunId!)

        const [searchParams] = useSearchParams()

        const questionId = searchParams.get('question_id')

        const question = questionId
            ? stores.testRuns.testRunQuestionsById[questionId]
            : undefined

        const location = useLocation()
        const navigate = useNavigate()

        const onQuestionClose = useCallback(() => {
            searchParams.delete('question_id')
            navigate(`${location.pathname}?${searchParams.toString()}`)
        }, [location, navigate, searchParams])

        if (!document || !testRun) {
            return null
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    boxSizing: 'border-box',
                }}>
                <div
                    style={{
                        padding: '1rem',
                        flexGrow: 1,
                        width: '50%',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <BreadCrumbs
                            name={formatDate(testRun.created_at)}
                            path={[
                                {
                                    name: 'Documents',
                                    to: '/documents',
                                },
                                {
                                    name: document.title,
                                    to: `/documents/${document.id}/questions`,
                                },
                                {
                                    name: 'Test runs',
                                    to: `/documents/${document.id}/test-runs`,
                                },
                            ]}
                        />
                    </div>

                    <TestRunsTable testRuns={[testRun]} stores={stores} />

                    <TestRunQuestionsTable
                        testRunQuestions={testRun.test_run_questions ?? []}
                        stores={stores}
                        focusedQuestionId={questionId}
                    />
                </div>

                {question && (
                    <div
                        style={{
                            padding: '1rem',
                            width: '50%',
                            backgroundColor: 'var(--background-dark-color)',
                            height: '100%',
                            overflow: 'auto',
                            boxSizing: 'border-box',
                        }}>
                        <TestRunQuestion
                            item={question}
                            onClose={onQuestionClose}
                        />
                    </div>
                )}
            </div>
        )
    })
