import { observer } from "mobx-react-lite"
import { TextButton, TextButtonProps } from "../../../components/text-button"
import { MetadataImportStep, MetadataImportStore } from "./metadata-import-store"
import { useMemo } from "react"

export interface MetadataImportFooterProps {
    metadataImportStore: MetadataImportStore
}

export const MetadataImportFooter = observer(({
    metadataImportStore,
}: MetadataImportFooterProps) => {
    const previousButton = useMemo(() => {
        if (metadataImportStore.currentStep === MetadataImportStep.MapColumns) {
            return <div />
        }

        return (
            <TextButton
                kind='comment'
                onClick={metadataImportStore.movePrevious}
                disabled={!metadataImportStore.mapColumnsStepIsValid}>
                Previous
            </TextButton>
        )
    }, [metadataImportStore.currentStep, metadataImportStore.mapColumnsStepIsValid])

    const nextButton = useMemo(() => {
        if (metadataImportStore.currentStep === MetadataImportStep.PreviewDocuments) {
            let kind: TextButtonProps['kind']
            let label: string

            if (
                metadataImportStore.documentDiffs.length > 0 &&
                !metadataImportStore.didSubmit
            ) {
                kind = 'primary'
                label = 'Submit'
            } else {
                kind = 'danger'
                label = 'Close'
            }

            return (
                <TextButton
                    kind={kind}
                    onClick={metadataImportStore.moveNext}
                    disabled={metadataImportStore.isSubmitting}>
                    {label}
                </TextButton>
            )
        }

        return (
            <TextButton
                kind='primary'
                onClick={metadataImportStore.moveNext}
                disabled={!metadataImportStore.mapColumnsStepIsValid}>
                Next
            </TextButton>
        )
    }, [
        metadataImportStore.currentStep,
        metadataImportStore.mapColumnsStepIsValid,
        metadataImportStore.documentDiffs.length,
        metadataImportStore.isSubmitting,
    ])

    return (
        <div style={{
            display: 'flex',
            borderTop: '1px solid var(--selection-color)',
            paddingTop: '1rem',
            marginTop: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            {previousButton}
            {nextButton}
        </div>
    )
})
