import fuzzysort from 'fuzzysort'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { MdCheck, MdOutlineOpenInNew } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { FormTextInput } from '../creation/form-text-input'
import './selectable-list.css'

export interface SelectableListItem {
    id: string
    name: string
    navigateTo?: string | null
}

export interface SelectableListProps {
    items: SelectableListItem[]
    selectedIds?: Set<string>
    onSelect?(item: SelectableListItem): void
    isLoading: boolean
    placeholder: string
    onSubmit?(value: string): void
    onQueryChange?(value: string): void
}

export const SelectableList: FC<SelectableListProps> = ({
    items,
    selectedIds,
    onSelect,
    isLoading,
    placeholder,
    onSubmit,
    onQueryChange,
}) => {
    const [query, setQuery] = useState('')

    const results = useMemo(() => {
        return fuzzysort.go(query, items, {
            keys: ['name'],
            all: true,
        })
    }, [query, items])

    const submit = useCallback(
        (e: React.FormEvent) => {
            e.preventDefault()
            const value = query.trim()

            if (value) {
                onSubmit?.(value)
            }
        },
        [query, onSubmit],
    )

    const onChange = useCallback(
        (value: string) => {
            setQuery(value)
            onQueryChange?.(value)
        },
        [onQueryChange],
    )

    return (
        <form onSubmit={submit}>
            <FormTextInput
                value={query}
                onChange={onChange}
                placeholder={placeholder}
                style={{
                    padding: '0 0rem 0.25rem',
                }}
            />

            {isLoading && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0.25rem 0rem',
                    }}>
                    <span className="color-dimmed">Loading...</span>
                </div>
            )}

            {!isLoading && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        maxHeight: '400px',
                        paddingBottom: '0.75rem',
                    }}>
                    {results.map((result) => {
                        return (
                            <div
                                key={result.obj.id}
                                className="selectable-list-item"
                                style={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0.25rem 0rem',
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flexGrow: 2,
                                    }}
                                    onClick={() => onSelect?.(result.obj)}>
                                    <div>
                                        <span
                                            className="color-foreground"
                                            style={{ paddingRight: '0.5rem' }}>
                                            {query.length === 0 &&
                                                result.obj.name}

                                            {query.length > 0 &&
                                                result[0].highlight(
                                                    (m, i) => (
                                                        <span
                                                            key={i}
                                                            style={{
                                                                color: 'var(--purple-color)',
                                                                wordWrap:
                                                                    'break-word',
                                                            }}>
                                                            {m}
                                                        </span>
                                                    ),
                                                )}
                                        </span>
                                    </div>

                                    <MdCheck
                                        size={16}
                                        color="var(--green-color)"
                                        style={{
                                            flexShrink: 0,
                                            // this prevents the layout from changing when selecting / unselecting items
                                            opacity: selectedIds?.has(
                                                result.obj.id,
                                            )
                                                ? 1
                                                : 0,
                                        }}
                                    />
                                </div>

                                {result.obj.navigateTo && (
                                    <NavLink
                                        to={result.obj.navigateTo}
                                        target="_blank">
                                        <MdOutlineOpenInNew
                                            size={16}
                                            color="var(--comment-color)"
                                            className="preview-icon"
                                            style={{
                                                flexShrink: 0,
                                                marginLeft: '0.5rem',
                                            }}
                                        />
                                    </NavLink>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </form>
    )
}
