import type { FC } from 'react'
import { useCallback } from 'react'
import type { ActionMenuItem } from '../actions-menu'
import { ActionsMenu } from '../actions-menu'

export enum TestQuestionActionKind {
    Update = 'update',
    Delete = 'delete',
}

interface TestQuestionActionsModalProps {
    anchor: HTMLElement
    testQuestionId: string
    onClose(): void
    onAction(testQuestionId: string, kind: TestQuestionActionKind): void
}

export const TestQuestionActionsModal: FC<TestQuestionActionsModalProps> = ({
    anchor,
    testQuestionId,
    onClose,
    onAction,
}) => {
    const _onAction = useCallback(
        (item: ActionMenuItem) => {
            onAction(testQuestionId, item.id as TestQuestionActionKind)
        },
        [onAction, testQuestionId],
    )

    return (
        <ActionsMenu
            anchor={anchor}
            items={[
                {
                    id: TestQuestionActionKind.Update,
                    name: 'Update question',
                },
                {
                    id: TestQuestionActionKind.Delete,
                    name: 'Delete question',
                    shouldConfirm: true,
                },
            ]}
            onAction={_onAction}
            onClose={onClose}
        />
    )
}
