import {
    type PaginatedRequestQueryParams,
    type APIWorkOrder,
} from '@visorpro/client'
import { makeAutoObservable } from 'mobx'
import _ from 'lodash'
import { visorPRORestClient } from '../util/api'

export class WorkOrdersStore {
    public workOrdersById: Record<string, APIWorkOrder> = {}
    public totalWorkOrders: number = 0
    public isFetched: boolean = false
    private _pagination: PaginatedRequestQueryParams = {
        limit: 1000,
        offset: 0,
    }

    constructor() {
        makeAutoObservable(this)
    }

    public get workOrders(): APIWorkOrder[] {
        return _.values(this.workOrdersById)
    }

    public get pagination(): PaginatedRequestQueryParams {
        return this._pagination
    }

    public list = async (queryParams?: PaginatedRequestQueryParams) => {
        const response = await visorPRORestClient.workOrder.list(queryParams)
        this.workOrdersById = _.keyBy(response.items, 'id')
        this.totalWorkOrders = response.total
        this.isFetched = true
        this._pagination = queryParams || this._pagination
    }
}
