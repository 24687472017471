import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { MdAdd } from 'react-icons/md'
import { AcceptanceCriteriaListItem } from './acceptance-criteria-list-item'
import type {
    AcceptanceCriteriaInput,
    QuestionFormStore,
} from './question-form-store'

export interface AcceptanceCriteriaListProps {
    store: QuestionFormStore
    style?: React.CSSProperties
}

export const AcceptanceCriteriaList: FC<AcceptanceCriteriaListProps> = observer(
    ({ store, style }) => {
        const onAdd = useCallback(() => {
            store.acceptanceCriteriaStore.addEmptyItem()
        }, [store])

        const onRemove = useCallback(
            (item: AcceptanceCriteriaInput) => {
                store.acceptanceCriteriaStore.removeItem(item.key)
            },
            [store],
        )

        const items = useMemo(() => {
            return store.acceptanceCriteriaStore.itemKeys.map(
                (key) => store.acceptanceCriteriaStore.itemsByKey[key],
            )
        }, [
            store.acceptanceCriteriaStore.itemKeys,
            store.acceptanceCriteriaStore.itemsByKey,
        ])

        const content = useMemo(() => {
            return items.map((item) => (
                <AcceptanceCriteriaListItem
                    key={item.key}
                    item={item}
                    onTextChange={store.setAcceptanceCriteriaText}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    store={store}
                />
            ))
        }, [items, onAdd, onRemove, store])

        return (
            <div
                style={{
                    gap: '0.25rem',
                    display: 'flex',
                    flexDirection: 'column',
                    ...style,
                }}>
                {content}

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                    <MdAdd
                        size={16}
                        color="var(--green-color)"
                        style={{
                            flexShrink: 0,
                            cursor: 'pointer',
                        }}
                        onClick={onAdd}
                    />
                </div>
            </div>
        )
    },
)
