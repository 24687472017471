import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { useIssue, useIssueInstances } from '../../stores/issues-store'
import type { Stores } from '../../util/store'
import { IssueInstanceThumbnail } from './issue-instance-thumbnail'

export interface IssueDetailsPageProps {
    stores: Stores
}

export const IssueDetailsPage: FC<IssueDetailsPageProps> = observer(
    ({ stores }) => {
        const { issueId } = useParams()
        const issue = useIssue(issueId!, stores)
        const instances = useIssueInstances(issueId!, stores)

        if (!issue) {
            return null
        }

        return (
            <div style={{ padding: '1rem' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <BreadCrumbs
                        name={issue.name}
                        path={[
                            {
                                name: 'Issues',
                                to: '/issues',
                            },
                        ]}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        gap: '1rem',
                        marginTop: '1rem',
                    }}>
                    {instances.map((instance) => {
                        return (
                            <IssueInstanceThumbnail
                                key={instance.id}
                                instance={instance}
                            />
                        )
                    })}
                </div>
            </div>
        )
    },
)
