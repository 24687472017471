import type { APITestRunQuestion } from '@visorpro/client'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import type { Stores } from '../../util/store'
import { Table } from '../table/table'
import { TableHeader } from '../table/table-header'
import { TestRunQuestionRow } from './test-run-question-row'

export interface TestQuestionsTableProps {
    stores: Stores
    testRunQuestions: APITestRunQuestion[]
    focusedQuestionId: string | null
}

export const TestRunQuestionsTable: FC<TestQuestionsTableProps> = observer(
    ({ stores, testRunQuestions, focusedQuestionId }) => {
        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Score</TableHeader>
                            <TableHeader>Question</TableHeader>
                            <TableHeader>Assertions</TableHeader>
                        </tr>
                    </thead>

                    <tbody>
                        {testRunQuestions.map((testRunQuestion) => {
                            return (
                                <TestRunQuestionRow
                                    key={testRunQuestion.id}
                                    testRunQuestion={testRunQuestion}
                                    stores={stores}
                                    isFocused={
                                        focusedQuestionId === testRunQuestion.id
                                    }
                                />
                            )
                        })}
                    </tbody>
                </Table>
            </>
        )
    },
)
