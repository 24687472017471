import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { TestRunsTable } from '../../../../components/test-runs-table/test-runs-table'
import { useDataSetById } from '../../../../stores/data-sets-store'
import { useDataSetTestRuns } from '../../../../stores/test-runs-store'
import type { Stores } from '../../../../util/store'

export interface DataSetTestRunsProps {
    stores: Stores
}

export const DataSetTestRuns = observer(({ stores }: DataSetTestRunsProps) => {
    const { dataSetId } = useParams()
    const dataSet = useDataSetById(stores, dataSetId!)

    const testRuns = useDataSetTestRuns(stores, dataSetId!)

    if (!dataSet) {
        return null
    }

    return (
        <div>
            <TestRunsTable
                showDocumentsColumn
                stores={stores}
                testRuns={testRuns}
            />
        </div>
    )
})
