import type { CSSProperties, FC, ReactNode } from 'react'
import { useCallback, useState } from 'react'
import type { Placement } from '@floating-ui/react'
import {
    FloatingPortal,
    useFloating,
    useClick,
    useDismiss,
    useRole,
    useInteractions,
    FloatingFocusManager,
    FloatingOverlay,
    offset,
    flip,
    shift,
    autoUpdate,
} from '@floating-ui/react'
import { MdChevronLeft } from 'react-icons/md'

export interface ModalProps {
    anchor?: HTMLElement | null
    children: ReactNode
    onClose?(): void
    style?: CSSProperties
    placement?: Placement
    onBack?(): void
    containerStyle?: CSSProperties
}

export const Modal: FC<ModalProps> = ({
    anchor,
    children,
    onClose,
    style,
    placement = 'bottom-end',
    onBack,
    containerStyle,
}) => {
    const { refs, floatingStyles, context } = useFloating({
        elements: {
            reference: anchor,
        },
        strategy: 'absolute',
        placement,
        open: true,
        onOpenChange: (open) => {
            if (!open) onClose?.()
        },
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)

    const { getFloatingProps } = useInteractions([click, dismiss, role])

    return (
        <FloatingPortal>
            <FloatingOverlay className="modal-overlay" lockScroll style={containerStyle}>
                <FloatingFocusManager context={context} modal={true}>
                    <div
                        style={{
                            ...floatingStyles,
                            backgroundColor: 'var(--background-dark-color)',
                            padding: '1rem',
                            borderRadius: '6px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                            ...style,
                        }}
                        onClick={(e) => e.stopPropagation()}
                        ref={refs.setFloating}
                        {...getFloatingProps()}>

                        {
                            onBack &&
                            <MdChevronLeft
                                size={24}
                                color="var(--comment-color)"
                                style={{
                                    flexShrink: 0,
                                    cursor: 'pointer',
                                }}
                                onClick={onBack}
                            />
                        }

                        {children}
                    </div>
                </FloatingFocusManager>
            </FloatingOverlay>
        </FloatingPortal>
    )
}

interface ModalState<T = unknown> {
    anchor: HTMLElement
    data: T
}

export function useModal<T>() {
    const [state, setState] = useState<ModalState<T | undefined>>()

    const open = useCallback((e: { target: EventTarget }, data?: T) => {
        setState({
            data,
            anchor: e.target as HTMLElement,
        })
    }, [])

    const update = useCallback(
        (data?: T) => {
            if (state) {
                setState({ ...state, data })
            }
        },
        [state],
    )

    const close = useCallback(() => setState(undefined), [])

    return { state, open, close, update }
}
