import { observer } from 'mobx-react-lite'
import type { CSSProperties, FC } from 'react'
import { useCallback } from 'react'
import { Modal } from '../../components/modal'
import { SelectableList } from '../../components/modals'
import { useManufacturers } from '../../stores/manufacturers-store'
import type { Stores } from '../../util/store'
import type { Placement } from '@floating-ui/react'

export interface ManufacturerModalProps {
    anchor?: HTMLElement
    placement?: Placement
    stores: Stores
    onClose(): void
    onManufacturerIdChange(id: string): void
    style?: CSSProperties
    containerStyle?: CSSProperties
    onBack?(): void
    selectedIds?: Set<string>
}

export const ManufacturerModal: FC<ManufacturerModalProps> = observer(
    ({
        anchor,
        placement,
        stores,
        onClose,
        onManufacturerIdChange,
        style,
        containerStyle,
        onBack,
        selectedIds,
    }) => {
        const manufacturers = useManufacturers(stores)

        const select = useCallback(
            (manufacturerId: string) => {
                onManufacturerIdChange(manufacturerId)
            },
            [onManufacturerIdChange],
        )

        const createManufacturer = useCallback(
            async (name: string) => {
                const manufacturerId = await stores.manufacturers.create({
                    name,
                })
                if (manufacturerId) {
                    select(manufacturerId)
                }
            },
            [select, stores.manufacturers],
        )

        return (
            <Modal
                placement={placement}
                anchor={anchor}
                onClose={onClose}
                style={style}
                containerStyle={containerStyle}
                onBack={onBack}>
                <SelectableList
                    items={manufacturers}
                    selectedIds={selectedIds}
                    onSelect={(item) => select(item.id)}
                    onSubmit={createManufacturer}
                    isLoading={stores.manufacturers.isFetching || stores.manufacturers.isUpdating}
                    placeholder="Search manufacturer"
                />
            </Modal>
        )
    },
)
