import type { FC } from 'react'
import { useCallback, useState } from 'react'

export interface DeleteButtonProps {
    onDelete?(): void
}

export const DeleteButton: FC<DeleteButtonProps> = ({ onDelete }) => {
    const [isConfirming, setIsConfirming] = useState(false)

    const askConfirmation = useCallback(() => {
        setIsConfirming(true)
    }, [])

    if (isConfirming) {
        return (
            <button
                className="text-button"
                onClick={onDelete}
                style={{
                    cursor: 'pointer',
                    color: 'var(--red-color)',
                }}>
                Are you sure?
            </button>
        )
    }

    return (
        <button
            className="text-button"
            onClick={askConfirmation}
            style={{
                cursor: 'pointer',
                color: 'var(--comment-color)',
            }}>
            Delete
        </button>
    )
}
