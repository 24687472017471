import { makeAutoObservable, runInAction } from 'mobx'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import type { APIOrganizationInviteCode } from '@visorpro/client'
import { observed } from '../util/observed-decorator'

export class OrganizationInvitationCodesStore {
    public codesByOrganizationId: Record<
        string,
        APIOrganizationInviteCode[] | undefined
    > = {}
    public isFetching = false
    public isUpdating = false

    constructor() {
        makeAutoObservable(this)
    }

    @observed('isFetching')
    public async getOrganizationCodes(organizationId: string) {
        const codes =
            await visorPRORestClient.organization.getInvitationCodes(
                organizationId,
            )

        runInAction(() => {
            this.codesByOrganizationId = {
                ...this.codesByOrganizationId,
                [organizationId]: codes,
            }
        })
    }

    @observed('isUpdating')
    public async deleteOrganizationCode(
        organizationId: string,
        codeId: string,
    ) {
        await visorPRORestClient.organization.deleteInvitationCode(
            organizationId,
            codeId,
        )

        runInAction(() => {
            this.codesByOrganizationId = {
                ...this.codesByOrganizationId,
                [organizationId]: this.codesByOrganizationId[
                    organizationId
                ]?.filter((code) => code.id !== codeId),
            }
        })
    }

    @observed('isUpdating')
    public async createCode(organizationId: string, codeStr?: string) {
        const code = await visorPRORestClient.organization.invitationCode(
            organizationId,
            codeStr,
        )

        runInAction(() => {
            this.codesByOrganizationId = {
                ...this.codesByOrganizationId,
                [organizationId]: [
                    code,
                    ...(this.codesByOrganizationId[organizationId] ?? []),
                ],
            }
        })
    }
}

export const useCodesByOrganizationId = (
    stores: Stores,
    organizationId: string,
) => {
    return stores.organizationCodes.codesByOrganizationId[organizationId]
}
