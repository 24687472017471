import type { FC } from 'react'
import { useCallback, useRef } from 'react'
import type { Stores } from '../../util/store'
import { useModal } from '../modal'
import { TestRunConfigModal } from '../test-run-config-modal/test-run-config-modal'
import { TextButton } from '../text-button'

export interface TestRunsBulkSelectModalProps {
    stores: Stores
    testRunIds: string[]
    isCopyingTestRuns: boolean
    onClearPress(): void
}

export const TestRunsBulkSelectModal: FC<TestRunsBulkSelectModalProps> = ({
    stores,
    testRunIds,
    isCopyingTestRuns,
    onClearPress,
}) => {
    const testRunConfigModal = useModal()
    const containerRef = useRef<HTMLDivElement>(null)

    const onCopyPress = useCallback(() => {
        if (containerRef.current) {
            testRunConfigModal.open({
                target: containerRef.current,
            })
        }
    }, [containerRef, testRunConfigModal])

    const testRunOrTestRuns = testRunIds.length === 1 ? 'test run' : 'test runs'

    return (
        <>
            <div
                ref={containerRef}
                style={{
                    position: 'fixed',
                    bottom: '3rem',
                    height: '3rem',
                    right: '2rem',
                    backgroundColor: 'var(--background-dark-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '5px',
                    padding: '0 1rem',
                    gap: '1rem',
                }}>
                <TextButton disabled={isCopyingTestRuns} onClick={onCopyPress}>
                    {`Copy ${testRunIds.length} ${testRunOrTestRuns}`}
                </TextButton>

                <span
                    style={{
                        width: '1px',
                        height: '16px',
                        backgroundColor: 'var(--selection-color-opaque)',
                    }}
                />

                <TextButton
                    style={{ color: 'var(--comment-color)' }}
                    onClick={onClearPress}>
                    Clear
                </TextButton>
            </div>

            {testRunConfigModal.state && (
                <TestRunConfigModal
                    testRunIds={testRunIds}
                    stores={stores}
                    anchor={testRunConfigModal.state.anchor}
                    placement="bottom-end"
                    onClose={testRunConfigModal.close}
                />
            )}
        </>
    )
}
