import type { Placement } from '@floating-ui/react'
import type { FC } from 'react'
import type { SelectableListProps } from './selectable-list'
import { SelectableList } from './selectable-list'
import { Modal } from '../../modal'

export interface SelectableListModalProps {
    anchor: HTMLElement
    selectableListProps: SelectableListProps
    onClose?(): void
    placement?: Placement
}

export const SelectableListModal: FC<SelectableListModalProps> = ({
    anchor,
    selectableListProps,
    onClose,
    placement,
}) => {
    return (
        <Modal
            anchor={anchor}
            style={{ width: '500px' }}
            placement={placement}
            onClose={onClose}>
            <SelectableList
                {...selectableListProps}
                onQueryChange={(query) => {
                    selectableListProps.onQueryChange?.(query)
                }}
            />
        </Modal>
    )
}
