import { makeAutoObservable, runInAction } from 'mobx'
import { visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import type { APIOrganizationDomain } from '@visorpro/client/dist/types/APIOrganizationDomain'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { observed } from '../util/observed-decorator'

export class OrganizationDomainsStore {
    public domainsById: Record<string, APIOrganizationDomain> = {}
    public domainIdsByOrganizationId: Record<string, string[]> = {}
    public isFetching = false
    public isUpdating = false

    constructor() {
        makeAutoObservable(this)
    }

    @observed('isFetching')
    public async getDomains(organizationId: string) {
        try {
            const domains =
                await visorPRORestClient.organization.getDomains(organizationId)

            runInAction(() => {
                const domainsById: Record<string, APIOrganizationDomain> = {}
                const domainIds: string[] = []

                domains.items.forEach((domain) => {
                    domainsById[domain.id] = domain
                    domainIds.push(domain.id)
                })

                this.domainsById = domainsById
                this.domainIdsByOrganizationId = {
                    ...this.domainIdsByOrganizationId,
                    [organizationId]: domainIds,
                }
            })
        } catch (e) {
            toast.error(`Failed to fetch domains: ${(e as Error).message}`)
        }
    }

    @observed('isUpdating')
    public async createDomain(organizationId: string, domain: string) {
        try {
            const result = await visorPRORestClient.organization.createDomain(
                organizationId,
                domain,
            )

            runInAction(() => {
                this.domainsById = {
                    ...this.domainsById,
                    [result.id]: result,
                }
                this.domainIdsByOrganizationId = {
                    ...this.domainIdsByOrganizationId,
                    [organizationId]: [
                        result.id,
                        ...this.domainIdsByOrganizationId[organizationId],
                    ],
                }
            })

            return true
        } catch (e) {
            toast.error(`Failed to create domain: ${(e as Error).message}`)
            return false
        }
    }

    @observed('isUpdating')
    public async updateDomain(
        organizationId: string,
        domainId: string,
        domain: string,
    ) {
        try {
            const result = await visorPRORestClient.organization.updateDomain(
                organizationId,
                domainId,
                domain,
            )

            runInAction(() => {
                this.domainsById = {
                    ...this.domainsById,
                    [result.id]: result,
                }
            })
        } catch (e) {
            toast.error(`Failed to update domain: ${(e as Error).message}`)
        }
    }

    @observed('isUpdating')
    public async deleteDomain(organizationId: string, domainId: string) {
        try {
            await visorPRORestClient.organization.deleteDomain(
                organizationId,
                domainId,
            )

            runInAction(() => {
                const domainsById = { ...this.domainsById }
                delete domainsById[domainId]
                this.domainsById = domainsById
                this.domainIdsByOrganizationId = {
                    ...this.domainIdsByOrganizationId,
                    [organizationId]: this.domainIdsByOrganizationId[
                        organizationId
                    ].filter((id) => id !== domainId),
                }
            })
        } catch (e) {
            toast.error(`Failed to delete domain: ${(e as Error).message}`)
        }
    }
}

export const useDomainsByOrganizationId = (
    stores: Stores,
    organizationId: string,
) => {
    useEffect(() => {
        if (
            !stores.organizationDomains.domainIdsByOrganizationId[
                organizationId
            ]
        ) {
            void stores.organizationDomains.getDomains(organizationId)
        }
    }, [
        organizationId,
        stores.organizationDomains,
        stores.organizationDomains.domainIdsByOrganizationId,
    ])

    return useMemo(() => {
        const ids =
            stores.organizationDomains.domainIdsByOrganizationId[
                organizationId
            ] || []
        return ids.map((id) => stores.organizationDomains.domainsById[id])
    }, [
        organizationId,
        stores.organizationDomains.domainIdsByOrganizationId,
        stores.organizationDomains.domainsById,
    ])
}
