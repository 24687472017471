import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback } from 'react'
import { MdChevronLeft } from 'react-icons/md'
import { Modal } from '../modal'
import type { SelectableListItem } from '../modals'
import { SelectableList } from '../modals'
import type { Stores } from '../../util/store'

export interface ModelModalProps {
    stores: Stores
    onClose(): void
    anchor: HTMLElement
    onSelect(modelName: string): void
}

// todo: ask openai directly so this is always up to date
const modelNames = [
    'gpt-4o-2024-05-13',
    'gpt-4-turbo-2024-04-09',
    'gpt-4-0125-preview',
    'gpt-4-1106-preview',
    'gpt-4-0613',
    'o1-preview-2024-09-12',
    'o1-mini-2024-09-12',
]

const models = modelNames.map((modelName) => ({
    id: modelName,
    name: modelName,
}))

export const TestRunConfigModalModel: FC<ModelModalProps> = observer(
    ({ anchor, onClose, onSelect }) => {
        const _onSelect = useCallback(
            (item: SelectableListItem) => {
                onSelect(item.id)
            },
            [onSelect],
        )

        return (
            <Modal anchor={anchor} onClose={onClose}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.5rem',
                    }}>
                    <MdChevronLeft
                        size={24}
                        color="var(--comment-color)"
                        style={{
                            flexShrink: 0,
                            cursor: 'pointer',
                            paddingLeft: '0.5rem',
                        }}
                        onClick={onClose}
                    />

                    <span
                        style={{
                            color: 'var(--comment-color)',
                        }}>
                        Select model
                    </span>
                </div>

                <SelectableList
                    items={models}
                    onSelect={_onSelect}
                    isLoading={false}
                    placeholder=""
                />
            </Modal>
        )
    },
)
